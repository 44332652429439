import React, { useState, FC, useEffect, useImperativeHandle } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { RefProps, ModalProps } from './types';

const Modal: FC<ModalProps> = React.forwardRef<RefProps, ModalProps>(
  ({ children, isVisible = false, title, fullWidth = false }, ref) => {
    const [isVisibleInner, setVisibleInner] = useState(isVisible);

    useEffect(() => {
      setVisibleInner(isVisible);
    }, [isVisible]);

    useImperativeHandle(ref, () => ({
      toggle,
      show,
      hide,
    }));

    function toggle() {
      setVisibleInner(!isVisibleInner);
    }

    function show() {
      setVisibleInner(true);
    }

    function hide() {
      setVisibleInner(false);
    }

    return (
      <Dialog
        fullWidth={fullWidth}
        maxWidth="md"
        open={isVisibleInner}
        onClose={hide}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
        <br />
        <DialogContent>{children({ close: hide })}</DialogContent>
      </Dialog>
    );
  },
);

export default Modal;
