import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

// import { domain } from '../../config';
import { TextWrapper, FlyBox, Tooltip } from 'components';

import './CoverScreen.css';

class CoverScreen extends Component {
  render() {
    const logoStyle =
      // this.props.drawerLeftVariant === 'persistent'
      //   ? {
      //       backgroundImage: 'url(./images/intego_logo3.png)',
      //       width: '116px',
      //       height: '41px',
      //       backgroundSize: 'cover',
      //       backgroundColor: 'rgba(255, 255, 255, .5)',
      //       boxShadow: '0 0 20px rgba(255, 255, 255, .5)',
      //       borderRadius: '2px',
      //     }
      //   : {
      {
        backgroundImage: 'url(/images/intego_logo1.png)',
        width: '90px',
        height: '76px',
        backgroundSize: 'cover',
        backgroundColor: 'rgba(255, 255, 255, .5)',
        boxShadow: '0 0 20px rgba(255, 255, 255, .5)',
        borderRadius: '2px',
      };
    return (
      <div className="logo-container">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <div
            className={get(this.props, 'graph.loading') ? 'logo' : ''}
            style={logoStyle}
          />
          {!this.props.isOptionsOpen ? (
            <div style={{ marginTop: '30px' }}>
              <FlyBox>
                <h3>
                  <TextWrapper variant="h5" align="center">
                    Brief instruction
                  </TextWrapper>
                </h3>
                <span>
                  <TextWrapper variant="h6">
                    1. Select Gear icon at the top
                  </TextWrapper>
                </span>
                <span>
                  <TextWrapper variant="h6">2. Pick needed options</TextWrapper>
                </span>
                <span>
                  <TextWrapper variant="h6">
                    3. Click run and see interactive graph.
                  </TextWrapper>
                </span>
              </FlyBox>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

CoverScreen.propTypes = {
  drawerLeftVariant: PropTypes,
};

const mapStateToProps = state => ({
  drawerLeftVariant: state.common.drawerLeftVariant,
  graph: state.graph,
  isOptionsOpen: state.graph.isOptionsOpen,
});

export default connect(mapStateToProps)(CoverScreen);
