import React, { useState, useEffect, useRef } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { TextWrapper } from 'components';
import { ModelApi, GraphApi, PortfolioApi } from 'api';
import { history, pageUrl } from 'config';
import { Table, Loading } from 'components';
import { useShallowSelector } from 'hooks';
import { Actions } from 'actions';
import { ModalCreateGraph, ModalDelete } from '../index';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';

import './Model.css';
import GraphsTable from '../GraphsTable/GraphsTable';

const columnsConfig = [
  { id: 'title', label: 'Title' },
  { id: 'metricNodesCount', label: 'Metric Nodes' },
  { id: 'clusteredNodesCount', label: 'Clustered Nodes' },
];

const rowsConfig = allIds => [
  { prop: 'title', format: val => (val ? val : '-') },
  { prop: 'metricNodesCount', format: val => (val ? val : '-') },
  { prop: 'clusteredNodesCount', format: val => (val ? val : '-') },
];

const getMatrix = rows => [
  { row: [100], variant: 'rect' },
  ...new Array(rows + 1).fill(rows + 1).reduce(arr => {
    arr.push({ row: [40, 25, 25, 10], variant: 'rect' });
    return arr;
  }, []),
];

const TOP_MARGIN = 344;
const INNER_HEIGHT = window.innerHeight;
const LINE_HEIGH = 57;
const RESERVED_LINES = 2;
const ROWS_COUNT =
  parseInt((INNER_HEIGHT - TOP_MARGIN) / LINE_HEIGH) - RESERVED_LINES;

const graphsTableToolbarStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: 0,
    },
    actions: {
      marginLeft: 'auto'
    },
    button: {
      width: '200px',
    },
}));

const Model = ({ model: modelUpper }) => {
  const { portfolioId, studyId, fileId, datasetId, modelId } = useParams();
  const location = useLocation();

  const loading = useShallowSelector(s => s.graphs.loading);
  const graphs = useShallowSelector(s => s.graphs.graphs);
  const { totalCount, page, perPage } = useShallowSelector(
    s => s.graphs.pagination,
  );
  const [model, setModel] = useState({
    title: '',
    additionalInfo: '',
    created: '',
  });
  const [showAddModel, setShowAddModel] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [graphsTableSort, setGraphsTableSort] = useState([]);

  const allIds = { portfolioId, studyId, fileId, datasetId, modelId };

  const graphsTableToolbarClasses = graphsTableToolbarStyles();

  function setPerPageVal(perPage) {
    return Actions.graphs.dispatch({ 'pagination.perPage': perPage });
  }

  async function listGraphs() {
    return GraphApi.listGraphs(allIds, page + 1, perPage || ROWS_COUNT, graphsTableSort);
  }

  function changeGraphFavoriteStatus(graph, isFavorite) {
    const { resourceTitle, id } = graph;

    GraphApi.changeGraphFavoriteStatus({
      resourceTitle, resourceId: id, isFavorite
    }).then(() => {
      const [isFavoriteSort] = graphsTableSort.filter(([fieldName]) => fieldName === 'isFavorite');
      if (isFavoriteSort) {
        //we have sorting by isFavorite, so we need to refresh the table
        listGraphs();
      } else {
        //no sorting by isFavorite, update local copy of graphs
        const newGraphs = [...graphs].map(graph => ({
          ...graph,
          isFavorite: graph.id === id ? isFavorite : graph.isFavorite
        }));
        Actions.graphs.dispatch({ graphs: newGraphs });
      }
    });
  }

  function handleSortChange(tableSort) {
    setGraphsTableSort(tableSort);
    //return GraphApi.listGraphs(allIds, page + 1, perPage || ROWS_COUNT, sorting);
  }

  function getModelDetails() {
    return ModelApi.getModelDetails(allIds).then(({ data }) => {
      setModel(data);
    });
  }

  function setPageVal(perPage) {
    Actions.graphs.dispatch({ 'pagination.page': perPage });
  }

  useEffect(() => {
    setPerPageVal(ROWS_COUNT);
    if (!modelUpper?.id) {
      getModelDetails();
    }
  }, []);

  useEffect(() => {
    // setPerPageVal(ROWS_COUNT);
    const getGraphs = async () => {
      if (loading) {
        return;
      }
      await listGraphs();
    };
    getGraphs();
  }, [graphsTableSort, perPage, page, location]);

  const title = modelUpper?.title ?? model.title;
  const datasetRowsNumber = modelUpper?.datasetRowsNumber ?? model.datasetRowsNumber;
  const additionalInfo = modelUpper?.additionalInfo ?? model.additionalInfo;
  const created = new Date(
    parseInt(modelUpper?.created || model.created),
  ).toLocaleString();

  function handleDeleteModel() {
    return ModelApi.deleteModel(allIds).then(() => {
      setShowDeleteModel(false);
      return PortfolioApi.getPortfoliosTree();
    });
  }

  function onGraphTileClick(item) {
    Actions.graph.dispatch({
      graphData: item,
      initialGraphData: cloneDeep(item),
      list_colorings: item?.coloring ?? [],
    });
    Actions.common.dispatch({
      isDrawerRightOpen: false,
      isDrawerRightVisible: false,
    });
    history.push(
      pageUrl.graph(
        allIds.portfolioId,
        allIds.studyId,
        allIds.fileId,
        allIds.datasetId,
        allIds.modelId,
        item.id,
      ),
    );
  }

  return (
    <div>
      <div className="profile-heading-wrapper">
        <div className="profile-title-wrapper">
          <TextWrapper variant="h6">Model: {title}</TextWrapper>
          <TextWrapper variant="subtitle2">Dataset rows: {datasetRowsNumber}</TextWrapper>
          <TextWrapper variant="subtitle2">Info: {additionalInfo}</TextWrapper>
          <TextWrapper variant="subtitle2">Created: {created}</TextWrapper>
        </div>
      </div>
      <div className="portfolio-controls-section">
        {/* <Button className="portfolio-btn" variant="outlined">
          Add Study
        </Button> */}
        <Button onClick={() => setShowDeleteModel(true)} variant="outlined">
          Delete Model
        </Button>
      </div>

      <Toolbar className={graphsTableToolbarClasses.root}>
        <div>
          <Typography variant="h6">Graphs</Typography>
        </div>
        <div className={graphsTableToolbarClasses.actions}>
          <Button
            className={graphsTableToolbarClasses.button}
            onClick={() => setShowAddModel(true)}
            variant="outlined"
          >
            Generate Graphs
          </Button>
        </div>
      </Toolbar>
      <GraphsTable
        rows={graphs}
        page={page}
        rowsPerPage={perPage}
        totalRowsQuantity={totalCount}
        rowsPerPageOptions={[ROWS_COUNT, ROWS_COUNT * 2, ROWS_COUNT * 3]}
        onChangeRowsPerPage={e => setPerPageVal(e.target.value)}
        onChangePage={(e, nextPge) => setPageVal(nextPge)}
        LoadingComponent={() => (
          <Loading loadingMatrix={getMatrix(ROWS_COUNT)} />
        )}
        loading={loading}
        onGraphClick={(item) => onGraphTileClick(item)}
        onChangeFavoriteStatus={changeGraphFavoriteStatus}
        onSortChange={handleSortChange}
      >

      </GraphsTable>
      <ModalCreateGraph
        showModal={showAddModel}
        handleClose={() => setShowAddModel(false)}
        listGraphs={listGraphs}
      />
      <ModalDelete
        showModal={showDeleteModel}
        handleClose={() => setShowDeleteModel(false)}
        warningMessage="Are you sure you want to delete this model?"
        entityName="Model"
        deleteEntity={handleDeleteModel}
      />
    </div>
  );
};

export default Model;
