import React from 'react';
// import capitalize from 'lodash/capitalize';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

// import { TextWrapper } from 'components';
import { scroll } from 'utils';

import './ScrollControl.css';

export function ScrollControl(props) {
  const getY = () =>
    document.getElementsByClassName('content-wrapper')[0].scrollTop;

  return (
    <div
      className="scroll-control-container"
      style={{
        display: 'inline-flex',
        position: 'fixed',
        bottom: '50px',
        right: '10px',
        flexDirection: 'column',
      }}
    >
      <Fab
        style={{ marginBottom: '15px' }}
        onClick={e => scroll(getY() - 500, 200)}
      >
        <KeyboardArrowUp />
      </Fab>
      <Fab onClick={e => scroll(getY() + 500, 200)}>
        <KeyboardArrowDown />
      </Fab>
    </div>
  );
}

export default ScrollControl;
