import React, { useState, useEffect } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import pickBy from 'lodash/pickBy';

import { Table, Loading } from 'components';
import { Actions } from 'actions';
import { apiUrl } from 'config';
import { useShallowSelector } from 'hooks';
import { notify } from 'utils';

import { ModalDeleteUser, ModalCreateUser, ModalEditUser } from '../components';

import './Users.css';

const columnsConfig = [
  { id: 'displayName', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'phoneNumber', label: 'Phone' },
  // { id: 'photoUrl', label: 'photoUrl' },
  { id: 'disabled', label: 'Disabled' },
  { id: 'uuid', label: 'UUID' },
  { id: 'edit', label: '' },
];

const rowsConfig = config => [
  { prop: 'displayName', format: val => (val ? val : '-') },
  { prop: 'email', format: val => (val ? val : '-') },
  { prop: 'phoneNumber', format: val => (val ? val : '-') },
  // { prop: 'photoUrl', format: val => (val ? val : '-') },
  { prop: 'disabled', format: val => val.toString() },
  { prop: 'uuid', format: val => (val ? val : '-') },
  {
    prop: 'uuid',
    format: (uuid, user) => {
      return (
        <div>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              config.handleEditClick(user);
            }}
          >
            <EditIcon style={{ color: '#3d3d3d' }} />
          </IconButton>
        </div>
      );
    },
  },
];

const defaultUser = {
  disabled: false,
  displayName: '',
  email: '',
  emailVerified: true,
  password: '',
  phoneNumber: '',
  photoUrl: '',
};

const Users = props => {
  useEffect(() => {
    loadUsers();
  }, []);
  const users = useShallowSelector(s => s.users.users);
  const loading = useShallowSelector(s => s.users.loading);
  const [state, setStateRow] = useState({
    tabIndex: 0,
    page: 0,
    rowsPerPage: 1000,
    order: 'desc',
    orderBy: 'pvalue',
  });
  const [newUser, setNewUserRaw] = useState(defaultUser);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  function setNewUser(newFields) {
    setNewUserRaw(prevFields => ({ ...prevFields, ...newFields }));
  }

  function handleDeleteUser() {
    Actions.users.DELETE({
      url: apiUrl.users.deleteUsers(currentUser[0].uuid),
    })
      .then(() => {
        setShowDeleteModal(false);
        loadUsers();
      })
      .catch(() => setShowDeleteModal(false));
  }

  function handleCreateUser() {
    const filteredNewUser = pickBy(
      newUser,
      val => Boolean(val) || val === false,
    );
    Actions.users.POST({
      url: apiUrl.users.createUser(),
      data: filteredNewUser,
    })
      .then(() => {
        setShowCreateModal(false);
        loadUsers();
      })
      .catch(() => setShowCreateModal(false));
  }

  function handleEditUser() {
    const filteredNewUser = pickBy(
      newUser,
      val => Boolean(val) || val === false,
    );
    Actions.users.PATCH({
      url: apiUrl.users.editUser(filteredNewUser.uuid),
      data: filteredNewUser,
    })
      .then(() => {
        setShowEditModal(false);
        setNewUser(defaultUser);
        loadUsers();
      })
      .catch(() => {
        setShowEditModal(false);
        setNewUser(defaultUser);
      });
  }

  function setState(newState) {
    setStateRow(state => ({ ...state, ...newState }));
  }

  function loadUsers() {
    Actions.users.GET({
      url: apiUrl.users.getUsers(),
      mapData: ({ data: { users } }) => ({ users }),
    });
  }

  function handleEditClick(user) {
    console.log(user);
    setNewUser(user);
    setShowEditModal(true);
  }

  return (
    <div className="users-wrapper">
      <Table
        showRadio
        topMargin={158}
        showDeleteButton={true}
        showControlColumn={true}
        noItemsFoundPlaceholder={'No users fount'}
        loading={loading}
        columnsConfig={columnsConfig}
        rowsConfig={rowsConfig({ handleEditClick })}
        LoadingComponent={Loading}
        tableName="Users"
        items={users}
        rowClassName="row"
        page={state.page}
        rowsPerPage={state.rowsPerPage}
        columnsConfig={columnsConfig}
        order={state.order}
        orderBy={state.orderBy}
        addButtonItemName="Add User"
        page={state.page}
        rowsPerPage={state.rowsPerPage}
        totalRowsQuantity={users.length}
        rowsPerPageOptions={[10]}
        onDeleteClick={user => {
          setCurrentUser(user);
          setShowDeleteModal(true);
        }}
        onChangePage={(e, nextPge) => {
          setState({ page: nextPge });
        }}
        onColumnHeaderClick={(order, { id: orderBy }) => {
          setState({ order, orderBy });
          console.log(order, orderBy);
        }}
        onAddButtonClick={() => {
          setShowCreateModal(true);
        }}
      />
      <ModalDeleteUser
        showModal={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDeleteUser={handleDeleteUser}
      />
      <ModalCreateUser
        showModal={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        handleCreateUser={handleCreateUser}
        state={newUser}
        setState={setNewUser}
      />
      <ModalEditUser
        showModal={showEditModal}
        handleClose={() => setShowEditModal(false)}
        handleEditUser={handleEditUser}
        state={newUser}
        setState={setNewUser}
      />
    </div>
  );
};

// TableAdjusted.propTypes = {
//   tableName: PropTypes.string,
//   items: PropTypes.array,
//   page: PropTypes.number,
//   rowsPerPage: PropTypes.number,
//   orderBy: PropTypes.string,
//   columnsConfig: PropTypes.array,
//   rowsConfig: PropTypes.array,
//   onChangeRowsPerPage: PropTypes.func,
//   onChangePage: PropTypes.func,
//   rowsPerPageOptions: PropTypes.array,
//   totalRowsQuantity: PropTypes.number,
//   loading: PropTypes.bool,
//   LoadingComponent: PropTypes.any,
//   onColumnHeaderClick: PropTypes.func,
//   noItemsFoundPlaceholder: PropTypes.string,
//   onAddButtonClick: PropTypes.func,
//   addButtonItemName: PropTypes.string,
//   cellProps: PropTypes.func,
//   onDeleteClick: PropTypes.func,
//   cellClasses: PropTypes.func,
//   // whether to show first checkbox/radio column or not
//   showControlColumn: PropTypes.bool,
//   // whether to show Radio as control column.
//   // if not - then Checkbox is default.
//   // NOTE if true - will disable selectAll button in table header
//   showRadio: PropTypes.bool,
//   showAddButton: PropTypes.bool,
//   showDeleteButton: PropTypes.bool,
//   customAddButtonComponent: PropTypes.any,
//   customDeleteButtonComponent: PropTypes.any,
// };

export default Users;
