import React, { useState, FC } from 'react';
import { useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Input } from 'components';
import { GraphApi } from 'api';

import './ModalCopyLayer.css';
import { ModalCopyLayerProps } from './types';

const ModalCopyLayer: FC<ModalCopyLayerProps> = ({
  handleClose,
  communityLayerId,
  handleCopyCallback,
}) => {
  const {
    portfolioId,
    studyId,
    fileId,
    datasetId,
    modelId,
    graphId,
  } = useParams();
  const allIds = { portfolioId, studyId, fileId, datasetId, modelId, graphId };
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [title, setTitle] = useState({ title: '' });

  async function handleDeleteProxy() {
    try {
      setDeleteLoading(true);
      await GraphApi.copyLayer({
        ...allIds,
        communityLayerId,
        title: title.title,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      handleClose();
      handleCopyCallback();
    }
  }

  return (
    <div style={{ width: '550px' }}>
      <div style={{ marginBottom: '50px' }}>
        <Input
          id="title"
          placeholder="New layer..."
          label="Layer Title"
          that={{ setState: setTitle, state: title }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '20px 0 10px',
        }}
      >
        <Button
          variant="contained"
          style={{ marginRight: '25px' }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={deleteLoading}
          variant="contained"
          style={{ minWidth: '168px' }}
          color="primary"
          onClick={handleDeleteProxy}
        >
          {deleteLoading ? (
            <CircularProgress
              color="secondary"
              style={{ width: '20px', height: '20px' }}
            />
          ) : (
            'Copy Layer'
          )}
        </Button>
      </div>
    </div>
  );
};

export default ModalCopyLayer;
