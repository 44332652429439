import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './Tooltip.css';

class Tooltip extends React.Component {
  render() {
    return (
      <div data-title="My tooltip" class="tooltip">
        {this.props.children}
      </div>
    );
  }
}

Tooltip.propTypes = {};

const mapStateToProps = state => ({
  open: state.notifications.open,
  keyProp: state.notifications.key,
  message: state.notifications.message,
  vertical: state.notifications.vertical,
  horizontal: state.notifications.horizontal,
  status: state.notifications.status,
  autoHideDuration: state.notifications.autoHideDuration,
});

export default Tooltip;
