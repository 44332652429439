import React, { useState, useEffect, useRef } from "react";
import {
  Switch,
  FormGroup,
  FormControlLabel,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Toolbar,
  Button,
  IconButton,
  CircularProgress,
  Divider,
  Select,
  MenuItem,
  Tooltip,
  FormControl,
  TextField,
  ListItemIcon,
  Typography,
  Grid,
  DialogActions,
  Menu,
  Popper,
  Popover,
  Paper,
  FormLabel,
  Slider
} from "@material-ui/core";
import clsx from 'clsx';
import { Theme } from "@material-ui/core/styles";
import TwoWayQuerybuilder from 'react-two-way-querybuilder';
import { SQLQueriesApi, SQLQueryData } from "api";
import { useParams } from "react-router-dom";
import { Actions } from "actions";

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SharedIcon from 'components/CustomIcons/SharedIcon';
import TuneIcon from '@material-ui/icons/Tune';
import { useShallowSelector } from 'hooks';
import { IAppStore } from "reducers";

enum QueryResultDisplayMode {
  HighlightQueriedNodes,
  ShowOnlyQueriedNodes,
  ShowAllButQueriedNodes
}

interface CanvasUISettingsSectionProps {
  className?: string;
  showGraphInfoPlate?: boolean
  showHistogram?: boolean
  showTooltip?: boolean
  showQueryResult?: boolean;
  queryResultDisplayMode?: QueryResultDisplayMode;
  onShowGraphDetailsChange: (newValue: boolean) => void;
  onShowHistogramChange: (newValue: boolean) => void;
  onShowTooltipChange: (newValue: boolean) => void;
  onShowQueryResultChange: (newValue: boolean) => void;
  onQueryResultDisplayModeChange: ({displayMode, opacity}: {
    displayMode: QueryResultDisplayMode,
    opacity: number
  }) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: 'auto'
  },
  queryResultDisplayModeWrapper: {
    marginRight: 10
  }
}));

const useSqlQueryBuilderDialogStyles = makeStyles((theme: Theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  queriesDropdown: {
    width: 200,
    marginRight: 10
  },
  formControl: {
    marginBottom: 20
  },
  iconButtonProgress: {
    position: 'absolute',
  },
  menuItemIcon: {
    minWidth: 32
  }
}));

const QueryResultDisplayModeHumanReadable = {
  [QueryResultDisplayMode.ShowOnlyQueriedNodes]: 'Only queried nodes',
  [QueryResultDisplayMode.ShowAllButQueriedNodes]: 'Exclude queried nodes',
  [QueryResultDisplayMode.HighlightQueriedNodes]: 'Reshape queried nodes'
};

const CanvasUISettingsSection = (props: CanvasUISettingsSectionProps) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    showHistogram: props?.showHistogram ?? true,
    showGraphInfoPlate: props?.showGraphInfoPlate ?? true,
    showTooltip: props?.showTooltip ?? true,
    showQueryResult: props?.showQueryResult ?? true,
    queryResultDisplayMode: props?.queryResultDisplayMode ?? QueryResultDisplayMode.HighlightQueriedNodes,
    hiddenNodesOpacity: 20
  });
  const [queryResultDisplayModeSelectOpen, setQueryResultDisplayModeSelectOpen] = useState(false);
  const { className = '' } = props;

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setState({ ...state, [event.target.name]: newValue });
    switch (event.target.name) {
      case 'showHistogram':
        props?.onShowHistogramChange(newValue);
        break;
      case 'showGraphInfoPlate':
        props?.onShowGraphDetailsChange(newValue);
        break;
      case 'showTooltip':
        props?.onShowTooltipChange(newValue);
        break;
      case 'showQueryResult':
        props?.onShowQueryResultChange(newValue);
        break;

    }
  };

  const handleQueryResultDisplayModeSelectOpen = () => {
    setQueryResultDisplayModeSelectOpen(true);
  }

  const handleQueryResultDisplayModeSelectClose = () => {
    setQueryResultDisplayModeSelectOpen(false);
  }

  const changeQueryResultDisplayMode = (newMode: QueryResultDisplayMode) => {
    setState({
      ...state,
      queryResultDisplayMode: newMode
    });
    props?.onQueryResultDisplayModeChange({
      displayMode: newMode,
      opacity: state.hiddenNodesOpacity
    });
  }

  const changeQueryResultOpacity = (newOpacity: number) => {
    if (state.hiddenNodesOpacity !== newOpacity) {
      setState({
        ...state,
        hiddenNodesOpacity: newOpacity
      });

      props?.onQueryResultDisplayModeChange({
        displayMode: state.queryResultDisplayMode,
        opacity: newOpacity
      });
    }
  }

  //todo: following should be moved into separate component in the future

  const graphData = useShallowSelector(s => s.graph.graphData);
  const { datasetId = '', modelId = '' } = graphData;
  const sqlQueryResult = useShallowSelector((s: IAppStore) => s.graph.sqlQueryResult);
  const [sqlQueryText, setSqlQueryText] = useState('');

  const sqlQueryBuilderDialogClasses = useSqlQueryBuilderDialogStyles();
  const { portfolioId, studyId, fileId } = useParams();
  const [sqlQueryDialogOpen, setSqlQueryDialogOpen] = useState(false);

  //should be serializable value
  const [forceTwoWayQuerybuilderRefresh, setForceTwoWayQuerybuilderRefresh] = useState(Date.now());

  const [currentSqlQueryData, setCurrentSqlQueryData] = useState<SQLQueryData>({
    id: '',
    createdBy: '',
    query: '',
    title: '',
    additionalInfo: '',
    isPublic: false,
    isShared: false,
    portfolioId: portfolioId ?? '',
    studyId: studyId ?? '',
    fileId: fileId ?? ''
  });

  const [sqlQueryInProgress, setSqlQueryInProgress] = useState(false);
  const [sqlQueryFields, setSqlQueryFields] = useState([]);
  const [sqlQueryResultLocal, setSqlQueryResult] = useState<string[] | null>(null);

  const [storedSqlQueriesList, setStoredSqlQueriesList] = useState<SQLQueryData[]>([]);

  const [sqlQuerySaveDialogOpen, setSqlQuerySaveDialogOpen] = useState(false);
  const [sqlQuerySaveInProgress, setSqlQuerySaveInProgress] = useState(false);
  const [sqlQuerySaveNewInProgress, setSqlQuerySaveNewInProgress] = useState(false);
  const [sqlQueryDeleteInProgress, setSqlQueryDeleteInProgress] = useState(false);
  const [sqlQueryPrivacyChangeInProgress, setSqlQueryPrivacyChangeInProgress] = useState(false);

  const [saveOptionsMenuAnchorEl, setSaveOptionsMenuAnchorEl] = useState<null | HTMLElement>(null);
  const saveOptionsMenuOpen = Boolean(saveOptionsMenuAnchorEl);

  const [queryResultUISettingsMenuAnchorEl, setQueryResultUISettingsMenuAnchorEl] = useState<null | HTMLElement>(null);

  const sqlQuerySettingsTooltip = [
    (<div>Display style: {QueryResultDisplayModeHumanReadable[state.queryResultDisplayMode]}</div>),
    (<div>Opacity: {state.hiddenNodesOpacity}</div>)
  ];

  useEffect(() => {
    setSqlQueryInProgress(true);
    SQLQueriesApi.list(datasetId, modelId, currentSqlQueryData).then(({ data }) => {
      if (isMounted.current) {
        setSqlQueryInProgress(false);
        setStoredSqlQueriesList(data.items);
      }
    });
  }, []);

  useEffect(() => {
    SQLQueriesApi.getFields(datasetId, modelId, currentSqlQueryData).then(({ data }) => {
      if (isMounted.current) {
        setSqlQueryFields(data);
      }
    });
  }, []);

  const resetCurrentSqlQueryData = () => {
    setCurrentSqlQueryData({
      ...currentSqlQueryData,
      id: '',
      createdBy: '',
      query: '',
      title: '',
      additionalInfo: '',
      isPublic: false,
      isShared: false,
    });
  }

  const handleCurrentQueryChange = (e: any) => {
    const selectedQueryId = e.target.value;
    if (selectedQueryId !== '') {
      const [ queryData ] = storedSqlQueriesList.filter(({ id }) => id === selectedQueryId);
      if (queryData) {
        setCurrentSqlQueryData(queryData);
      }
    } else {
      resetCurrentSqlQueryData();
    }
    setForceTwoWayQuerybuilderRefresh(Date.now());
  };

  const handleSQLQueryBuilderChange = (e: any) => {
    setCurrentSqlQueryData({
      ...currentSqlQueryData,
      query: e.query
    });
    setSqlQueryResult(null);
  };

  const executeQuery = () => {
    setSqlQueryInProgress(true);
    setSqlQueryResult(null);

    SQLQueriesApi.execute(datasetId, modelId, currentSqlQueryData).then(({ data }) => {
      setSqlQueryResult(data.nodesIds);
    }).catch(e => {
      //do something?
    }).finally(() => {
      setSqlQueryInProgress(false);
    });
  };

  const onSaveQueryToolbarButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    //called when user clicks save button from toolbar
    if (currentSqlQueryData.id === '' || (currentSqlQueryData.isPublic && !currentSqlQueryData.isShared)) {
      setSqlQuerySaveDialogOpen(true);
    } else {
      setSaveOptionsMenuAnchorEl(e.currentTarget);
    }
  };

  const handleSaveQueryFromToolbar = () => {
    setSaveOptionsMenuAnchorEl(null);
    setSqlQuerySaveInProgress(true);
    SQLQueriesApi.update(datasetId, modelId, currentSqlQueryData).then(({ data }) => {
      setStoredSqlQueriesList(storedSqlQueriesList.map(queryData => {
        if (queryData.id === data.id) {
          return data;
        }
        return queryData;
      }));
    }).finally(() => {
      setSqlQuerySaveInProgress(false);
    });
  };

  const handleSaveQueryAsFromToolbar = () => {
    setSaveOptionsMenuAnchorEl(null);
    setSqlQuerySaveDialogOpen(true);
  };

  const handleSaveOptionsMenuClose = () => {
    setSaveOptionsMenuAnchorEl(null);
  };

  const handleSaveNewQuery = () => {
    setSqlQuerySaveNewInProgress(true);

    const sqlQueryData = {...currentSqlQueryData};
    if (currentSqlQueryData.isPublic && !currentSqlQueryData.isShared) {
      sqlQueryData.id = '';
    }

    SQLQueriesApi.add(datasetId, modelId, sqlQueryData).then(({ data }) => {
      setSqlQuerySaveDialogOpen(false);
      setStoredSqlQueriesList([...storedSqlQueriesList, data]);
      setCurrentSqlQueryData(data);
    }).finally(() => {
      setSqlQuerySaveNewInProgress(false);
    });
  };

  const handleDeleteQuery = () => {
    setSqlQueryDeleteInProgress(true);
    SQLQueriesApi.delete(datasetId, modelId, currentSqlQueryData).then(({ data }) => {
      setStoredSqlQueriesList(storedSqlQueriesList.filter(({ id }) => id !== currentSqlQueryData.id));
      resetCurrentSqlQueryData();
      setForceTwoWayQuerybuilderRefresh(Date.now());
    }).finally(() => {
      setSqlQueryDeleteInProgress(false);
    });
  };

  const onClearQueryClick = () => {
    setCurrentSqlQueryData({
      ...currentSqlQueryData,
      query: ''
    });
    setSqlQueryResult(null);
    Actions.graph.dispatch({
      sqlQueryResult: []
    });
    setSqlQueryText('');
    setForceTwoWayQuerybuilderRefresh(Date.now());
  };

  const onShowNodesClick = () => {
    setSqlQueryDialogOpen(false);
    setSqlQueryText(currentSqlQueryData.query);
    Actions.graph.dispatch({
      sqlQueryResult: sqlQueryResultLocal
    });
  };

  const changeSqlQueryPrivacyState = (isPublic: boolean) => {
    setSqlQueryPrivacyChangeInProgress(true);
    SQLQueriesApi.changePrivacy(
      datasetId, modelId, {
      ...currentSqlQueryData,
      isPublic
    }).then(({ data }) => {
      setStoredSqlQueriesList(storedSqlQueriesList.map(queryData => {
        if (queryData.id === data.id) {
          return data;
        }
        return queryData;
      }));
      setCurrentSqlQueryData(data);
    }).finally(() => {
      setSqlQueryPrivacyChangeInProgress(false);
    });
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={state.showQueryResult}
            onChange={handleChange}
            name="showQueryResult"
            color="primary"
            disabled={sqlQueryResult.length === 0}
          />
        }
        label="Show query result"
      />
      <FormControl>
        <IconButton
          color="inherit"
          aria-label="Query result UI settings"
          disabled={sqlQueryResult.length === 0 || !state.showQueryResult}
          onClick={(e) => {
            setQueryResultUISettingsMenuAnchorEl(e.currentTarget);
          }}
        >
          <Tooltip title={sqlQuerySettingsTooltip} placement="top">
            <TuneIcon />
          </Tooltip>
        </IconButton>
      </FormControl>
      <Tooltip title={sqlQueryText} placement="top">
        <Button
          variant="outlined"
          color="primary"
          style={{
            marginLeft: 0
          }}
          onClick={() => {setSqlQueryDialogOpen(true)}}
        >SQL Query</Button>
      </Tooltip>

      <FormGroup row className={clsx(classes.root, className)}>
        <FormControlLabel
          control={
            <Switch
              checked={state.showHistogram}
              onChange={handleChange}
              name="showHistogram"
              color="primary"
            />
          }
          label="Charts"
        />
        <FormControlLabel
          control={
            <Switch
              checked={state.showGraphInfoPlate}
              onChange={handleChange}
              name="showGraphInfoPlate"
              color="primary"
            />
          }
          label="Legend"
        />
        <FormControlLabel
          control={
            <Switch
              checked={state.showTooltip}
              onChange={handleChange}
              name="showTooltip"
              color="primary"
            />
          }
          label="Hints"
        />
      </FormGroup>
      <Dialog
        fullWidth
        maxWidth="md"
        open={sqlQueryDialogOpen}
        onClose={() => {setSqlQueryDialogOpen(false)}}
        //disableBackdropClick
      >
        <DialogTitle style={{paddingBottom: 0}}>
          <div className={sqlQueryBuilderDialogClasses.titleWrapper}>
            SQL Query Builder
            <IconButton
              onClick={() => setSqlQueryDialogOpen(false)}
              style={{marginLeft: 'auto'}}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Toolbar disableGutters>
            <div>
              <Select
                displayEmpty
                value={currentSqlQueryData.id}
                onChange={handleCurrentQueryChange}
                className={sqlQueryBuilderDialogClasses.queriesDropdown}
              >
                <MenuItem value="">
                  <Grid container alignItems="center">
                    <ListItemIcon className={sqlQueryBuilderDialogClasses.menuItemIcon}>
                      <LockIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"><em>New Query</em></Typography>
                  </Grid>
                </MenuItem>
                {storedSqlQueriesList.map(({ id, title, isPublic, isShared }) => (
                  <MenuItem value={id} key={id}>
                    <Grid container alignItems="center">
                      <ListItemIcon className={sqlQueryBuilderDialogClasses.menuItemIcon}>
                        {isShared ? (
                          <LockOpenIcon fontSize="small"/>
                        ) : isPublic ? (
                          <SharedIcon fontSize="small"/>
                        ) : (
                          <LockIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      <Typography variant="inherit">{title}</Typography>
                    </Grid>
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Tooltip title="Delete query" placement="top">
              <div>
                <IconButton
                  disabled={currentSqlQueryData.id === '' || (currentSqlQueryData.isPublic && !currentSqlQueryData.isShared)}
                  onClick={handleDeleteQuery}
                >
                  <>
                    <DeleteIcon />
                    {sqlQueryDeleteInProgress ? (
                      <CircularProgress className={sqlQueryBuilderDialogClasses.iconButtonProgress}/>
                    ) : null}
                  </>
                </IconButton >
              </div>
            </Tooltip>
            <Tooltip title="Save query" placement="top">
              <div>
                <IconButton
                  onClick={onSaveQueryToolbarButtonClick}
                >
                  <>
                    <SaveIcon />
                    {sqlQuerySaveInProgress ? (
                      <CircularProgress className={sqlQueryBuilderDialogClasses.iconButtonProgress}/>
                    ) : null}
                  </>
                </IconButton >
              </div>
            </Tooltip>
            <Menu
              id="save-options-menu"
              getContentAnchorEl={null}
              anchorEl={saveOptionsMenuAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              open={saveOptionsMenuOpen}
              onClose={handleSaveOptionsMenuClose}
            >
              <MenuItem onClick={handleSaveQueryFromToolbar}>Save</MenuItem>
              <MenuItem onClick={handleSaveQueryAsFromToolbar}>Save As</MenuItem>
            </Menu>
            {currentSqlQueryData.isShared ? (
              <Tooltip title="Make private" placement="top">
                <div>
                  <IconButton
                    disabled={false}
                    onClick={() => changeSqlQueryPrivacyState(false)}
                  >
                    <>
                      <LockIcon />
                      {sqlQueryPrivacyChangeInProgress ? (
                        <CircularProgress className={sqlQueryBuilderDialogClasses.iconButtonProgress}/>
                      ) : null}
                    </>
                  </IconButton >
                </div>
              </Tooltip>
            ) : (!currentSqlQueryData.isPublic && currentSqlQueryData.id !== '') ? (
              <Tooltip title="Share" placement="top">
                <div>
                  <IconButton
                    disabled={false}
                    onClick={() => changeSqlQueryPrivacyState(true)}
                  >
                    <>
                      <LockOpenIcon />
                      {sqlQueryPrivacyChangeInProgress ? (
                        <CircularProgress className={sqlQueryBuilderDialogClasses.iconButtonProgress}/>
                      ) : null}
                    </>
                  </IconButton >
                </div>
              </Tooltip>
            ) : null}
          </Toolbar>
          <div style={{height: '50vh', overflow: 'auto'}}>
            <TwoWayQuerybuilder
              key={forceTwoWayQuerybuilderRefresh}
              fields={sqlQueryFields}
              config={{
                query: currentSqlQueryData.query
              }}
              onChange={handleSQLQueryBuilderChange}
            />
          </div>
        </DialogContent>
        <Divider />
        <DialogTitle style={{paddingBottom: 0}}>
          SQL Query Representation
        </DialogTitle>
        <DialogContent>
          <div style={{height: '10vh', overflow: 'auto'}}>
            {currentSqlQueryData.query}
          </div>
        </DialogContent>
        <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
          <Toolbar disableGutters style={{width: '100%'}}>
            <div>
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  sqlQueryInProgress ? (<CircularProgress size={20} />) : (<PlayCircleFilledIcon />)
                }
                onClick={() => { executeQuery() }}
                disabled={sqlQueryInProgress || currentSqlQueryData.query === ''}
                style={{
                  marginRight: 10
                }}
              >
                Execute
              </Button>

              {sqlQueryResultLocal ? `Query returned ${sqlQueryResultLocal.length} nodes` : ''}
            </div>
            <div style={{
              marginLeft: 'auto',
            }}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginRight: 10
                }}
                onClick={onClearQueryClick}
              >Clear</Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!sqlQueryResultLocal}
                onClick={onShowNodesClick}
              >Show</Button>
            </div>
          </Toolbar>
        </DialogActions>
      </Dialog>
      <Dialog
        open={sqlQuerySaveDialogOpen}
      >
        <DialogTitle>
          New query
        </DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            className={sqlQueryBuilderDialogClasses.formControl}
          >
            <TextField
              value={currentSqlQueryData.title}
              onChange={(e) => setCurrentSqlQueryData({
                ...currentSqlQueryData,
                title: e.target.value
              })}
              label="Title"
              placeholder="Title"
            />
          </FormControl>
          <FormControl
            fullWidth
            className={sqlQueryBuilderDialogClasses.formControl}
          >
            <TextField
              value={currentSqlQueryData.additionalInfo}
              onChange={(e) => setCurrentSqlQueryData({
                ...currentSqlQueryData,
                additionalInfo: e.target.value
              })}
              label="Additional Info"
              placeholder="Additional Info"
            />
          </FormControl>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 0 10px',
            }}
          >
            <Button
              variant="contained"
              style={{ marginRight: '25px' }}
              onClick={() => setSqlQuerySaveDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={currentSqlQueryData.title.trim() === ''}
              variant="contained"
              color="primary"
              onClick={handleSaveNewQuery}
              startIcon={
                sqlQuerySaveNewInProgress ? (
                  <CircularProgress
                    color="secondary"
                    style={{ width: '20px', height: '20px' }}
                  />
                ) : (
                  <SaveIcon />
                )
              }
            >
              Save New Query
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Popover
        open={Boolean(queryResultUISettingsMenuAnchorEl)}
        onClose={() => setQueryResultUISettingsMenuAnchorEl(null)}
        anchorEl={queryResultUISettingsMenuAnchorEl}
      >
        <Paper style={{
          padding: 20
        }}>
          <FormControl component="fieldset">
          <FormLabel component="legend">Queried nodes display style</FormLabel>
          <FormControl>
            <Select
              labelId="query-result-display-mode"
              open={queryResultDisplayModeSelectOpen}
              onOpen={handleQueryResultDisplayModeSelectOpen}
              onClose={handleQueryResultDisplayModeSelectClose}
              value={state.queryResultDisplayMode}
              onChange={e => changeQueryResultDisplayMode((e.target.value as unknown) as QueryResultDisplayMode)}
              //style={{width: 150}}
            >
              <MenuItem value={QueryResultDisplayMode.ShowOnlyQueriedNodes}>
                {QueryResultDisplayModeHumanReadable[QueryResultDisplayMode.ShowOnlyQueriedNodes]}
              </MenuItem>
              <MenuItem value={QueryResultDisplayMode.ShowAllButQueriedNodes}>
                {QueryResultDisplayModeHumanReadable[QueryResultDisplayMode.ShowAllButQueriedNodes]}
              </MenuItem>
              <MenuItem value={QueryResultDisplayMode.HighlightQueriedNodes}>
                {QueryResultDisplayModeHumanReadable[QueryResultDisplayMode.HighlightQueriedNodes]}
              </MenuItem>
            </Select>
          </FormControl>
          {state.queryResultDisplayMode !== QueryResultDisplayMode.HighlightQueriedNodes ? (
            <>
              <FormLabel component="legend" style={{
                marginTop: 20
              }}>Opacity</FormLabel>
              <Slider
                min={0}
                max={100}
                step={10}
                value={state.hiddenNodesOpacity}
                onChange={(event, newValue) => {
                  changeQueryResultOpacity(newValue as number)
                }}
              />
            </>
          ) : null}
          </FormControl>
        </Paper>
      </Popover>
    </>
  )
};
export {
  CanvasUISettingsSection,
  QueryResultDisplayMode
};