import React, { PropsWithChildren } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
}));

const ResizableSplitSectionChild = (props: PropsWithChildren<{ width?: number, height?: number}>) => {
  const { width, height } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root)}
      style={{
        width,
        height
      }}
    >
      {props.children}
    </div>
  );
};

export default ResizableSplitSectionChild;