import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const DEFAULT_MATRIX = [
  { row: [100], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
  { row: [15, 25, 15, 15, 30], variant: 'rect' },
];

const Loading = ({ loadingMatrix }) => {
  const multiItem = loadingMatrix => {
    return loadingMatrix.map(({ row, variant = 'rect' }) => {
      return (
        <div
          style={{ display: 'flex', alignItems: 'center', margin: '15px 0' }}
        >
          {row.map((percentage, i) => {
            return (
              <div
                key={i}
                style={{ display: 'flex', flex: percentage, padding: '0 10px' }}
              >
                <Skeleton
                  variant={variant}
                  style={{ width: '100%' }}
                  height={30}
                />
              </div>
            );
          })}
        </div>
      );
    });
  };

  return <div>{multiItem(loadingMatrix)}</div>;
};

Loading.defaultProps = {
  loadingMatrix: DEFAULT_MATRIX,
};

export default Loading;
