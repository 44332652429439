import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

import { FlyBox, Row, Col } from 'components';
import Login from '../Login/Login';
import Register from '../Register/Register';
import { history, pageUrl } from 'config';

import './AuthSwitcher.css';

const styles = theme => ({
  loginButton: {
    margin: '16px 0 8px',
  },
});

class AuthSwitcher extends Component {
  state = {
    currentTab: 0,
  };

  componentDidMount() {
    if (this.props.auth.access_token) {
      return history.push(pageUrl.main());
    }
  }
  componentDidUpdate() {
    if (this.props.auth.access_token) {
      return history.push(pageUrl.main());
    }
  }

  _handleChange = (event, value) => this.setState({ currentTab: value });

  render() {
    const { classes } = this.props;
    return (
      <Row>
        <Col sm={false} md={6}>
          <div
            style={{
              height: '100vh',
              backgroundImage: `url(./images/login.jpg)`,
              backgroundSize: 'cover',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </Col>
        <Col xs={12} sm={12} md={6}>
          <div className="auth-wrapper">
            <div className="auth-container">
              <FlyBox
                noPaperPadding
                margin
                customStyles={{ maxWidth: '444px' }}
              >

                <AppBar position="static">
                  <Toolbar style={{justifyContent: 'center'}}>
                    <Typography variant="subtitle1" style={{textTransform: 'uppercase'}}>Sign in</Typography>
                  </Toolbar>
                </AppBar>
                <div className="auth-login-register-container">
                  {this.state.currentTab === 0 ? <Login /> : <Register />}
                </div>
              </FlyBox>
            </div>
            <div
              style={{
                display: 'flex',
                position: 'absolute',
                bottom: '20px',
              }}
            >
              <div
                style={{
                  backgroundImage: 'url(./images/intego_logo3.png)',
                  width: '150px',
                  height: '60px',
                  backgroundSize: 'cover',
                  alignSelf: 'center',
                  margin: '30px',
                  backgroundColor: 'rgba(255, 255, 255, .5)',
                  boxShadow: '0 0 20px rgba(255, 255, 255, .5)',
                  borderRadius: '2px',
                }}
              />
              <div
                style={{
                  backgroundImage: 'url(./images/HIPAA-Compliant-Logo.png)',
                  width: '150px',
                  height: '81px',
                  justifyContent: 'flex-end',
                  backgroundSize: 'cover',
                  alignSelf: 'center',
                  margin: '20px',
                  backgroundColor: 'rgba(255, 255, 255, .5)',
                  boxShadow: '0 0 20px rgba(255, 255, 255, .5)',
                  borderRadius: '2px',
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withStyles(styles)(AuthSwitcher));
