import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { pageUrl } from 'config';
import { Actions } from 'actions';
import { AuthChecker, App } from '..';
import { AuthSwitcher } from 'pages';
import { ThemeProvider, Notify } from 'components';
import { useShallowSelector } from 'hooks';

const ThemedApp = () => {
  const themeMode = useShallowSelector(s => s.common.themeMode);
  return (
    <ThemeProvider themeMode={themeMode}>
      <App />
    </ThemeProvider>
  )
};

const ThemedAuthSwitcher = () => {
  const themeMode = useShallowSelector(s => s.common.themeMode);
  return (
    <ThemeProvider themeMode={themeMode}>
      <AuthSwitcher />
    </ThemeProvider>
  )
}

const ThemedNotify = (props) => {
  const themeMode = useShallowSelector(s => s.common.themeMode);
  return (
    <ThemeProvider themeMode={themeMode}>
      <Notify {...props} />
    </ThemeProvider>
  )
}

const Routes = props => {
  return (
    <>
      <Router history={props.history}>
        <Switch>
          <Route
            path={pageUrl.root()}
            component={props => <AuthChecker innerComponent={ThemedApp} {...props} />}
          />
          <Route path={pageUrl.login()} component={ThemedAuthSwitcher} />
          <Redirect to={pageUrl.root()} />
        </Switch>
      </Router>
      <ThemedNotify customDispatch={Actions.notifications.dispatch} />
    </>
  );
};

export default Routes;
