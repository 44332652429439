import React, { useEffect, useState } from "react";
import { useCurrentLayer, IAppStore, CommunityData } from 'reducers';
import { Tooltip, IconButton, Theme, makeStyles, Snackbar, SnackbarContent, Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, Divider, useTheme } from "@material-ui/core";
import AssessmentIcon from '@material-ui/icons/Assessment';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import lassoImg from './../lasso.png';
import { useShallowSelector } from 'hooks';
import { Actions } from 'actions';
import { useSelector } from 'react-redux';
import { GraphApi } from 'api';
import { useParams } from "react-router-dom";
import { ModalAddLayer } from "containers/LayersDrawer/components";
import ColorizeIcon from '@material-ui/icons/Colorize';
import pink from '@material-ui/core/colors/pink';

interface SelectionToolbarSectionProps {
  eyeDropToolSelectionColor: string
}

interface SelectionToolbarSectionState {
  lassoSelectorButtonColor: string,
  lassoMode: string;
}

const SELECTION_NODES_QUANTITY_THRESHOLD = 5;

const LASSO_COLOR1 = '#375E97';
const LASSO_COLOR2 = '#00e676';

const LASSO_INACTIVE_CLASSNAME = 'lassoSelectionInactive';
const LASSO_ACTIVE_COLOR1_CLASSNAME = 'lassoSelectionColor1';
const LASSO_ACTIVE_COLOR2_CLASSNAME = 'lassoSelectionColor2';

const lassoModes = [LASSO_INACTIVE_CLASSNAME, LASSO_ACTIVE_COLOR1_CLASSNAME, LASSO_ACTIVE_COLOR2_CLASSNAME];
const lassoColors = ['', LASSO_COLOR1, LASSO_COLOR2];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginRight: 10
  },
  lassoSelectionInactive: {
    background: 'transparent'
  },
  lassoSelectionColor1: {
    background: LASSO_COLOR1,
    '&:hover': {
      background: LASSO_COLOR1,
    }
  },
  lassoSelectionColor2: {
    background: LASSO_COLOR2,
    '&:hover': {
      background: LASSO_COLOR2,
    }
  },
  invertColor: {
    filter: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(345deg) brightness(100%) contrast(104%);'
  },
  toolbar: {
    background: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid'
  },
  divider: {
    height: '70%',
    marginRight: 5,
    marginLeft: 5
  }
}));

const useSnackbarStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#4caf50',
    color: theme.palette.common.white,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  messageText: {
    marginLeft: '1rem'
  }
}));

const SelectionToolbarSection = (props: SelectionToolbarSectionProps) => {
  const { currentLayer } = useCurrentLayer();
  const { portfolioId, studyId, fileId, datasetId, modelId, graphId } = useParams();
  const allIds = { portfolioId, studyId, fileId, datasetId, modelId, graphId };
  const coloringModeCommunities = useShallowSelector(s => s.graph.coloringModeCommunities);
  const selectedLayerCommunityIds = useShallowSelector(s => s.graph.selectedLayerCommunityIds);
  const graphData = useShallowSelector(s => s.graph.graphData);
  const graphType = useShallowSelector(s => s.graph.graphType);
  const isDrawerRightVisible = useShallowSelector(
    s => s.common.isDrawerRightVisible,
  );
  const coloring = useSelector((s: IAppStore) => s.graph.coloring);
  const isModalAddLayerOpen = useShallowSelector(
    s => s.graph.isModalAddLayerOpen,
  );
  const selectionColor = useShallowSelector(s => s.graph.selectionColor);
  const eyeDropToolActive = useShallowSelector(s => s.graph.eyeDropToolActive);

  const [state, setState] = useState<SelectionToolbarSectionState>({
    lassoSelectorButtonColor: '',
    lassoMode: LASSO_INACTIVE_CLASSNAME
  });
  const [successfulLayerSaveSnackbarOpen, setSuccessfullLayerSaveSnackbarOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const classes = useStyles();
  const snackbarClasses = useSnackbarStyles();
  const theme = useTheme();

  const { eyeDropToolSelectionColor } = props;

  let additionalClass = classes.lassoSelectionInactive;
  switch (state.lassoMode) {
    case LASSO_ACTIVE_COLOR1_CLASSNAME:
      additionalClass = classes.lassoSelectionColor1;
      break;
    case LASSO_ACTIVE_COLOR2_CLASSNAME:
      additionalClass = classes.lassoSelectionColor2;
      break;
    default:
      additionalClass = classes.lassoSelectionInactive;
  }

  const selectedCommunitiesInLayer = getSelectedCommunities();

  const lassoSelectionClearButtonVisible = selectedCommunitiesInLayer.length > 0;
  const allowCalculation = selectedCommunitiesInLayer.allowCalculate;

  const eyeDropSelectionButtonBackgroundColor = eyeDropToolActive ? eyeDropToolSelectionColor : '';
  const eyeDropSelectionButtonColor = eyeDropSelectionButtonBackgroundColor ? theme.palette.getContrastText(eyeDropSelectionButtonBackgroundColor) : '';

  useEffect(() => {
      Actions.predictors.dispatch({ statisticTable: [] });
  }, [selectedCommunitiesInLayer.length]);

  useEffect(() => {
    setState({
      lassoSelectorButtonColor: '',
      lassoMode: LASSO_INACTIVE_CLASSNAME
    });
    Actions.graph.dispatch({
      selectionColor: ''
    });
  }, [graphType, coloring, isDrawerRightVisible]);

  useEffect(() => {
    setState({
      lassoSelectorButtonColor: selectionColor,
      lassoMode: lassoModes[lassoColors.indexOf(selectionColor)] || LASSO_INACTIVE_CLASSNAME
    })
  }, [selectionColor]);

  function cycleSelectionMode() {
    let currentIndex = lassoModes.indexOf(state.lassoMode);

    currentIndex += 1;
    if (currentIndex >= lassoModes.length) {
      currentIndex = 0;
    }

    const newLassoMode = lassoModes[currentIndex];
    const newLassoSelectorButtonColor = lassoColors[currentIndex];

    setState({
      ...state,
      lassoSelectorButtonColor: newLassoSelectorButtonColor,
      lassoMode: newLassoMode
    });
    Actions.graph.dispatch({
      selectionColor: newLassoSelectorButtonColor
    });
  };

  function getSelectedCommunities() {
    let selectedCommunities = [];

    if (currentLayer) {
      selectedCommunities = (
        currentLayer.communities ?? []
      ).filter(community => selectedLayerCommunityIds.includes(community.id));
    } else {
      selectedCommunities = coloringModeCommunities.filter(
        community => (community !== null)
      ) as CommunityData[];
    }
    const length = selectedCommunities.length;

    return {
      selectedCommunities,
      length,
      allowCalculate: length > 0 && length < 3,
    };
  }

  function handleCalculatePredictorsClick() {
    const nodesTotal = graphData[graphType]?.nodes.length ?? 0;
    const selections = getSelectedCommunities().selectedCommunities;

    const nodesSelectedTotal = selections.reduce((acc, selection) => {
      return acc + selection.nodes.length;
    }, 0);

    if (
      (nodesTotal - nodesSelectedTotal) < SELECTION_NODES_QUANTITY_THRESHOLD ||
      selections.some(({ nodes }) => nodes.length < SELECTION_NODES_QUANTITY_THRESHOLD)
    ) {
      setConfirmationDialogOpen(true);
    } else {
      calculatePredictors(selections);
    }
  }

  function handleConfirmationDialogProceedClick() {
    const selections = getSelectedCommunities().selectedCommunities;
    setConfirmationDialogOpen(false);
    calculatePredictors(selections);
  }

  function calculatePredictors(selections: CommunityData[]) {
    GraphApi.getPredictors({
      ...allIds,
      graphType,
      selections: selections.map(({ nodes, color, title }) => ({
        nodes,
        color,
        title
      }))
    }).then(() => {
      Actions.predictors.dispatch({ autoOpen: true });
    });
  }

  function handleLassoSelectionClearClick() {
    setState({...state, lassoSelectorButtonColor: ''});
    Actions.graph.dispatch({
      coloringModeCommunities: [null, null],
      selectionColor: '',
    });
    Actions.predictors.dispatch({ statisticTable: [] });
  }

  function handleModalAddLayerClose() {
    Actions.graph.dispatch({ isModalAddLayerOpen: false });
  }

  function handleSaveButtonClick() {
    Actions.graph.dispatch({ isModalAddLayerOpen: true });
  }

  function saveSelectionToLayer(title: string, additionalInfo: string) {
    const data = {
      ...allIds,
      title,
      additionalInfo,
      graphType,
      isDefault: false,
      communities: coloringModeCommunities.filter(community => community !== null),
    };
    return GraphApi.addLayer(data).then(() => {
      setSuccessfullLayerSaveSnackbarOpen(true);
    });
  }

  function handleSuccessLayerSaveSnackbarClose(event?: React.SyntheticEvent, reason?: string) {
    setSuccessfullLayerSaveSnackbarOpen(false);
  }

  function handleEyeDropToolClick() {
    Actions.graph.dispatch({
      eyeDropToolActive: !eyeDropToolActive
    });
  }

  return (
    <>
      {isDrawerRightVisible ? (
        <>
          <div>
            <Tooltip
              title="Select community by node color"
              placement="top"
            >
              <IconButton
                onClick={handleEyeDropToolClick}
                style={{
                  backgroundColor: eyeDropSelectionButtonBackgroundColor,
                  color: eyeDropSelectionButtonColor,
                  boxShadow: eyeDropToolActive ? `0 0 0 1px ${eyeDropSelectionButtonColor}` : ''
                }}
              >
                <ColorizeIcon />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ) : (
        <>
          <div>
            <Tooltip
              title="Select some nodes using lasso"
              placement="top"
            >
              <IconButton
                onClick={cycleSelectionMode}
                className={additionalClass}
              >
                <span className="lasso-img-wrapper">
                  <img
                    alt={''}
                    src={lassoImg}
                    className={state.lassoMode === LASSO_INACTIVE_CLASSNAME ? '' : classes.invertColor}
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
                </span>
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Clear selection" placement="top">
              <div>
                <IconButton
                  onClick={handleLassoSelectionClearClick}
                  disabled={!lassoSelectionClearButtonVisible}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        </>
      )}
      <div>
        <Tooltip title="Calculate predictors" placement="top">
          <div>
            <IconButton
              disabled={!allowCalculation}
              onClick={handleCalculatePredictorsClick}
            >
              <AssessmentIcon />
            </IconButton>
          </div>
        </Tooltip>
      </div>
      {!isDrawerRightVisible ? (
        <>
          <div>
            <Tooltip title="Save selection to layer" placement="top">
              <div>
                <IconButton
                  disabled={!allowCalculation}
                  onClick={handleSaveButtonClick}
                >
                  <SaveIcon />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        </>
      ) : null}
      <Snackbar
        open={successfulLayerSaveSnackbarOpen}
        onClose={handleSuccessLayerSaveSnackbarClose}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <SnackbarContent
          classes={snackbarClasses}
          message={(
            <>
              <CheckCircleIcon/>
              <div className={snackbarClasses.messageText}>
                Layer saved successfully
              </div>
            </>
          )}
        />
      </Snackbar>
      <ModalAddLayer
        addLayer={saveSelectionToLayer}
        showModal={isModalAddLayerOpen}
        handleClose={handleModalAddLayerClose}
      />
      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={confirmationDialogOpen}
      >
        <DialogTitle id="confirmation-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            One of selected groups is too small. Calculation results may be irrelevant.<br />
            Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationDialogOpen(false)} variant="contained">
            Cancel
          </Button>
          <Button onClick={handleConfirmationDialogProceedClick} variant="contained" color="primary">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export {
  SelectionToolbarSection,
  LASSO_COLOR1,
  LASSO_COLOR2
};