import React from "react";
import { Divider, makeStyles, Tooltip } from "@material-ui/core";
import capitalize from 'lodash/capitalize';
import { TextWrapper } from "components";
import clsx from 'clsx';

interface DataRow {
  color: string;
  cells: string[];
  faded: boolean;
}

interface HeaderCell {
  label: string;
  tooltip: string;
}

interface PredictorsBarSummaryProps {
  headerCells: HeaderCell[];
  dataRows: DataRow[];
}

const useStyles = makeStyles(theme => ({
  root: {
    margin: '7px 0',
    flex: 'none'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 0 7px',
  },
  cellText: {
    minWidth: '20px',
    flex: 1,
    fontWeight: 'bold'
  },
  faded: {
    opacity: 0.3,
    transition: 'opacity 0.3s'
  }
}));

const PredictorsBarSummary = (props: PredictorsBarSummaryProps) => {
  const { headerCells, dataRows } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        {headerCells.map(({ label, tooltip}, index) => (
          <Tooltip title={tooltip} placement="top">
            <TextWrapper
              key={`${label}${index}`}
              align="center"
              className={classes.cellText}
            >
              {capitalize(label)}
            </TextWrapper>
          </Tooltip>
        ))}
      </div>

      {dataRows.map(({ color, cells, faded }) => (
        <>
          <Divider />
          <div className={clsx(classes.row, faded ? classes.faded : '')}>
            {cells.map((cellText, index) => (
              <TextWrapper
                key={`${index}`}
                align="center"
                className={classes.cellText}
                style={{ color }}
              >
                {capitalize(cellText)}
              </TextWrapper>
            ))}
          </div>
        </>
      ))}
    </div>
  );
};
export default PredictorsBarSummary;