import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import { TextWrapper } from 'components';
import { DatasetApi, PortfolioApi } from 'api';
import { Table, Loading } from 'components';
import { ModalAddModel, ModalDelete } from '../index';

import './Dataset.css';

const columnsConfig = [
  { id: 'name', label: 'Name' },
  { id: 'additionalInfo', label: 'Description' },
];

const rowsConfig = [
  { prop: 'name', format: (val: string) => (val ? val : '-') },
  { prop: 'additionalInfo', format: (val: string) => (val ? val : '-') },
];

const Dataset = ({ dataset: datasetUpper }: any) => {
  const { portfolioId, studyId, fileId, datasetId } = useParams();
  const allIds = { portfolioId, studyId, fileId, datasetId };

  const [showAddModel, setShowAddModel] = useState(false);
  const [showDeleteDataset, setShowDeleteDataset] = useState(false);
  const [dataset, setDataset] = useState({
    title: '',
    additionalInfo: '',
    created: '',
  });

  useEffect(() => {
    if (!datasetUpper?.id) {
      DatasetApi.getDatasetDetails(allIds).then(({ data }: any) => {
        setDataset(data);
      });
    }
  }, []);

  function handleDeleteFile() {
    return DatasetApi.deleteDataset(allIds).then(() => {
      setShowDeleteDataset(false);
      return PortfolioApi.getPortfoliosTree();
    });
  }

  const title = datasetUpper?.title ?? dataset.title;
  const additionalInfo = datasetUpper?.additionalInfo ?? dataset.additionalInfo;
  const created = new Date(
    parseInt(datasetUpper?.created || dataset.created),
  ).toLocaleString();
  return (
    <div>
      <div className="profile-heading-wrapper">
        <div className="profile-title-wrapper">
          <TextWrapper variant="h6">Dataset: {title}</TextWrapper>
          <TextWrapper variant="subtitle2">Info: {additionalInfo}</TextWrapper>
          <TextWrapper variant="subtitle2">Created at: {created}</TextWrapper>
        </div>
      </div>
      <div className="portfolio-controls-section">
        {/* <Button className="portfolio-btn" variant="outlined">
          Add Study
        </Button> */}
        <Button onClick={() => setShowDeleteDataset(true)} variant="outlined">
          Delete Dataset
        </Button>
      </div>

      <Table
        showRadio
        topMargin={158}
        showDeleteButton={true}
        showControlColumn={true}
        noItemsFoundPlaceholder={''}
        loading={false}
        columnsConfig={columnsConfig}
        rowsConfig={rowsConfig}
        LoadingComponent={Loading}
        tableName=""
        items={[]}
        rowClassName="row"
        page={1}
        rowsPerPage={5}
        order={'desc'}
        orderBy={'name'}
        addButtonItemName="Add Model"
        // showAddButton={false}
        totalRowsQuantity={0}
        rowsPerPageOptions={[10]}
        onDeleteClick={user => {}}
        onChangePage={(e, nextPge) => {}}
        onColumnHeaderClick={(order, { id: orderBy }) => {}}
        onAddButtonClick={() => setShowAddModel(true)}
      />
      <ModalAddModel
        showModal={showAddModel}
        handleClose={() => setShowAddModel(false)}
      />

      <ModalDelete
        showModal={showDeleteDataset}
        handleClose={() => setShowDeleteDataset(false)}
        warningMessage="Are you sure you want to delete this dataset?"
        entityName="Dataset"
        deleteEntity={handleDeleteFile}
      />
    </div>
  );
};

export default Dataset;
