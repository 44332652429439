import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import DescriptionIcon from '@material-ui/icons/Description';
import AppsIcon from '@material-ui/icons/Apps';
// import BarChartIcon from '@material-ui/icons/BarChart';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Tooltip from '@material-ui/core/Tooltip';
// import TableChartIcon from '@material-ui/icons/TableChart';
// import BubbleChartIcon from '@material-ui/icons/BubbleChart';
// import GrainIcon from '@material-ui/icons/Grain';

import { Drawer } from 'components';
import { Actions } from 'actions';
import { pageUrl, history } from 'config';

import './styles.css';

const DRAWER_WIDTH = '54px';

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: grey[900],
    userSelect: 'none',
    height: 'calc(100vh - 64px)',
    overflowY: 'auto',
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const PAGES_NAMES = {
  main: 'main',
  graph: 'graph',
  users: 'users',
};

const PAGES_DICTIONARY = {
  [pageUrl.main()]: PAGES_NAMES.main,
  [pageUrl.graph()]: PAGES_NAMES.graph,
  [pageUrl.users.users()]: PAGES_NAMES.users,
};

const NavBar = () => {
  const isDrawerRightOpen = true;
  const drawerRightVariant = 'persistent';
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { pathname } = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };

  function handleDrawerRightClose() {
    Actions.common.dispatch({ isDrawerRightOpen: false });
  }

  function isSelectedNavItem(navItemName) {
    const currentPageName = PAGES_DICTIONARY[pathname];
    switch (true) {
      case currentPageName === navItemName:
        return true;
    }
  }

  function goTo(url) {
    history.push(url);
  }

  return (
    <Drawer
      drawerWidth={DRAWER_WIDTH}
      drawerVariant={drawerRightVariant}
      side="left"
      isOpen={isDrawerRightOpen}
      handleClose={handleDrawerRightClose}
      showToolbar={false}
    >
      <div className="nav-bar-wrapper">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <ListItem
            onClick={() => goTo(pageUrl.main())}
            button
            selected={isSelectedNavItem(PAGES_NAMES.main)}
          >
            <ListItemIcon>
              <Tooltip title="Portfolios" placement="right">
                <AppsIcon />
              </Tooltip>
            </ListItemIcon>
          </ListItem>
          {/* <ListItem disabled button>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Files" />
          </ListItem> */}
          {/* <ListItem disabled button onClick={handleClick}>
            <ListItemIcon>
              <TableChartIcon />
            </ListItemIcon>
            <ListItemText primary="DataSets" />
          </ListItem> */}
          {/* <ListItem
            onClick={() => goTo(pageUrl.graph())}
            button
            selected={isSelectedNavItem(PAGES_NAMES.graph)}
          >
            <ListItemIcon>
              <GrainIcon />
            </ListItemIcon>
            <ListItemText primary="Models" />
          </ListItem> */}
          {/* <ListItem disabled button onClick={handleClick}>
            <ListItemIcon>
              <BubbleChartIcon />
            </ListItemIcon>
            <ListItemText primary="Communities" />
          </ListItem> */}
          {/* <ListItem disabled button onClick={handleClick}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Analysis" />
          </ListItem> */}
        </List>

        {/* BOTTOM LIST */}
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <ListItem
            onClick={() => goTo(pageUrl.users.users())}
            button
            selected={isSelectedNavItem(PAGES_NAMES.users)}
          >
            <ListItemIcon>
              <Tooltip title="Users management" placement="right">
                <GroupAddIcon />
              </Tooltip>
            </ListItemIcon>
          </ListItem>
          {/* <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItem> */}
        </List>
      </div>
    </Drawer>
  );
};

export default NavBar;
