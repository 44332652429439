export const constants = {
  AUTH: 'AUTH',
  COMMON: 'COMMON',
  NOTIFICATIONS: 'NOTIFICATIONS',
  REQ: 'REQ',
  GRAPH: 'GRAPH',
  TABS: 'TABS',
  USERS: 'USERS',
  PORTFOLIOS: 'PORTFOLIOS',
  STUDIES: 'STUDIES',
  FILES: 'FILES',
  DATASETS: 'DATASETS',
  MODELS: 'MODELS',
  GRAPHS: 'GRAPHS',
  FLAGS: 'FLAGS',
  SQLQUERIES: 'SQLQUERIES'
};
