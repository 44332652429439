import React, { useRef, useState } from "react";
import { IAppStore } from 'reducers';
import { FormControl, Select, MenuItem, Theme, makeStyles, TextField, Tooltip, FormControlLabel, Checkbox, IconButton, Divider } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { useShallowSelector } from 'hooks';
import { Actions } from 'actions';
import { useSelector } from 'react-redux';
import { GraphApi } from 'api';
import { useParams } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StarIcon from '@material-ui/icons/Star';
import StarOutlinedIcon from '@material-ui/icons/StarBorder';
import CircularProgress from '@material-ui/core/CircularProgress';

type ColoringItemData = {
  id: string;
  resourceTitle: string;
  columnType: string;
  definition: string;
  balancing: boolean;
  isDefault: boolean;
  isProjection: boolean;
  isMetricOnly: boolean;
  isFavorite: boolean;
  title: string;
  tooltip: string[][];
}

type AutocompleteOptionData = ColoringItemData & {
  isDivider: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  coloringSettingsWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  coloringDropdownContainer: {
    marginRight: 10
  },
  favoriteIconWrapper: {
    paddingRight: 8
  },
  favoriteActive: {
    color: '#ffab00'
  },
  favoriteHidden: {
    visibility: 'hidden'
  },
  autocompleteOptionsDivider: {
    flexGrow: 1,
    //magic setting to make divider have the same color as regular, since disabled options have opacity
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  },
  favoriteIconButtonProgress: {
    position: 'absolute',
  },
}));

const ColoringToolbarSection = () => {
  const { portfolioId, studyId, fileId, datasetId, modelId, graphId } = useParams();
  const allIds = { portfolioId, studyId, fileId, datasetId, modelId, graphId };
  const graphData = useShallowSelector(s => s.graph.graphData);
  const graphType = useShallowSelector(s => s.graph.graphType);
  const coloring = useSelector((s: IAppStore) => s.graph.coloring);
  const balancedColoring = useSelector((s: IAppStore) => s.graph.balancedColoring);
  const listColorings = useShallowSelector(s => s.graph.list_colorings);
  const coloringListItems = listColorings as ColoringItemData[];

  const classes = useStyles();

  const [coloringAutocompleteUpdating, setSoloringAutocompleteUpdating] = React.useState(false);
  const [newColoringLoading, setNewColoringLoading] = useState(false);

  const coloringListMap = new Map<string, AutocompleteOptionData>();

  const coloringAutocompleteOptionsWithDivider = React.useMemo(() => {
    let dividerAdded = false;
    let thereAreFavoriteColoringsLocal = false;

    return organizeColoringList(coloringListItems).reduce<AutocompleteOptionData[]>((acc, autocompleteOptionData, index) => {
      const { isFavorite } = autocompleteOptionData;
      if (isFavorite) {
        thereAreFavoriteColoringsLocal = true;
      }
      if (!isFavorite && !dividerAdded && thereAreFavoriteColoringsLocal) {
        acc.push({
          ...autocompleteOptionData,
          isDivider: true,
          isFavorite: false
        });
        dividerAdded = true;
      }
      const optionData = {
        ...autocompleteOptionData,
        isFavorite,
        isDivider: false
      };
      acc.push(optionData);
      return acc;
    }, []);
  }, [coloringListItems]);

  let thereAreFavoriteColorings = false;
  coloringAutocompleteOptionsWithDivider.forEach(optionData => {
    const { title, isDivider, isFavorite } = optionData;
    if (isFavorite) {
      thereAreFavoriteColorings = true;
    }
    if (!isDivider) {
      coloringListMap.set(title, optionData);
    }
  });

  const coloringSelectedData = coloringListMap.get(coloring) || {
    id: '',
    resourceTitle: '',
    columnType: '',
    definition: '',
    balancing: false,
    isDefault: false,
    isProjection: false,
    isMetricOnly: false,
    isFavorite: false,
    isDivider: false,
    title: '',
    tooltip: []
  };

  if (coloring === '') {
    const [defaultColoring] = coloringListItems.filter(({ isDefault }) => isDefault);
    Actions.graph.dispatch({
      coloring: defaultColoring.title,
      balancedColoring: false,
    });
  }

  function handleColoringChange(event: unknown, newValue: AutocompleteOptionData) {
    const newColoring = newValue.title;

    Actions.graph.dispatch({
      coloringModeCommunities: [null, null],
      coloring: newColoring,
      balancedColoring: false,
    });
    updateGraph(newColoring, false);
  };

  function handleBalancedCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const checked = e.target.checked;
    if (coloringSelectedData) {
      Actions.graph.dispatch({
        coloringModeCommunities: [null, null],
        coloring: coloringSelectedData.title,
        balancedColoring: checked,
      });
      updateGraph(coloring, checked);
    }
  }

  function updateGraph(newColoring: string, balancedColoringValue: boolean) {
    const coloringData = coloringListMap.get(newColoring);
    if (coloringData) {
      setNewColoringLoading(true);
      GraphApi.getColoring({
          ...allIds,
          graphId: graphData.id,
          colorBy: newColoring,
          isBalanced: balancedColoringValue,
          isProjection: coloringData.isProjection
        },
        graphData
      ).finally(() => {
        setNewColoringLoading(false);
      });
    }
  }

  function organizeColoringList(listItems: ColoringItemData[]) {
    const result = [...listItems];
    result.sort((a, b) => {
      //{"is_favorite": "desc", "is_default": "desc", "is_projection": "desc", "title": "asc"}`
      const values1 = [a.isFavorite, a.isDefault, a.isProjection];
      const values2 = [b.isFavorite, b.isDefault, b.isProjection];

      for (let i = 0; i < values1.length; i++) {
        const value1 = Number(values1[i]);
        const value2 = Number(values2[i]);
        if (value1 !== value2) {
          return value2 - value1;
        }
      }
      return a.title.localeCompare(b.title, 'en', {numeric: true})
    });
    return result;
  }

  function changeColoringFavoriteStatus(coloringItemData: ColoringItemData, isFavorite: boolean) {
    const { resourceTitle, id } = coloringItemData;

    setSoloringAutocompleteUpdating(true);
    GraphApi.changeColoringFavoriteStatus({
      resourceTitle,
      resourceId: id,
      isFavorite,
      graphId: graphData.id
    }).then(() => {
      const newColoringList = coloringListItems.map(coloringListItemData => {
        if (coloringListItemData.id === id) {
          return {
            ...coloringListItemData,
            isFavorite
          }
        }
        return coloringListItemData;
      })
      Actions.graph.dispatch({
        list_colorings: newColoringList
      });
    }).finally(() => {
      setSoloringAutocompleteUpdating(false);
    })
  }

  function getTooltipText(option: AutocompleteOptionData) {
    const tooltipRows = option.tooltip;
    if (tooltipRows.length) {
      return (
        <div>
          {tooltipRows.map(([label, value]) => (
            <div>{value !== '' ? [label, value].join(': ') : label}</div>
          ))}
        </div>
      );
    }
    return '';
  }

  return (
    <>
      <div className={classes.coloringSettingsWrapper}>
        <Tooltip
          title="Toggle node color favorite status"
          placement="top"
        >
          <IconButton disabled={coloringAutocompleteUpdating || newColoringLoading} onClick={() => {
            changeColoringFavoriteStatus(coloringSelectedData, !coloringSelectedData.isFavorite);
          }}>
            { coloringSelectedData.isFavorite ? (
                <StarIcon className={classes.favoriteActive}/>
              ) : (
                <StarOutlinedIcon />
              )
            }
            {newColoringLoading || coloringAutocompleteUpdating ? (
              <CircularProgress className={classes.favoriteIconButtonProgress}/>
            ) : null}
          </IconButton>
        </Tooltip>
        <FormControl className={classes.coloringDropdownContainer} style={{width: 150}}>
          <Autocomplete
            disabled={newColoringLoading}
            disableClearable
            //disableCloseOnSelect
            //open
            options={coloringAutocompleteOptionsWithDivider}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <Tooltip title={getTooltipText(coloringSelectedData)} placement="top">
                <TextField {...params} label="Node Color" variant="outlined" />
              </Tooltip>
            )}
            renderOption={option => {
              const { title, isDivider, isFavorite } = option;
              const tooltipText = getTooltipText(option);

              return isDivider ? (
                <Divider orientation="horizontal" className={classes.autocompleteOptionsDivider} />
              ) : (
                <>
                  {isFavorite ? (
                    <span className={classes.favoriteIconWrapper}>
                      <StarIcon fontSize="small" className={classes.favoriteActive} />
                    </span>
                  ) : (
                    thereAreFavoriteColorings ? (
                      <span className={classes.favoriteIconWrapper}>
                        <StarOutlinedIcon fontSize="small" className={classes.favoriteHidden} />
                      </span>
                    ) : null
                  )}
                  <Tooltip title={tooltipText} placement="top">
                    <Typography noWrap style={{textOverflow: 'ellipsis'}}>{title}</Typography>
                  </Tooltip>
                </>
              );
            }}
            getOptionDisabled={(option) => {
              if (graphType === 'clustered') {
                return option.isMetricOnly;
              }
              return option.isDivider;
            }}
            size="small"
            value={coloringSelectedData}
            onChange={handleColoringChange}
          />
        </FormControl>
        <Tooltip
          title="Toggle balanced node color"
          placement="top"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={balancedColoring}
                disabled={!coloringSelectedData.balancing || newColoringLoading}
                onChange={handleBalancedCheckboxChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Balanced"
          />
        </Tooltip>
      </div>
    </>
  );
};

export default ColoringToolbarSection;