import React, { useEffect } from 'react';

import { Route, Switch } from 'react-router-dom';

import { pageUrl } from 'config';
import {
  Portfolio,
  Study,
  ProfileWrapper,
  File,
  Dataset,
  Model,
} from '../index';

const ProfileRoutes = ({ currentEntity }) => {
  return (
    <Switch>
      <Route exact path={pageUrl.portfolio()}>
        <ProfileWrapper>
          <Portfolio portfolio={currentEntity} />
        </ProfileWrapper>
      </Route>
      <Route exact path={pageUrl.study()}>
        <ProfileWrapper>
          <Study study={currentEntity} />
        </ProfileWrapper>
      </Route>
      <Route exact path={pageUrl.file()}>
        <ProfileWrapper>
          <File file={currentEntity} />
        </ProfileWrapper>
      </Route>
      <Route exact path={pageUrl.dataset()}>
        <ProfileWrapper>
          <Dataset dataset={currentEntity} />
        </ProfileWrapper>
      </Route>
      <Route exact path={pageUrl.model()}>
        <ProfileWrapper>
          <Model model={currentEntity} />
        </ProfileWrapper>
      </Route>
    </Switch>
  );
};

export default ProfileRoutes;
