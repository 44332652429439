import React, { Component } from 'react';

import './PresetSelector.css';

export default class PresetSelector extends Component {
  render() {
    const { styleCustom = {}, onClick = () => {} } = this.props;
    return (
      <div className="button-preset-selector">
        <div
          onClick={onClick}
          style={{
            display: 'inline-block',
            background: 'lightgray',
            width: '24px',
            height: '24px',
            borderRadius: '3px',
            position: 'relative',
            ...styleCustom,
          }}
        >
          <span
            style={{
              position: 'absolute',
              top: '2px',
              left: '2px',
              display: 'inline-block',
              height: '20px',
              width: '9px',
              borderRadius: '2px',
              background: 'gray',
            }}
          />
          <span
            style={{
              position: 'absolute',
              top: '2px',
              left: '13px',
              display: 'inline-block',
              height: '9px',
              width: '9px',
              borderRadius: '2px',
              background: 'gray',
            }}
          />
          <span
            style={{
              position: 'absolute',
              top: '13px',
              left: '13px',
              display: 'inline-block',
              height: '9px',
              width: '9px',
              borderRadius: '2px',
              background: 'gray',
            }}
          />
        </div>
      </div>
    );
  }
}
