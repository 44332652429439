import each from 'lodash/each';

import { Actions } from 'actions';
import { apiUrl } from 'config';

export class PortfolioApi {
  static createPortfolio = (title, additionalInfo) => {
    return Actions.req.POST({
      url: apiUrl.portfolios.createPortfolio(),
      data: { title, additionalInfo },
      mapData: response => {
        console.log(response);
        return {};
      },
    });
  };

  static listPortfolios = () => {
    return Actions.portfolios.POST({
      url: apiUrl.portfolios.listPortfolios(),
      // mapData: ({ data }) => ({ portfolios: data?.items ?? [] }),
    });
  };

  static deletePortfolio = allIds => {
    return Actions.req.DELETE({
      url: apiUrl.portfolios.deletePortfolio(),
      data: { force: true, ...allIds },
    });
  };

  static getPortfolioDetails = portfolioId => {
    return Actions.req.POST({
      url: apiUrl.portfolios.getPortfolioDetails(),
      data: { portfolioId },
      // mapData: ({ data }) => ({ portfolios: data?.items ?? [] }),
    });
  };

  static getPortfoliosTree = () => {
    return Actions.portfolios
      .GET({
        url: apiUrl.portfolios.getPortfoliosTree(),
        // mapData: ({ data }) => ({ portfolios: data?.items ?? [] }),
      })
      .then(({ data: { tree: portfolios, userData, permissions } }) => {
        const entitiesWithChildIds = [];

        each(portfolios, portfolio => {
          portfolio.studies.length && entitiesWithChildIds.push(portfolio.id);

          each(portfolio.studies, study => {
            study.files.length && entitiesWithChildIds.push(study.id);

            each(study.files, file => {
              file.datasets.length && entitiesWithChildIds.push(file.id);
            });
          });
        });
        Actions.portfolios.dispatch({ portfolios });
        return { entitiesWithChildIds };
      });
  };
}
