import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip, IconButton, makeStyles } from "@material-ui/core";
import {
  TextWrapper,
  PredictorsBarColorIndicator,
  PredictorsBarSummary,
} from 'components';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

import './PredictorsBarChart.css';
import Chart from 'containers/Chart/Chart';
import { connect } from 'react-redux';
import { BarChartData } from 'reducers';

//this value is used in an attempt to prevent labels overlapping in complex_bar_chart
const MAGIC_MAX_NUMBER_OF_SUMMARY_LABELS = 4;

interface PredictorsBarChartProps {
  svgWidth?: number;
  svgHeight?: number;
  onUnpin?(predictorName: string): void;
  onChartClick?: (predictorName: string) => void;
  displayMode?: '' | 'dialog';
  predictorName: string;
  predictor: any;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    position: 'relative',
    borderRadius: '7px',
    margin: '8px 8px 8px 13px',
    boxShadow: '0px 5px 5px -2px rgba(0,0,0,.3)',
  },
  rootDialog: {
    border: 0,
    margin: 0,
    boxShadow: 'none'
  },
  svgWrapper: {
    position: 'relative',
    flex: 'auto',
    overflow: 'hidden',
    cursor: 'pointer'
  },
  svgWrapperDialog: {
    cursor: 'default'
  }
}));

const PredictorsBarChart = (props: PredictorsBarChartProps) => {
  const {
    svgWidth = 400,
    svgHeight = 200,
    onUnpin = () => {},
    onChartClick = () => {},
    displayMode = '',
    predictorName,
    predictor
  } = props;

  const { chart } = predictor;
  const [color1, color2] = chart.colors.flat();
  const [label1, label2] = chart.labels.flat();

  const summaryLabels = predictor.summary[0];
  const replaceSummaryLabelsInComplexBarchart = chart.type === 'complex_bar_chart' && summaryLabels.length > MAGIC_MAX_NUMBER_OF_SUMMARY_LABELS;

  const summaryHeaderCells = (
    summaryLabels.map((text: string, index: number) => ({
      label: replaceSummaryLabelsInComplexBarchart ? `${index + 1}` : text,
      tooltip: replaceSummaryLabelsInComplexBarchart ? text : ''
    }))
  );
  const chartData = useMemo(() => {
    const additionalChartData = {
      xAxisTooltips: replaceSummaryLabelsInComplexBarchart ? (
        (chart as BarChartData).xAxis.map((chartXAxis) => (
          chartXAxis.map((label) => label)
        ))
      ) : null,
      xAxis: replaceSummaryLabelsInComplexBarchart ? (
        (chart as BarChartData).xAxis.map((chartXAxis) => (
          chartXAxis.map((label, index) => `${index + 1}`)
        ))
      ) : chart.xAxis
    }
    return {
      ...chart,
      ...additionalChartData
    }
  }, [chart]);

  const [chartsInfo, setChartsInfo] = useState(
    [
      {
        label: label1,
        color: color1,
        visible: true,
        rugs: true,
        kdePoints: true
      },
      {
        label: label2,
        color: color2,
        visible: true,
        rugs: true,
        kdePoints: true
      }
    ]
  );

  const classes = useStyles();

  function onUnpinClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (onUnpin) {
      onUnpin(predictorName);
    }
    e.stopPropagation();
  }

  return (
    <div
      className={clsx(classes.root, displayMode === 'dialog' ? classes.rootDialog : '')}
    >
      {displayMode !== 'dialog' ? (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 5px'
        }}>
          <Tooltip title={chart.title} placement="top">
            <InfoIcon />
          </Tooltip>
          <TextWrapper
            style={{ margin: '0 7px', fontWeight: 'bold' }}
            variant="h6"
          >
            {predictorName}
          </TextWrapper>
          <div style={{marginLeft: 'auto'}}>
            <IconButton onClick={onUnpinClick}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      ) : null}
      <PredictorsBarColorIndicator
        chartsInfo={chartsInfo}
        onChartsVisibilityChange={({ chartsVisibility, rugsVisibility, kdePointsVisibility }) => {
          const newChartsInfo = chartsInfo.map((chartState, index) => {
            return {
              ...chartState,
              visible: chartsVisibility[index],
              rugs: rugsVisibility,
              kdePoints: kdePointsVisibility
            }
          });
          setChartsInfo(newChartsInfo);
        }}
        showRugsAndKdeOptions={displayMode === 'dialog' && chart.type === 'complex_histogram'}
      />
      <div
        className={clsx(classes.svgWrapper, displayMode === 'dialog' ? classes.svgWrapperDialog : '')}
        onClick={(e) => {
          e.stopPropagation();
          onChartClick(predictorName);
        }}
      >
        <Chart
          chartData={chartData}
          viewBoxWidth={svgWidth}
          viewBoxHeight={svgHeight}
          chartsVisibility={chartsInfo}
          renderingOptions={{
            kdePoints: displayMode === 'dialog',
            rugs: displayMode === 'dialog'
          }}
          additionalBarStyle={{
            stroke: '#999',
            'stroke-width': '1'
          }}
        />
      </div>

      <PredictorsBarSummary
        headerCells={summaryHeaderCells}
        dataRows={[
          {
            color: color1,
            cells: predictor.summary[1],
            faded: !chartsInfo[0].visible,
          }, {
            color: color2,
            cells: predictor.summary[2],
            faded: !chartsInfo[1].visible,
          }
        ]}
      />
    </div>
  );
};

export default PredictorsBarChart;
