import React, { useEffect, useState, useRef, useLayoutEffect, useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';

import { Actions } from 'actions';
import { d3, DEFAULT_NODE_COLOR, scaleCanvas } from 'utils';
import { useShallowSelector } from 'hooks';
import { useCurrentLayer, CommunityData, IAppStore, GraphNodeData, GraphNodesAndLinksData, GraphLinkData } from 'reducers';
import { BarChart, GraphInfoPlate, LayersDrawer } from 'containers';
import { lassoSelector } from './localUtils';

import './ForceChartCanvas.css';
import 'd3-selection-multi';
import { makeStyles, Tooltip, Toolbar, Button, Slide } from '@material-ui/core';
import { LASSO_COLOR1, LASSO_COLOR2 } from './toolbar-components/SelectionToolbarSection';
import ForceChartCanvasToolbar from './ForceChartCanvasToolbar';
import { useSelector } from 'react-redux';
import { CanvasUISettingsSection, QueryResultDisplayMode } from './toolbar-components/CanvasUISettingsSection';
import { PredictorsTable } from 'containers';

import CloseIcon from '@material-ui/icons/Close';
import ResizableSplitSectionChild from 'components/ResizableLayout/ResizableLayoutSectionChild';
import ResizableSplitSectionParent from 'components/ResizableLayout/ResizableSplitSectionParent';
import PredictorsCharts from 'containers/PredictorsTable/PredictorsCharts';
import Chart from 'containers/Chart/Chart';
import LassoNodesSelector from './components/Lasso';
import { D3ZoomEvent } from 'd3';

type NodeTooltipState = {
  x: number
  y: number
  visible: boolean,
  tooltipLines: string[],
  nodeId: string | null
}

type NodeToDraw = GraphNodeData & {
  nodeIndex: number;
  color: string;
  selected: boolean,
  queried: boolean
};

type LinkToDraw = GraphLinkData & {
  source: NodeToDraw,
  sourceIndex: number,
  target: NodeToDraw,
  targetIndex: number
};

const useStyles = makeStyles(theme => ({
  chartWrapper: {
    position: 'absolute',
    bottom: '15px',
    left: 0,
    width: '380px',
    paddingTop: 8,
    '&:hover': {
      borderRadius: 4,
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[4]
    }
  }
}));

const useTooltipStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: '0.75rem'
  }
}));

const useBottomBarStyles = makeStyles(theme => ({
  bottomBar: {
    background: theme.palette.background.paper,
    borderTopWidth: 1,
    borderTopColor: theme.palette.divider,
    borderTopStyle: 'solid'
  },
}));

//const globalDevicePixelRatio = window.devicePixelRatio || 1;
//setting this to fixed value, since chrome has performance issues when drawing lines with width > 1
const globalDevicePixelRatio = 1;

function scaleAndTranslateNodesAndLinks(
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  translateX: number = 0,
  translateY: number = 0,
  graphData: GraphNodesAndLinksData,
  selectedCommunities: Array<CommunityData | null> = [],
  graphType: string
) {
  console.log('adaptNodesAndLinks', selectedCommunities.length)
  const { nodes, links } = cloneDeep(graphData);

  const [minX, maxX] = d3.extent(nodes, d => Number(d.x));
  const [minY, maxY] = d3.extent(nodes, d => Number(d.y));
  xScale.domain([minX ?? 0, maxX ?? 0]);
  yScale.domain([minY ?? 0, maxY ?? 0]);

  let nodesScaled: NodeToDraw[] = nodes.map((d, index: number) => ({
    ...d,
    x: (xScale(d.x) ?? 0) + translateX,
    y: (yScale(d.y) ?? 0) + translateY,
    color: d.fillColor,
    nodeIndex: index,
    selected: false,
    queried: false
  }));

  let linksAdapted: LinkToDraw[] = links.map(linkData => ({
    ...linkData,
    source: nodesScaled[linkData.s],
    sourceIndex: linkData.s,
    target: nodesScaled[linkData.t],
    targetIndex: linkData.t
  }));
  return { nodes: nodesScaled, links: linksAdapted, graphType };
}

const ForceChartCanvas = () => {
  const themeMode = useShallowSelector(s => s.common.themeMode);
  const graphType = useShallowSelector(s => s.graph.graphType);
  const selectionColor = useShallowSelector(s => s.graph.selectionColor);
  const { currentLayer } = useCurrentLayer();
  const graphData = useShallowSelector(s => s.graph.graphData);
  const coloringData = useShallowSelector(s => s.graph.coloringData);
  const layers = useShallowSelector(s => s.graph.layers);
  const coloring = useSelector((s: IAppStore) => s.graph.coloring);
  const coloringModeCommunities = useShallowSelector(s => s.graph.coloringModeCommunities);
  const selectedLayerCommunityIds = useShallowSelector((s: IAppStore) => s.graph.selectedLayerCommunityIds);
  const sqlQueryResult = useShallowSelector((s: IAppStore) => s.graph.sqlQueryResult);

  const chartData = useSelector((s: IAppStore) => s.graph.chartData);

  const width = useShallowSelector(s => s.common.width);
  const forceCanvasResize = useShallowSelector(s => s.flags.forceCanvasResize);
  const forceUpdate = useShallowSelector(s => s.flags.forceUpdate);
  const forceUpdateEnd = useShallowSelector(s => s.flags.forceUpdateEnd);
  const coloringUpdate = useShallowSelector(s => s.flags.coloringUpdate);
  const eyeDropToolActive = useShallowSelector(s => s.graph.eyeDropToolActive);
  const isDrawerLeftVisible = useShallowSelector(
    s => s.common.isDrawerLeftVisible,
  );
  const isDrawerRightVisible = useShallowSelector(
    s => s.common.isDrawerRightVisible,
  );
  const isDrawerRightResizeEnd = useShallowSelector(
    s => s.common.isDrawerRightResizeEnd,
  );

  const [eyeDropSelectionColor, setEyeDropSelectionColor] = useState('');
  const [state, setState] = useState({
    isDetailedGraphType: graphType === 'metric',
    showHistogram: true,
    showGraphInfoPlate: true,
    showTooltip: true,
    showQueryResult: true,
  });

  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const bottomBarClasses = useBottomBarStyles();
  const commonContainerRef = useRef<HTMLDivElement | null>(null);
  const canvasContainerRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasCtx = useRef<CanvasRenderingContext2D | null>(null);

  const coloringModeCommunitiesRef = useRef<[CommunityData | null, CommunityData | null]>(coloringModeCommunities);
  coloringModeCommunitiesRef.current = coloringModeCommunities;

  //communities selected in layer panel
  const currentLayerSelectedCommunities = useMemo(() => {
    return (currentLayer?.communities ?? []).filter(
      community => selectedLayerCommunityIds.includes(community.id)
    );
  }, [currentLayer, selectedLayerCommunityIds]);
  const currentLayerSelectedCommunitiesRef = useRef<CommunityData[]>([]);
  currentLayerSelectedCommunitiesRef.current = (currentLayer?.communities ?? []).filter(
    community => selectedLayerCommunityIds.includes(community.id)
  );
  const renderingRef = useRef<number>(0);

  const renderingSettingsRef = useRef<{
    devicePixelRatio: number,
    canvasWidth: number,
    canvasHeight: number,
    queryResultDisplayMode: QueryResultDisplayMode,
    defaultNodeRadius: number,
    zoomBehavior?: d3.ZoomBehavior<HTMLCanvasElement, any>,
    transform: typeof d3.zoomIdentity,
    finalTransformK: number,
    hiddenNodesOpacity: number
  }>({
    devicePixelRatio: globalDevicePixelRatio,
    canvasWidth: 0,
    canvasHeight: 0,
    queryResultDisplayMode: QueryResultDisplayMode.ShowOnlyQueriedNodes,
    defaultNodeRadius: 0,
    transform: d3.zoomIdentity,
    finalTransformK: 1,
    hiddenNodesOpacity: 20
  });

  const [invalidateRenderingData, setInvalidateRenderingData] = useState(Symbol());
  const renderingDataRef = useRef<{
    graphType: 'clustered' | 'metric',
    renderQueryResult: boolean,
    nodes: {
      all: NodeToDraw[],
      selectedOrColored: NodeToDraw[],
      notSelectedOrColored: NodeToDraw[],
    },
    links: {
      all: LinkToDraw[],
      queried: LinkToDraw[],
      notQueried: LinkToDraw[]
    }
  }>({
    graphType: 'metric',
    renderQueryResult: false,
    nodes: {
      all: [],
      selectedOrColored: [],
      notSelectedOrColored: [],
    },
    links: {
      all: [],
      queried: [],
      notQueried: []
    }
  });
  renderingDataRef.current = useMemo(() => {
    let defaultNodeRadius = graphData[graphType]?.nodesDefault?.radius ?? 0;
    let paddingTop = defaultNodeRadius * 2;
    let paddingBottom = defaultNodeRadius * 2;

    if (graphType === 'clustered') {
      const nodes = graphData[graphType]?.nodes ?? [];
      let topNode: GraphNodeData | undefined;
      let bottomNode: GraphNodeData | undefined;
      nodes.forEach(node => {
        if (!topNode) {
          topNode = node;
        }
        if (!bottomNode) {
          bottomNode = node;
        }
        if (node.y < topNode.y) {
          topNode = node;
        }
        if (node.y > bottomNode.y) {
          bottomNode = node;
        }
      });
      if (topNode) {
        paddingTop = topNode.radius + 5;
      }
      if (bottomNode) {
        paddingBottom = bottomNode.radius + 5;
      }
    }

    renderingSettingsRef.current.defaultNodeRadius = defaultNodeRadius;

    let graphScaled;

    const { canvasHeight, queryResultDisplayMode } = renderingSettingsRef.current;
    const xScale = d3.scaleLinear().range([paddingTop, canvasHeight - paddingBottom]);
    const yScale = d3.scaleLinear().range([canvasHeight - paddingBottom, paddingTop]);

    const graphNodesAndLinksData = graphData[graphType];
    if (graphNodesAndLinksData !== null) {
      graphScaled = scaleAndTranslateNodesAndLinks(xScale, yScale, 0, 0, graphNodesAndLinksData, [], graphType);
    }

    if (graphScaled) {
      console.time('Recache rendering data')

      const selectedNodesSet = new Set<number>();
      const coloredNodesSet = new Set<number>();

      const dataIdToNodeIndexMap = new Map<string, number[]>();

      const allNodesToDraw: NodeToDraw[] = graphScaled.nodes.map((node, index) => {
        //mapping nodeId (dataId) to indexes
        node.dataId.forEach(dataId => {
          if (dataIdToNodeIndexMap.has(dataId)) {
            dataIdToNodeIndexMap.get(dataId)?.push(index);
          } else {
            dataIdToNodeIndexMap.set(dataId, [index]);
          }
        });

        return {
          ...node,
          x: node.x,
          y: node.y,
          color: currentLayer ? DEFAULT_NODE_COLOR : node.color, //will be updated later
          radius: node.radius,
          selected: false, //will be updated later
          queried: false //will be updated later
        };
      });

      if (currentLayer) {
        currentLayerSelectedCommunities.forEach(({ nodes, color }) => {
          nodes.forEach(nodeIndex => {
            selectedNodesSet.add(nodeIndex);
            allNodesToDraw[nodeIndex].selected = true;
          });
        });
        currentLayer.communities.filter(community => !community.removed).forEach(community => {
          const { nodes, color } = community;
          nodes.forEach(nodeIndex => {
            allNodesToDraw[nodeIndex].color = color;
          })
        });
      } else {
        if (coloringData) {
          const coloringNodes = graphType === 'metric' ? coloringData.metric : coloringData.clustered;
          allNodesToDraw.forEach((node, index) => {
            node.color = coloringNodes[index].color;
            node.tooltip = coloringNodes[index].tooltip;
          });
        }

        const [ coloringCommunity1, coloringCommunity2 ] = coloringModeCommunities;

        if (coloringCommunity1) {
          const { nodes, color } = coloringCommunity1;
          nodes.forEach(nodeIndex => {
            coloredNodesSet.add(nodeIndex);
            allNodesToDraw[nodeIndex].color = color;
          });
        }
        if (coloringCommunity2) {
          const { nodes, color } = coloringCommunity2;
          nodes.forEach(nodeIndex => {
            coloredNodesSet.add(nodeIndex);
            allNodesToDraw[nodeIndex].color = color;
          });
        }
      }

      const tmpQueriedNodesSet = new Set(sqlQueryResult.map(dataId => dataIdToNodeIndexMap.get(dataId)).flat());
      const queriedNodesSet = queryResultDisplayMode === QueryResultDisplayMode.ShowAllButQueriedNodes ? (
        new Set(allNodesToDraw.map((node, index) => index).filter(nodeIndex => {
          return !tmpQueriedNodesSet.has(nodeIndex)
        }))
      ) : tmpQueriedNodesSet;

      Array.from(queriedNodesSet).forEach(nodeIndex => {
        if (nodeIndex) {
          allNodesToDraw[nodeIndex].queried = true;
        }
      });

      //console.log('queriedNodesSet', Array.from(queriedNodesSet))

      const queriedLinks: LinkToDraw[] = [];
      const notQueriedLinks: LinkToDraw[] = [];

      graphScaled.links.forEach(link => {
        const { sourceIndex, targetIndex }: { sourceIndex: number, targetIndex: number} = link;
        if (queriedNodesSet.has(sourceIndex) && queriedNodesSet.has(targetIndex)) {
          queriedLinks.push(link);
        } else {
          notQueriedLinks.push(link);
        }
      });

      const selectedOrColoredNodes = [...Array.from(selectedNodesSet), ...Array.from(coloredNodesSet)].map(nodeIndex => allNodesToDraw[nodeIndex]);
      const notSelectedOrColoredNodes = allNodesToDraw.filter((node, index) => (!selectedNodesSet.has(index) && !coloredNodesSet.has(index)));

      console.timeEnd('Recache rendering data')
      return {
        graphType: graphType,
        renderQueryResult: state.showQueryResult && sqlQueryResult.length > 0,
        nodes: {
          all: allNodesToDraw,
          selectedOrColored: selectedOrColoredNodes,
          notSelectedOrColored: notSelectedOrColoredNodes,
        },
        links: {
          all: graphScaled.links,
          queried: queriedLinks,
          notQueried: notQueriedLinks
        }
      }
    }
    return {
      graphType: graphType,
      renderQueryResult: false,
      nodes: {
        all: [],
        selectedOrColored: [],
        notSelectedOrColored: [],
      },
      links: {
        all: [],
        queried: [],
        notQueried: []
      }
    }
  }, [
    invalidateRenderingData
  ]);

  useEffect(() => {
    const containerDivElement = commonContainerRef.current;
    let resizeObserver: ResizeObserver | null = null;
    let observerTimer = 0;
    if (containerDivElement) {
      resizeObserver = new ResizeObserver(() => {
        //delay canvas resize, so it won't be called many time during drawers transitions, for example
        clearTimeout(observerTimer);
        observerTimer = window.setTimeout(() => {
          Actions.flags.dispatch({
            forceCanvasResize: Symbol()
          });
        }, 100);
      });
      resizeObserver.observe(containerDivElement);
    }

    initValues();
    setInvalidateRenderingData(Symbol());

    return () => {
      clearTimeout(observerTimer);
      if (containerDivElement && resizeObserver) {
        resizeObserver.unobserve(containerDivElement);
      }
      cancelAnimationFrame(renderingRef.current);
    }
  }, []);

  useEffect(() => {
    setInvalidateRenderingData(Symbol());
  }, [
    graphData,
    coloringData,
    sqlQueryResult,
    graphType,
    coloringModeCommunities,
    currentLayerSelectedCommunities,
    layers,
    currentLayer,
    state.showQueryResult,
  ]);

  useEffect(() => {
    if (commonContainerRef.current && canvasRef.current) {
      getContainerSize();
      updateCanvasSize();

      setInvalidateRenderingData(Symbol());
    }
  }, [forceCanvasResize]);

  useEffect(() => {
    Actions.predictors.dispatch({ statisticTable: [] });
  }, [isDrawerRightVisible]);

  useEffect(() => {
    if (eyeDropToolActive) {
      stopSelectionMode();
    }
  }, [eyeDropToolActive]);

  useEffect(() => {
    if (selectionColor) {
      setEyeDropSelectionColor('');
      Actions.graph.dispatch({
        eyeDropToolActive: false
      });
    }
  }, [selectionColor]);

  const selectedChartBarColors = useMemo(() => {
    return [
      currentLayerSelectedCommunitiesRef.current.map(({ color }) => color)
    ];
  }, [currentLayer, selectedLayerCommunityIds]);

  function handleShowTooltipChange(status: boolean) {
    setState({...state, showTooltip: status});
  }

  function updateCommunitiesAfterLassoEnd(selectedNodes: {nodeIndex: number}[], shiftKey: boolean) {
    const selectedNodesIndexes = selectedNodes.map(({ nodeIndex }) => nodeIndex);

    if (selectedNodesIndexes.length > 0) {
      if (currentLayer) {
        currentLayer.communities.forEach(community => {
          if (community.color === selectionColor) {
            if (shiftKey) {
              const nodesSet = new Set(community.nodes);
              //check if all selected nodes already in community
              if (selectedNodesIndexes.every(nodeIndex => nodesSet.has(nodeIndex))) {
                selectedNodesIndexes.forEach(nodeIndex => nodesSet.delete(nodeIndex));
                community.nodes = Array.from(nodesSet);
              } else {
                community.nodes = Array.from(new Set([...community.nodes, ...selectedNodesIndexes]));
              }
            } else {
              community.nodes = selectedNodesIndexes;
            }
            community.hasChanges = true;
          } else {
            selectedNodesIndexes.forEach(nodeIndex => {
              const duplicateNodeIndex = community.nodes.indexOf(nodeIndex);
              if (duplicateNodeIndex !== -1) {
                community.nodes.splice(duplicateNodeIndex, 1);
                community.hasChanges = true;
              }
            });
          }
        });
        setInvalidateRenderingData(Symbol());
      } else {
        switch (selectionColor) {
          case LASSO_COLOR1: {
            let [oldCommunity1, community2] = coloringModeCommunities;
            let newCommunityNodes: number[] = [];
            const nodesSet = new Set(oldCommunity1 ? oldCommunity1.nodes : []);
            //check if all selected nodes already in community
            if (selectedNodesIndexes.every(nodeIndex => nodesSet.has(nodeIndex))) {
              selectedNodesIndexes.forEach(nodeIndex => nodesSet.delete(nodeIndex));
              newCommunityNodes = Array.from(nodesSet);
            } else if (shiftKey && oldCommunity1) {
              newCommunityNodes = Array.from(new Set([...oldCommunity1.nodes, ...selectedNodesIndexes]));
            } else {
              newCommunityNodes = selectedNodesIndexes;
            }
            const community1 = {
              color: LASSO_COLOR1,
              nodes: newCommunityNodes
            };
            if (community2 !== null) {
              community2.nodes = community2.nodes.filter((node:number) => !selectedNodesIndexes.includes(node));
              community2 = community2.nodes.length > 0 ? {
                id: Symbol(),
                color: LASSO_COLOR2,
                nodes: community2.nodes
              } : null;
            }
            Actions.graph.dispatch({
              coloringModeCommunities: [community1, community2]
            });
            break;
          }
          case LASSO_COLOR2: {
            let [community1, oldCommunity2] = coloringModeCommunities;
            let newCommunityNodes: number[] = [];
            const nodesSet = new Set(oldCommunity2 ? oldCommunity2.nodes : []);
            //check if all selected nodes already in community
            if (selectedNodesIndexes.every(nodeIndex => nodesSet.has(nodeIndex))) {
              selectedNodesIndexes.forEach(nodeIndex => nodesSet.delete(nodeIndex));
              newCommunityNodes = Array.from(nodesSet);
            } else if (shiftKey && oldCommunity2) {
              newCommunityNodes = Array.from(new Set([...oldCommunity2.nodes, ...selectedNodesIndexes]));
            } else {
              newCommunityNodes = selectedNodesIndexes;
            }
            const community2 = {
              color: LASSO_COLOR2,
              nodes: newCommunityNodes
            };
            if (community1 !== null) {
              community1.nodes = community1.nodes.filter((node:number) => !selectedNodesIndexes.includes(node));
              community1 = community1.nodes.length > 0 ? {
                id: Symbol(),
                color: LASSO_COLOR1,
                nodes: community1.nodes
              } : null;
            }
            Actions.graph.dispatch({
              coloringModeCommunities: [community1, community2]
            });
            break;
          }
        }
      }
    }
  }

  function getContainerSize() {
    const canvas = canvasRef.current;
    const { width, height } = commonContainerRef.current?.getBoundingClientRect() ?? {
      width: 0,
      height: 0
    };
    const { zoomBehavior } = renderingSettingsRef.current;
    if (canvas && zoomBehavior && width > 0 && height > 0) {
      //during d3 zoom init we might not have valid canvasWidth and canvasHeight
      //so we adjust coordinates here
      const translateX = Math.max(0, (width - height) / 2);
      const canvasD3 = d3.select(canvas);
      canvasD3.call(zoomBehavior.transform, d3.zoomIdentity.translate(translateX, 0));
      //reset zoombehavior to make translate happen only once
      //temporary solution
      renderingSettingsRef.current.zoomBehavior = undefined;
    }

    renderingSettingsRef.current = {
      ...renderingSettingsRef.current,
      canvasWidth: width,
      canvasHeight: height
    };
  }

  function getCanvasToWindow(canvas:HTMLCanvasElement, x:number, y:number) {
    const ctx = canvas.getContext("2d");
    if (ctx) {
      const transform = ctx.getTransform();

      if (transform.isIdentity) {
        return {
          x, y
        };
      } else {
        return {
          x: Math.round(x * transform.a + y * transform.c + transform.e),
          y: Math.round(x * transform.b + y * transform.d + transform.f)
        };
      }
    }
    return {
      x, y
    }
  }

  function getWindowToCanvas(canvas:HTMLCanvasElement, x:number, y:number) {
    const rect = canvas.getBoundingClientRect();
    const screenX = (x - rect.left) * (canvas.width / rect.width);
    const screenY = (y - rect.top) * (canvas.height / rect.height);
    const ctx = canvas.getContext("2d");
    if (ctx) {
      const transform = ctx.getTransform();

      if (transform.isIdentity) {
        return {
          x: screenX,
          y: screenY
        };
      } else {
        const invMat = transform.invertSelf();

        return {
          x: Math.round(screenX * invMat.a + screenY * invMat.c + invMat.e),
          y: Math.round(screenX * invMat.b + screenY * invMat.d + invMat.f)
        };
      }
    }
    return {
      x, y
    }
  }

  const initialNodeTooltipState:NodeTooltipState = {
    x: 0,
    y: 0,
    visible: false,
    nodeId: '',
    tooltipLines: []
  };
  const [nodeTooltipState, setNodeTooltipState] = useState(initialNodeTooltipState);

  function initValues() {
    const MIN_ZOOM = 0.6;
    const MAX_ZOOM = 12;
    const canvas = canvasRef.current;

    if (canvas) {
      const canvasD3 = d3.select(canvas);
      canvasCtx.current = canvas.getContext('2d');

      getContainerSize();
      updateCanvasSize();

      const zoom = d3
        .zoom<HTMLCanvasElement, any>()
        .scaleExtent([MIN_ZOOM, MAX_ZOOM])
        .on('start', (...args: any) => {
          setNodeTooltipState((currentState:NodeTooltipState) => {
            return {
              ...currentState,
              nodeId: '',
              visible: false
            };
          });
        })
        .on('zoom', () => {
          const event = (d3.event as D3ZoomEvent<HTMLCanvasElement, {}>);
          const transform = event.transform;
          renderingSettingsRef.current.transform = transform;
          renderingSettingsRef.current.finalTransformK = transform.k >= 1 ? transform.k : 1;
          scheduleCanvasRendering('zoomed');
        })
        .on('end', () => {
          scheduleCanvasRendering('zoomed');
        });

      canvasD3.call(zoom);
      renderingSettingsRef.current.zoomBehavior = zoom;
      Actions.graph.dispatch({ coloringModeCommunities: [null, null] });
    }
  }

  function handleEyeDropOverlayMouseMove(e:React.MouseEvent<HTMLDivElement>) {
    const { defaultNodeRadius } = renderingSettingsRef.current;
    const nodes = renderingDataRef.current.nodes.all;
    const canvas = canvasRef.current;
    //todo move repeating code to function
    if (canvas && eyeDropToolActive) {
      const position = {
        x: e.nativeEvent.offsetX,
        y: e.nativeEvent.offsetY
      };
      const targetNodes = nodes.map(node => ({...node}));
      if (currentLayer) {
        targetNodes.forEach(node => {
          node.color = DEFAULT_NODE_COLOR
        });
        currentLayer.communities.filter(community => !community.removed).forEach(community => {
          const { nodes, color } = community;
          nodes.forEach(nodeIndex => {
            targetNodes[nodeIndex].color = color;
          })
        });
      }

      const [node] = targetNodes.filter(node => {
        const nodePosition = getCanvasToWindow(canvas, node.x, node.y);
        const radius = node.radius || defaultNodeRadius;
        const a = (nodePosition.x - position.x);
        const b = (nodePosition.y - position.y);
        const distance = Math.sqrt(a * a + b * b);
        return distance <= radius;
      });
      if (node) {
        setEyeDropSelectionColor(node.color);
      } else {
        setEyeDropSelectionColor('');
      }
    }
  }

  function handleEyeDropOverlayMouseClick(e:React.MouseEvent<HTMLDivElement>) {
    if (eyeDropToolActive) {
      if (eyeDropSelectionColor) {
        const [targetCommunity] = (currentLayer?.communities ?? []).filter(community => community.color === eyeDropSelectionColor);
        if (targetCommunity) {
          if (selectedLayerCommunityIds.includes(targetCommunity.id)) {
            Actions.graph.dispatch({
              selectedLayerCommunityIds: selectedLayerCommunityIds.filter(id => (id !== targetCommunity.id))
            });
          } else {
            Actions.graph.dispatch({
              selectedLayerCommunityIds: [...selectedLayerCommunityIds, targetCommunity.id]
            });
          }
        }
      }
    }
  }

  function handleCanvasMouseMove(e:React.MouseEvent<HTMLCanvasElement>) {
    const nodes = renderingDataRef.current.nodes.all;
    const {
      defaultNodeRadius,
      hiddenNodesOpacity,
      queryResultDisplayMode
    } = renderingSettingsRef.current;
    const canvas = canvasRef.current;

    if (canvas) {
      const position = {
        x: e.nativeEvent.offsetX,
        y: e.nativeEvent.offsetY
      };
      const [node] = nodes.filter(node => {
        const nodePosition = getCanvasToWindow(canvas, node.x, node.y);
        const radius = node.radius || defaultNodeRadius;
        const a = (nodePosition.x - position.x);
        const b = (nodePosition.y - position.y);
        const distance = Math.sqrt(a * a + b * b);
        return distance <= radius;
      });
      const nodeId = node !== undefined ? String(node.data[0]) : '';

      setNodeTooltipState((currentState: NodeTooltipState) => {
        if (
          (!nodeId && currentState.visible) ||
          (
            state.showQueryResult &&
            queryResultDisplayMode !== QueryResultDisplayMode.HighlightQueriedNodes &&
            hiddenNodesOpacity === 0 &&
            (node && !renderingDataRef.current.nodes.all[node.nodeIndex].queried)
          )
        ) {
          return {
            ...currentState,
            nodeId: '',
            visible: false
          };
        }
        if (nodeId !== currentState.nodeId && nodeId !== '') {
          const x = node.x;
          const y = node.y;
          const accuratePosition = getCanvasToWindow(canvas, x, y);
          const tooltipLines = node.tooltip.map(([label, value]: [string, string]) => `${label}: ${value}`);

          //experimental formatting example
          /*const tooltipLines = node.tooltip.map(([label, value]: [string, string]) => {
            const valueFormatted = isNaN(Number(value)) ? value : new Intl.NumberFormat(undefined, {maximumSignificantDigits: 8}).format(Number(value));
            return `${label}: ${valueFormatted}`
          });*/

          return {
            ...currentState,
            x: accuratePosition.x,
            y: accuratePosition.y,
            visible: true,
            tooltipLines: tooltipLines,
            nodeId
          };
        }
        return currentState;
      });
    }
  }

  function handleCanvasMouseLeave() {
    setNodeTooltipState((currentState:NodeTooltipState) => {
      return {
        ...currentState,
        nodeId: '',
        visible: false
      };
    });
  }

  function updateCanvasSize() {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const renderingSettings = renderingSettingsRef.current;

      clearCanvas();
      canvas.style.width = renderingSettings.canvasWidth + 'px';
      canvas.style.height = renderingSettings.canvasHeight + 'px';
      scheduleCanvasRendering('updatecanvassize');
    }
  }

  function clearCanvas() {
    const ctx = canvasCtx.current;
    const canvas = canvasRef.current;

    if (ctx && canvas) {
      //since we have constant transform applied simple clear rect might not cover whole area
      const currentTransform = ctx.getTransform();
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      //canvas.width = canvas.width;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.setTransform(currentTransform);
    }
  }

  function drawNode(
    ctx: CanvasRenderingContext2D,
    node: {
      x: number,
      y: number,
      color: string,
      radius: number
    },
    isSelected: boolean,
    shape = 'circle',
    opacity = 1
  ) {
    const { finalTransformK, defaultNodeRadius, devicePixelRatio } = renderingSettingsRef.current;
    const finalTransformKAdjusted = finalTransformK * devicePixelRatio;

    const originalOpacity = ctx.globalAlpha;
    ctx.globalAlpha = opacity;

    let radius = Number(((node.radius || defaultNodeRadius) / finalTransformK).toFixed(2));

    ctx.fillStyle = node.color;

    switch (shape) {
      case 'square': {
        ctx.beginPath();
        ctx.rect(node.x - radius, node.y - radius, radius * 2, radius * 2);
        break;
      }
      case 'star': {
        drawStar(ctx, node.x, node.y, 9, radius * 0.8, radius * 1.5);
        break;
      }
      case 'circle':
      default: {
        ctx.beginPath();
        ctx.arc(node.x, node.y, radius, 0, Math.PI * 2);
        ctx.arc(node.x, node.y, radius, 0, Math.PI * 2);
      }
    }

    ctx.fill();

    if (isSelected) {
      ctx.strokeStyle = node.color;
      ctx.lineWidth = 10 / finalTransformKAdjusted;
      ctx.stroke();
      ctx.strokeStyle = '#fff';
      ctx.lineWidth = 4 / finalTransformKAdjusted;
      ctx.stroke();
      ctx.strokeStyle = '#999';
      ctx.lineWidth = 1 / finalTransformKAdjusted;
      return;
    }
    ctx.stroke();
    ctx.globalAlpha = originalOpacity;
  }

  function drawLinks(ctx: CanvasRenderingContext2D, links: LinkToDraw[], opacity = 1) {
    const { finalTransformK, devicePixelRatio } = renderingSettingsRef.current;
    const originalOpacity = ctx.globalAlpha;

    ctx.globalAlpha = opacity;
    ctx.strokeStyle = '#999';
    links.forEach(link => {
      ctx.beginPath();
      ctx.lineWidth = (link.lineWidth || 1) / (finalTransformK * devicePixelRatio);
      ctx.moveTo(link.source.x, link.source.y);
      ctx.lineTo(link.target.x, link.target.y);
      ctx.stroke();
    });
    ctx.globalAlpha = originalOpacity;
  }

  function batchDrawLinks(ctx: CanvasRenderingContext2D, links: LinkToDraw[], opacity = 1) {
    const { finalTransformK, devicePixelRatio } = renderingSettingsRef.current;
    //NOTICE: will work properly only when all links have the same lineWidth
    const originalOpacity = ctx.globalAlpha;
    ctx.globalAlpha = opacity;
    ctx.strokeStyle = '#999';
    ctx.beginPath();
    links.forEach(link => {
      ctx.lineWidth = (link.lineWidth || 1) / (finalTransformK * devicePixelRatio);
      ctx.moveTo(link.source.x, link.source.y);
      ctx.lineTo(link.target.x, link.target.y);
    });
    ctx.stroke();
    ctx.globalAlpha = originalOpacity;
  }

  function drawStar(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    spikes: number,
    radius: number,
    innerRadius: number,
  ) {
    let rotation = Math.PI / 2 * 3;
    const step = Math.PI / spikes;

    ctx.beginPath();
    ctx.moveTo(x, y - radius);

    for(let i = 0; i < spikes; i++) {
      const tmpX1 = x + Math.cos(rotation) * radius;
      const tmpY1 = y + Math.sin(rotation) * radius;
      ctx.lineTo(tmpX1, tmpY1);
      rotation += step;

      const tmpX2 = x + Math.cos(rotation) * innerRadius;
      const tmpY2 = y + Math.sin(rotation) * innerRadius;
      ctx.lineTo(tmpX2, tmpY2)
      rotation += step;
    }
    ctx.lineTo(x, y - radius);
    ctx.closePath();
    ctx.fill();
  }

  function draw(caller?:string) {
    const ctx = canvasCtx.current;
    const {
      graphType,
      nodes,
      links,
      renderQueryResult
     } = renderingDataRef.current;
    const {
      transform,
      finalTransformK,
      hiddenNodesOpacity,
      queryResultDisplayMode,
      devicePixelRatio
    } = renderingSettingsRef.current;

    if (ctx) {
      //console.time('draw')
      //caller parameter is for debugging purposes
      const hiddenNodeOpacity = hiddenNodesOpacity / 100;

      clearCanvas();

      ctx.setTransform(
        transform.k * devicePixelRatio,
        0,
        0,
        transform.k * devicePixelRatio,
        transform.x * devicePixelRatio,
        transform.y * devicePixelRatio,
      );

      const linksRenderingFunction = graphType === 'clustered' ? drawLinks : batchDrawLinks;
      if (renderQueryResult) {
        switch (queryResultDisplayMode) {
          case QueryResultDisplayMode.HighlightQueriedNodes: {
            linksRenderingFunction(ctx, links.all);
            break;
          }
          case QueryResultDisplayMode.ShowAllButQueriedNodes:
          case QueryResultDisplayMode.ShowOnlyQueriedNodes: {
            linksRenderingFunction(ctx, links.notQueried, hiddenNodeOpacity);
            linksRenderingFunction(ctx, links.queried);
            break;
          }
        }
      } else {
        linksRenderingFunction(ctx, links.all);
      }

      ctx.strokeStyle = '#999';
      ctx.lineWidth = 1 / (finalTransformK * devicePixelRatio);

      //draw selected nodes after regular nodes to avoid unpleasant overlapping
      nodes.notSelectedOrColored.concat(nodes.selectedOrColored).forEach((node: NodeToDraw, index: number) => {
        //todo: move to separate function to avoid repeating code
        const { selected, queried } = node;

        if (renderQueryResult) {
          switch (queryResultDisplayMode) {
            case QueryResultDisplayMode.HighlightQueriedNodes: {
              drawNode(ctx, node, selected, queried ? 'star' : 'circle');
              break;
            }
            case QueryResultDisplayMode.ShowAllButQueriedNodes:
            case QueryResultDisplayMode.ShowOnlyQueriedNodes: {
              if (queried) {
                drawNode(ctx, node, selected, 'circle');
              } else {
                drawNode(ctx, node, selected, 'circle', hiddenNodeOpacity);
              }
              break;
            }
          }
        } else {
          drawNode(ctx, node, selected, 'circle');
        }
      });
      //console.timeEnd('draw')
    }
  }

  function stopSelectionMode() {
    Actions.graph.dispatch({ selectionColor: '' });
  }

  const statisticTable: any = useSelector(
    (s: IAppStore) => s.predictors.statisticTable,
  );
  const predictorsLoading: any = useSelector(
    (s: IAppStore) => s.predictors.loading,
  );

  const predictorsOpen = (statisticTable.length > 0 || predictorsLoading);
  const [desiredPredictorsSize, setDesiredPredictorsSize] = useState(0.3);
  const [chartsPerRow, setChartsPerRow] = useState(2);

  function handlePredictorsCanvasSizeChange(size: [number, number], sizePx: [number, number]) {
    const [predictorsSize] = size;
    const [, canvasAndChartsSizePx] = sizePx;
    if (predictorsSize > 0) {
      setDesiredPredictorsSize(predictorsSize);
    }
    setChartsPerRow(Math.floor(canvasAndChartsSizePx / 320));
  }

  const [desiredChartsSize, setDesiredChartsSize] = useState(0.5);
  function handleCanvasChartsSizeChange(size: [number, number], sizePx: [number, number]) {
    const [, chartsSize] = size;
    if (chartsSize > 0) {
      setDesiredChartsSize(chartsSize);
    }
  }

  function handleChartBarClick(chartIndex: number, barIndex: number, color: string) {
    if (isDrawerRightVisible && currentLayer) {
      const currentLayerCommunities = currentLayer.communities;
      const [targetCommunity] = currentLayerCommunities.filter(communityData => communityData.color === color);

      if (targetCommunity) {
        if (selectedLayerCommunityIds.includes(targetCommunity.id)) {
          Actions.graph.dispatch({
            selectedLayerCommunityIds: selectedLayerCommunityIds.filter(id => (id !== targetCommunity.id))
          });
        } else {
          Actions.graph.dispatch({
            selectedLayerCommunityIds: [...selectedLayerCommunityIds, targetCommunity.id]
          });
        }
      }
    }
  }

  const animTimeRef = useRef(Date.now());
  function scheduleCanvasRendering(caller?: string) {
    cancelAnimationFrame(renderingRef.current);
    renderingRef.current = requestAnimationFrame(() => {
      const diff = Date.now() - animTimeRef.current;
      //console.log('diff', (diff / 1000).toFixed(2));
      draw(caller);
      animTimeRef.current = Date.now();
    });
  }

  useEffect(() => {
    scheduleCanvasRendering();
  });

  return (
    <>
      <ForceChartCanvasToolbar eyeDropToolSelectionColor={eyeDropSelectionColor} />
      <div style={{
        display: 'flex',
        height: '100%'
      }}>
        <ResizableSplitSectionParent
          desiredFirstSize={desiredPredictorsSize}
          onSizeChange={handlePredictorsCanvasSizeChange}
          direction="horizontal"
          first={
            predictorsOpen ? (
              <PredictorsTable/>
            ) : null
          }
          second={(
            <ResizableSplitSectionParent
              direction="vertical"
              desiredSecondSize={desiredChartsSize}
              onSizeChange={handleCanvasChartsSizeChange}
              first={
                <div
                  style={{
                    height: '100%',
                    overflow: 'hidden',
                    width: '100%',
                    flexGrow: predictorsOpen ? 0 : 1,
                    boxShadow: `inset 0 0 0 3px ${selectionColor || 'transparent'}`,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    ref={commonContainerRef}
                    style={{
                      height: 'calc(100% - 64px)',
                      overflow: 'hidden',
                      width: '100%',
                      boxShadow: `inset 0 0 0 3px ${selectionColor || 'transparent'}`,
                      flexGrow: 1,
                      position: 'relative'
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                      }}
                      onMouseMove={handleEyeDropOverlayMouseMove}
                      onClick={handleEyeDropOverlayMouseClick}
                      ref={canvasContainerRef}
                    >
                      {eyeDropToolActive && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: 'calc(50% - 100px)',
                            backgroundColor: eyeDropSelectionColor,
                            height: '21px',
                            zIndex: 10,
                          }}
                        >
                          <span style={{ color: '#fff', fontSize: '16px', padding: '5px' }}>
                            Community selection by node color
                          </span>
                        </div>
                      )}
                      {selectionColor && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: 'calc(50% - 100px)',
                            backgroundColor: selectionColor,
                            height: '21px',
                            zIndex: 10,
                          }}
                        >
                          <span style={{ fontSize: '16px', padding: '5px' }}>
                            Selection mode. Color: {selectionColor}
                          </span>
                        </div>
                      )}
                      {selectionColor && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            backgroundColor: selectionColor,
                            height: '31px',
                            zIndex: 10,
                          }}
                        >
                          <CloseIcon
                            style={{ color: '#fff', fontSize: '30px', cursor: 'pointer' }}
                            onClick={stopSelectionMode}
                          />
                        </div>
                      )}
                      <GraphInfoPlate
                        visible={state.showGraphInfoPlate}
                      />
                      <canvas
                        ref={canvasRef}
                        width={renderingSettingsRef.current.canvasWidth * renderingSettingsRef.current.devicePixelRatio}
                        height={renderingSettingsRef.current.canvasHeight * renderingSettingsRef.current.devicePixelRatio}
                        onMouseMove={handleCanvasMouseMove}
                        onMouseLeave={handleCanvasMouseLeave}
                      />
                      {selectionColor !== '' ? (
                        <LassoNodesSelector
                          width={renderingSettingsRef.current.canvasWidth}
                          height={renderingSettingsRef.current.canvasHeight}
                          selectionColor={selectionColor}
                          nodes={renderingDataRef.current.nodes.all}
                          transform={renderingSettingsRef.current.transform}
                          onSelectionEnd={(points, shiftKey) => {
                            updateCommunitiesAfterLassoEnd(points, shiftKey);
                          }}
                        />
                      ) : null}
                      {state.showTooltip && nodeTooltipState.visible ? (
                        <Tooltip
                          classes={tooltipClasses}
                          title={(
                            <>
                              {nodeTooltipState.tooltipLines.map((line) => (<div>{line}</div>))}
                            </>
                          )}
                          open={nodeTooltipState.visible}
                        >
                          <div style={{
                            position: 'absolute',
                            left: nodeTooltipState.x,
                            top: nodeTooltipState.y,
                            width: 0,
                            height: 0,
                            background: 'red',
                            borderRadius: '50%'
                          }}>
                          </div>
                        </Tooltip>
                      ) : (<></>)}
                      {state.showHistogram && chartData ? (
                        <div className={classes.chartWrapper}>
                          <Chart
                            chartData={chartData}
                            viewBoxWidth={380}
                            viewBoxHeight={150}
                            showTitle
                            showDescription
                            onBarClick={handleChartBarClick}
                            additionalBarStyle={{
                              stroke: themeMode === 'dark' ? '#fff' : '#999',
                              'stroke-width': 0.3
                            }}
                            selectedBarColors={selectedChartBarColors}
                            selectedBarStyle={{
                              stroke: themeMode === 'dark' ? '#fff' : '#000',
                              'stroke-width': 2,
                              'stroke-dasharray': '3,3'
                            }}
                          />
                        </div>
                      ) : (<></>)}
                    </div>
                  </div>
                  <div>
                    <Toolbar className={bottomBarClasses.bottomBar}>
                      <CanvasUISettingsSection
                        showGraphInfoPlate={state.showGraphInfoPlate}
                        showHistogram={state.showHistogram}
                        showTooltip={state.showTooltip}
                        showQueryResult={state.showQueryResult}
                        queryResultDisplayMode={renderingSettingsRef.current.queryResultDisplayMode}
                        onShowGraphDetailsChange={(status) => setState({...state, showGraphInfoPlate: status})}
                        onShowHistogramChange={(status) => setState({...state, showHistogram: status})}
                        onShowTooltipChange={handleShowTooltipChange}
                        onShowQueryResultChange={(status) => {
                          setState({...state, showQueryResult: status})
                        }}
                        onQueryResultDisplayModeChange={({ displayMode, opacity }) => {
                          if (renderingSettingsRef.current.queryResultDisplayMode !== displayMode) {
                            renderingSettingsRef.current.queryResultDisplayMode = displayMode;
                            setInvalidateRenderingData(Symbol());
                          }
                          if (renderingSettingsRef.current.hiddenNodesOpacity !== opacity) {
                            renderingSettingsRef.current.hiddenNodesOpacity = opacity;
                            scheduleCanvasRendering();
                          }
                        }}
                      />
                    </Toolbar>
                  </div>
                </div>
              }
              second={
                predictorsOpen ? (
                  <ResizableSplitSectionChild key={'bottom'}>
                    <PredictorsCharts itemsPerRow={chartsPerRow} />
                  </ResizableSplitSectionChild>
                ) : null
              }
            />
          )}
        />
        <LayersDrawer />
      </div>
    </>
  );
};

export default ForceChartCanvas;
