import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import some from 'lodash/some';
import Table from '@material-ui/core/Table';
import { withStyles } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';

import './styles/index.css';
import TableHeadAdjusted from './TableHeadAdjusted';
import TableToolbarAdjusted from './TableToolbarAdjusted';

const styles = {
  root: {
    width: '100%',
  },
  tableWrapper: {
    overflow: 'auto',
  },
};

class TableAdjusted extends React.Component {
  state = {
    selected: [],
    order: 'desc',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // clean selected array if nextProps.items doesn't contain selected items
    const selected = prevState.selected.filter(item =>
      some(nextProps.items, item),
    );
    return { ...prevState, selected };
  }

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.props.items });
      return;
    }
    this.setState({ selected: [] });
  };

  onColumnHeaderClick = (event, orderBy) => {
    this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc' }, () =>
      this.props.onColumnHeaderClick(this.state.order, orderBy),
    );
  };

  // TODO attention to logic
  onRowClick = item => {
    if (this.props.showControlColumn) {
      let selected = cloneDeep(this.state.selected);

      if (this.props.showRadio) {
        // remove from selected if exist there
        const removed = remove(selected, selectedI => isEqual(selectedI, item));
        if (!isEmpty(removed)) {
          // push item to selected arr only if it is not there
          selected = [];
        } else {
          selected = [item];
        }
      } else {
        // remove from selected if exist there
        const removed = remove(selected, selectedI => isEqual(selectedI, item));
        if (isEmpty(removed)) {
          // push item to selected arr only if it is not there
          selected.push(item);
        }
      }

      this.setState({ selected });
    }
  };

  render() {
    const { LoadingComponent = null, classes } = this.props;

    return (
      <div style={{ width: '100%' }}>
        {(this.props.showAddButton ||
          this.props.showAddButton ||
          this.props.tableName) && (
          <TableToolbarAdjusted
            showAddButton={this.props.showAddButton}
            showDeleteButton={this.props.showDeleteButton}
            onDeleteClick={e =>
              this.props.onDeleteClick(this.state.selected, e)
            }
            tableName={this.props.tableName}
            numSelected={this.state.selected.length}
            onAddButtonClick={this.props.onAddButtonClick}
            addButtonItemName={this.props.addButtonItemName}
            customAddButtonComponent={this.props.customAddButtonComponent}
            customDeleteButtonComponent={this.props.customDeleteButtonComponent}
          />
        )}
        {this.props.loading && LoadingComponent ? (
          <LoadingComponent />
        ) : this.props.items.length ? (
          <div
            className={classes.tableWrapper}
            style={{ height: `calc(100vh - ${this.props.topMargin}px)` }}
          >
            <Table stickyHeader={true} aria-label="sticky table">
              <TableHeadAdjusted
                showControlColumn={this.props.showControlColumn}
                showRadio={this.props.showRadio}
                columnsConfig={this.props.columnsConfig}
                numSelected={this.state.selected.length}
                order={this.state.order}
                orderBy={this.props.orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onColumnHeaderClick={this.onColumnHeaderClick}
                rowCount={this.props.items.length}
                sortable={this.props.sortable}
              />
              <TableBody>
                {this.props.items.map((item, index) => {
                  const isSelected = some(this.state.selected, item);
                  return (
                    <TableRow
                      key={index}
                      hover
                      onClick={() => this.onRowClick(item)}
                      aria-checked={isSelected}
                      tabIndex={-1}
                      selected={isSelected}
                      //className={this.props.rowClassName}
                      className={this.props.rowClasses(item)}
                    >
                      {this.props.showControlColumn && (
                        <TableCell
                          {...this.props.cellProps(
                            this.props.showRadio
                              ? 'radio-control'
                              : 'checkbox-control',
                            item,
                          )}
                          // padding="none"
                        >
                          {this.props.showRadio ? (
                            <Radio color="primary" checked={isSelected} />
                          ) : (
                            <Checkbox color="primary" checked={isSelected} />
                          )}
                        </TableCell>
                      )}
                      {this.props.rowsConfig.map((cell = {}, i) => {
                        let cellVal = get(item, cell.prop, cell.default || '-');
                        if (cell.format && cellVal !== '-') {
                          cellVal = cell.format(cellVal, item);
                        }
                        return (
                          <TableCell
                            {...this.props.cellProps(cell, item, index)}
                            key={i}
                            // padding="none"
                            className={this.props.cellClasses(cell, item)}
                          >
                            <div>
                              {cellVal}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <TablePagination
              component="div"
              count={this.props.totalRowsQuantity}
              rowsPerPage={this.props.rowsPerPage}
              page={this.props.page}
              onChangePage={(e, nextPage) => {
                this.setState({ selected: [] });
                this.props.onChangePage(e, nextPage);
              }}
              onChangeRowsPerPage={this.props.onChangeRowsPerPage}
              rowsPerPageOptions={this.props.rowsPerPageOptions}
            />
          </div>
        ) : (
          <h2 className="no-items-found">
            {this.props.noItemsFoundPlaceholder}
          </h2>
        )}
      </div>
    );
  }
}

TableAdjusted.propTypes = {
  tableName: PropTypes.string,
  items: PropTypes.array,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  orderBy: PropTypes.string,
  columnsConfig: PropTypes.array,
  rowsConfig: PropTypes.array,
  onChangeRowsPerPage: PropTypes.func,
  onChangePage: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  totalRowsQuantity: PropTypes.number,
  loading: PropTypes.bool,
  LoadingComponent: PropTypes.any,
  onColumnHeaderClick: PropTypes.func,
  noItemsFoundPlaceholder: PropTypes.string,
  onAddButtonClick: PropTypes.func,
  addButtonItemName: PropTypes.string,
  cellProps: PropTypes.func,
  onDeleteClick: PropTypes.func,
  rowClasses: PropTypes.func,
  cellClasses: PropTypes.func,
  rowClassName: PropTypes.string,//deprecated
  // whether to show first checkbox/radio column or not
  showControlColumn: PropTypes.bool,
  // whether to show Radio as control column.
  // if not - then Checkbox is default.
  // NOTE if true - will disable selectAll button in table header
  showRadio: PropTypes.bool,
  showAddButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  customAddButtonComponent: PropTypes.any,
  customDeleteButtonComponent: PropTypes.any,
  topMargin: PropTypes.number,
  sortable: PropTypes.bool,
};

TableAdjusted.defaultProps = {
  topMargin: 234,
  onColumnHeaderClick: (e, prop) =>
    console.log('on row sort clicked, prop name: ', prop),
  showControlColumn: true,
  showRadio: false,
  items: [],
  cellProps: () => ({}),
  noItemsFoundPlaceholder: 'No items found',
  rowClasses: () => '',
  cellClasses: () => '',
  showAddButton: true,
  showDeleteButton: true,
  rowsPerPageOptions: [25, 50, 75, 100],
  rowsPerPage: 25,
  page: 0,
  order: 'desc',
  orderBy: 'name',
};

export default withStyles(styles)(TableAdjusted);
