// export const firebaseConfig = {
//   apiKey: 'AIzaSyAbbttrsGYQgffFPe3b2YosN_9jK5h-qM4',
//   authDomain: 'atlas-259513.firebaseapp.com',
//   databaseURL: 'https://atlas-259513.firebaseio.com',
//   projectId: 'atlas-259513',
//   storageBucket: 'atlas-259513.appspot.com',
//   messagingSenderId: '183182859746',
//   appId: '1:183182859746:web:0d986a598cb84ce72e6dbb',
// };

const firebaseConfigDev = {
  apiKey: 'AIzaSyAgcRperoma6mJqbHopNBNvCd5q5Ftc260',
  authDomain: 'atlastda.firebaseapp.com',
  databaseURL: 'https://atlastda.firebaseio.com',
  projectId: 'atlastda',
  storageBucket: 'atlastda.appspot.com',
  messagingSenderId: '788778815070',
  appId: '1:788778815070:web:521621e875cb374d94aecf',
  measurementId: 'G-MN2EPSLF21',
};

const firebaseConfigStage = {
  apiKey: 'AIzaSyA5QCm43uUt_79ax1AAAvTecK5O6IolVwU',
  authDomain: 'atlas-stage.firebaseapp.com',
  databaseURL: 'https://atlas-stage.firebaseio.com',
  projectId: 'atlas-stage',
  storageBucket: 'atlas-stage.appspot.com',
  messagingSenderId: '282817696823',
  appId: '1:282817696823:web:92601319dd6cb3b8cbdbb2',
};

export const firebaseConfig = () => {
  let firebaseConfigFinal;
  if (process.env.REACT_APP_MODE === 'stage') {
    firebaseConfigFinal = firebaseConfigStage;
  } else if (process.env.REACT_APP_MODE === 'prod') {
    firebaseConfigFinal = firebaseConfigStage;
  } else {
    firebaseConfigFinal = firebaseConfigDev;
  }
  return firebaseConfigFinal;
};
