import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: '100%',
  },
});

const FlyBox = ({
  children,
  margin,
  padding,
  minHeight,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  customStyles,
  noPaperPadding,
  classes,
  elevation,
}) => {
  let styles = Object.assign(
    {},
    paddingTop && { paddingTop: '15px' },
    paddingBottom && { paddingBottom: '15px' },
    paddingLeft && { paddingLeft: '15px' },
    paddingRight && { paddingRight: '15px' },
    padding && { padding: '15px' },
    marginTop && { marginTop: '15px' },
    marginBottom && { marginBottom: '15px' },
    marginLeft && { marginLeft: '15px' },
    marginRight && { marginRight: '15px' },
    margin && { margin: '15px' },
    minHeight && { minHeight: 'calc(100vh - 100px)' },
    customStyles && customStyles,
  );
  return (
    <div className="fly-box" style={styles}>
      <Paper
        className={noPaperPadding ? '' : classes.root}
        elevation={elevation}
      >
        {children}
      </Paper>
    </div>
  );
};

FlyBox.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any,
  padding: PropTypes.bool,
  margin: PropTypes.bool,
  minHeight: PropTypes.bool,
  paddingTop: PropTypes.bool,
  paddingBottom: PropTypes.bool,
  paddingLeft: PropTypes.bool,
  paddingRight: PropTypes.bool,
  marginTop: PropTypes.bool,
  marginBottom: PropTypes.bool,
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
  customStyles: PropTypes.object,
  noPaperPadding: PropTypes.bool,
  elevation: PropTypes.number,
};

FlyBox.defaultProps = {
  customStyles: null,
  noPaperPadding: false,
  elevation: 4,
};

export default withStyles(styles)(FlyBox);
