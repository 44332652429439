import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { createReducer } from 'utils';
import { IAppStore } from 'reducers';
import { reducers } from './reducersList';

const reducersObj = reducers.reduce(
  (
    reducersObj: any,
    { name, basicType, initialState = {}, handlers = {}, config }: any,
  ) => {
    const reducer = createReducer(initialState, basicType, handlers);
    if (config) {
      reducersObj[name] = persistReducer(config, reducer);
    } else {
      reducersObj[name] = reducer;
    }
    return reducersObj;
  },
  {},
);

const rootReducer = combineReducers(reducersObj);

const persistConfig = {
  key: 'root',
  whitelist: ['common', 'flags'],
  storage: storage,
  stateReconciler: autoMergeLevel2,
};

const reduxDevToolComposer = (window as any)
  .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

export const configureStore = (
  middlewares = [],
  initialState = {},
): { store: IAppStore; persistor: Persistor } => {
  const pipeline = [applyMiddleware(...middlewares)];
  const composeFinal = reduxDevToolComposer
    ? reduxDevToolComposer({})
    : compose;
  const finalCreateStore = composeFinal(...pipeline)(createStore);
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = finalCreateStore(persistedReducer, initialState);
  let persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};
