import React, { Component } from 'react';

export default class DraggableLabel extends Component {
  render() {
    return (
      <div
        className="draggable"
        style={{
          height: '10px',
          cursor: 'all-scroll',
          position: 'relative',
        }}
      >
        <span
          style={{
            fontSize: '35px',
            lineHeight: '0px',
            position: 'absolute',
            top: '-15px',
            left: 'calc(50% - 13px)',
            color: this.props.themeMode === 'light' ? '#2e2e2e' : '#fff',
          }}
        >
          ...
        </span>
      </div>
    );
  }
}
