import React, { useState, useRef } from 'react';

import { useParams } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DescriptionIcon from '@material-ui/icons/Description';

import { Input, TextWrapper, AutocompleteMulti } from 'components';
import { FileApi, PortfolioApi } from 'api';

import './ModalAddFile.css';

const ModalAddFile = ({ showModal, handleClose, state, setState }: any) => {
  const { portfolioId, studyId }: any = useParams();
  const defaultFileState = {
    isSelected: false,
    files: [],
    file: null,
    predictors: [],
  };

  const [newFile, setNewFileRaw] = useState({
    title: '',
    additionalInfo: '',
  });

  function setNewFile(newSate: any) {
    return setNewFileRaw(s => ({ ...s, ...newSate }));
  }

  const [fileState, setFileState]: any = useState({ ...defaultFileState });
  const [file2State, setFile2State]: any = useState({ ...defaultFileState });
  const [fileId, setFileId]: any = useState(null);
  const [loadingCreateFile, setLoadingCreateFile] = useState(false);

  function onFileInputChange(e: any) {
    setFileState((s: any) => ({
      ...s,
      isSelected: !!e.target.value,
      file: e.target.files[0],
    }));

    var reader = new FileReader();
    // Read file into memory as UTF-8
    reader.readAsText(e.target.files[0]);
    // Handle errors load
    reader.onload = loadHandler;
    reader.onerror = errorHandler;
  }

  function loadHandler(event: any) {
    var csv = event.target.result;
    processData(csv);
  }

  function processData(csv: any) {
    var allTextLines = csv.split(/\r\n|\n/);
    var lines = [];
    for (var i = 0; i < allTextLines.length; i++) {
      var data = allTextLines[i].split(';');
      var tarr = [];
      for (var j = 0; j < data.length; j++) {
        tarr.push(data[j]);
      }
      lines.push(tarr);
    }

    const predictors = lines[0][0].split(',');
    setFile2State((s: any) => ({ ...s, predictors }));
  }

  function errorHandler(evt: any) {
    if (evt.target.error.name == 'NotReadableError') {
      console.log('Cannot read file !');
    }
  }

  function onFile2InputChange(e: any) {
    setFile2State((s: any) => ({
      ...s,
      isSelected: !!e.target.value,
      file: e.target.files[0],
    }));
  }

  async function uploadFile(file: any) {
    const formData = new FormData();

    formData.append('portfolioId', portfolioId);
    formData.append('studyId', studyId);
    formData.append('file', file);

    const {
      data: { fileLink },
    } = await FileApi.uploadFile(formData);
    return { fileLink };
  }

  async function createFile() {
    let columnsInfoFileLink = null;
    setLoadingCreateFile(true);
    const { fileLink } = await uploadFile(fileState.file);

    if (file2State.file) {
      const { fileLink } = await uploadFile(file2State.file);
      columnsInfoFileLink = fileLink;
    }

    const data: any = {
      portfolioId,
      studyId,
      title: newFile.title,
      additionalInfo: newFile.additionalInfo,
      fileLink: fileLink,
    };

    if (columnsInfoFileLink) {
      data.metadata = { id: fileId, columnsInfoFileLink };
    }
    await FileApi.createFile(data);
    setLoadingCreateFile(false);
    onClose();
    PortfolioApi.getPortfoliosTree();
  }

  function onClose () {
    handleClose();
    setFileState({ ...defaultFileState });
    setFile2State({ ...defaultFileState });
    setNewFile({ title: '', additionalInfo: '' });
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={showModal}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Create File</DialogTitle>
        <br />
        <DialogContent style={{ width: '550px' }}>
          <div style={{ marginBottom: '50px' }}>
            <Input
              id="title"
              placeholder="New File"
              label="Title"
              that={{ setState: setNewFile, state: newFile }}
            />
            <Input
              id="additionalInfo"
              placeholder="This File contains..."
              label="Description"
              that={{ setState: setNewFile, state: newFile }}
            />
            {fileState.isSelected ? (
              <div className="upload-file-wrapper">
                <DescriptionIcon className="upload-file-placeholder" />
                <TextWrapper variant="subtitle1">
                  {fileState.file.name}
                </TextWrapper>
              </div>
            ) : (
              <Button
                className="upload-file-btn"
                variant="contained"
                component="label"
              >
                Pick File
                <input
                  onChange={onFileInputChange}
                  multiple={false}
                  accept=".csv"
                  type="file"
                  style={{ display: 'none' }}
                />
              </Button>
            )}
            {file2State.isSelected ? (
              <div className="upload-file-wrapper">
                <DescriptionIcon className="upload-file-placeholder" />
                <TextWrapper variant="subtitle1">
                  {file2State?.file.name ?? ''}
                </TextWrapper>
              </div>
            ) : (
              <Button
                className="upload-file-btn"
                variant="contained"
                component="label"
              >
                Add File with data description
                <input
                  onChange={onFile2InputChange}
                  multiple={false}
                  accept=".csv"
                  type="file"
                  style={{ display: 'none' }}
                />
              </Button>
            )}

            {!!file2State.predictors.length && !!file2State.file && (
              <AutocompleteMulti
                closeOnSelect={true}
                multi={false}
                items={file2State.predictors}
                onChange={(id: any) => setFileId(id)}
                label="File ID"
                placeholder="Pick file ID"
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 0 10px',
            }}
          >
            <Button
              variant="contained"
              style={{ marginRight: '25px' }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={
                loadingCreateFile ||
                !fileState.file ||
                (file2State.file && !fileId)
              }
              variant="contained"
              color="primary"
              style={{ minWidth: '119px' }}
              onClick={createFile}
            >
              {loadingCreateFile ? (
                <CircularProgress
                  color="secondary"
                  style={{ width: '20px', height: '20px' }}
                />
              ) : (
                'Create File'
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalAddFile;
