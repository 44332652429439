import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

class TableHeadAdjusted extends React.Component {
  render() {
    return (
      <TableHead>
        <TableRow>
          {this.props.showControlColumn && (
            <TableCell
            // padding="none"
            >
              {!this.props.showRadio ? (
                <Checkbox
                  color="primary"
                  indeterminate={
                    this.props.numSelected > 0 &&
                    this.props.numSelected < this.props.rowCount
                  }
                  checked={this.props.numSelected === this.props.rowCount}
                  onChange={this.props.onSelectAllClick}
                />
              ) : (
                <div style={{ width: '14px', height: '20px' }}></div>
              )}
            </TableCell>
          )}

          {this.props.columnsConfig.map((column, i) => {
            return (
              <TableCell
                key={i}
                numeric={column.numeric}
                // padding="none"
                sortDirection={
                  this.props.orderBy === column.id ? this.props.order : false
                }
              >
                {this.props.sortable ? (
                  <Tooltip
                    disableFocusListener={!column || !column.label}
                    disableHoverListener={!column || !column.label}
                    disableTouchListener={!column || !column.label}
                    title="Sort"
                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={this.props.orderBy === column.id}
                      direction={this.props.order}
                      onClick={e =>
                        column &&
                        column.label &&
                        this.props.onColumnHeaderClick(e, column)
                      }
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  column.label
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

TableHeadAdjusted.propTypes = {
  numSelected: PropTypes.number,
  onColumnHeaderClick: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  columnsConfig: PropTypes.array,
  showControlColumn: PropTypes.bool,
  showRadio: PropTypes.bool,
  sortable: PropTypes.bool,
};

export default TableHeadAdjusted;
