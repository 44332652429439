import React, { useState, useEffect } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import { TextWrapper } from 'components';
import { Table, Loading } from 'components';
import { FileApi, PortfolioApi } from 'api';
import { ModalAddDataset, ModalDelete } from '../index';

import './File.css';

const columnsConfig = [
  { id: 'name', label: 'Name' },
  { id: 'additionalInfo', label: 'Description' },
];

const rowsConfig = [
  { prop: 'name', format: val => (val ? val : '-') },
  { prop: 'additionalInfo', format: val => (val ? val : '-') },
];

const File = () => {
  const { portfolioId, studyId, fileId } = useParams();
  const allIds = { portfolioId, studyId, fileId };
  const location = useLocation();

  const [file, setFile] = useState({
    title: '',
    additionalInfo: '',
    created: '',
  });

  const [showDeleteFileModel, setShowDeleteFileModal] = useState(false);

  useEffect(() => {
    FileApi.getFileDetails(allIds).then(({ data }) => {
      setFile(data);
    });
  }, [location]);

  function handleDeleteFile() {
    return FileApi.deleteFile({ portfolioId, studyId, fileId }).then(() => {
      setShowDeleteFileModal(false);
      return PortfolioApi.getPortfoliosTree();
    });
  }

  const [showAddStudy, setShowAddStudy] = useState(false);

  const title = file.title;
  const additionalInfo = file.additionalInfo;
  const created = new Date(parseInt(file.created)).toLocaleString();

  return (
    <div>
      <div className="profile-heading-wrapper">
        <div className="profile-title-wrapper">
          <TextWrapper variant="h6">File: {title}</TextWrapper>
          <TextWrapper variant="subtitle2">Info: {additionalInfo}</TextWrapper>
          <TextWrapper variant="subtitle2">Created at: {created}</TextWrapper>
        </div>
      </div>
      <div className="File-controls-section">
        <Button onClick={() => setShowDeleteFileModal(true)} variant="outlined">
          Delete File
        </Button>
      </div>

      <Table
        showRadio
        topMargin={158}
        showDeleteButton={true}
        showControlColumn={true}
        noItemsFoundPlaceholder={''}
        loading={false}
        columnsConfig={columnsConfig}
        rowsConfig={rowsConfig}
        LoadingComponent={Loading}
        tableName=""
        items={[]}
        rowClassName="row"
        addButtonItemName="Add Dataset"
        // showAddButton={false}
        totalRowsQuantity={0}
        rowsPerPageOptions={[10]}
        onDeleteClick={user => {}}
        onChangePage={(e, nextPge) => {}}
        onColumnHeaderClick={(order, { id: orderBy }) => {}}
        onAddButtonClick={() => setShowAddStudy(true)}
      />
      <ModalAddDataset
        file={file}
        showModal={showAddStudy}
        handleClose={() => setShowAddStudy(false)}
      />
      <ModalDelete
        showModal={showDeleteFileModel}
        handleClose={() => setShowDeleteFileModal(false)}
        warningMessage="Are you sure you want to delete this file?"
        entityName="File"
        deleteEntity={handleDeleteFile}
      />
    </div>
  );
};

export default File;
