import { Actions } from '../../actions';
import { apiUrl } from '../../config';

export interface SQLQueryData {
  id: string;
  title: string;
  query: string;
  isPublic: boolean;
  isShared: boolean;
  additionalInfo: string;
  createdBy?: string;
  portfolioId: string;
  studyId: string;
  fileId: string;
}

export class SQLQueriesApi {
  static getFields = (
    datasetId: string,
    modelId: string,
    { portfolioId, studyId, fileId }:SQLQueryData
  ) => {
    return Actions.sqlQueries.POST({
      url: apiUrl.sqlQueries.getFields(),
      data: { datasetId, modelId, portfolioId, studyId, fileId },
      mapData: (response: any) => {
        return {};
      },
    });
  };

  static execute = (
    datasetId: string,
    modelId: string,
    { portfolioId, studyId, fileId, query }:SQLQueryData
  ) => {
    return Actions.sqlQueries.POST({
      url: apiUrl.sqlQueries.execute(),
      data: { datasetId, modelId, portfolioId, studyId, fileId, query },
      mapData: (response: any) => {
        return {};
      },
    });
  };

  static list = (
    datasetId: string, modelId: string,
    { portfolioId, studyId, fileId }:SQLQueryData,
    pagination: {page: number, perPage: number} | undefined = undefined
  ) => {
    return Actions.sqlQueries.POST({
      url: apiUrl.sqlQueries.list(),
      data: { datasetId, modelId, portfolioId, studyId, fileId, pagination },
      mapData: (response: any) => {
        return {};
      },
    });
  };

  static add = (
    datasetId: string,
    modelId: string,
    { portfolioId, studyId, fileId, query, title, additionalInfo }: SQLQueryData
  ) => {
    return Actions.sqlQueries.POST({
      url: apiUrl.sqlQueries.add(),
      data: { datasetId, modelId, portfolioId, studyId, fileId, query, title, additionalInfo },
      mapData: (response: any) => {
        return {};
      },
    });
  };

  static update = (
    datasetId: string,
    modelId: string,
    { portfolioId, studyId, fileId, id, query, title, additionalInfo }: SQLQueryData
  ) => {
    return Actions.sqlQueries.PATCH({
      url: apiUrl.sqlQueries.update(),
      data: { datasetId, modelId, portfolioId, studyId, fileId, query, title, additionalInfo, queryId: id },
      mapData: (response: any) => {
        return {};
      },
    });
  };

  static delete = (
    datasetId: string,
    modelId: string,
    { portfolioId, studyId, fileId, id }: SQLQueryData
  ) => {
    return Actions.sqlQueries.DELETE({
      url: apiUrl.sqlQueries.delete(),
      data: { datasetId, modelId, portfolioId, studyId, fileId, queryId: id, force: true },
      mapData: (response: any) => {
        return {};
      },
    });
  };

  static changePrivacy = (
    datasetId: string,
    modelId: string,
    { portfolioId, studyId, fileId, id, isPublic }: SQLQueryData
  ) => {
    return Actions.sqlQueries.PATCH({
      url: apiUrl.sqlQueries.update(),
      data: { datasetId, modelId, portfolioId, studyId, fileId, queryId: id, isPublic },
      mapData: (response: any) => {
        return {};
      },
    });
  };
}
