import axios from 'axios';
import get from 'lodash/get';
import filter from 'lodash/filter';

import { store } from '../../index';
import { Actions } from 'actions';
import { notify, flattenObject } from 'utils';
import { history, pageUrl } from 'config';
import { IParams, IXhrParams } from './types';

import { auth } from 'firebase/app';

const ERR_FALLBACK_MESSAGE = 'Something went wrong';

function getErrorMessage(err: any) {
  const message =
    filter(
      flattenObject(err),
      (v, k) => /.+msg/.test(k) || /.+message/.test(k),
    ).toString() || ERR_FALLBACK_MESSAGE;

  return message;
}

export class Api {
  static headers = () => {
    const token = get(store.getState(), 'auth.access_token');
    const uuid = get(store.getState(), 'auth.uuid');
    let headers: { [index: string]: any } = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    if (uuid) {
      headers['uuid'] = uuid;
    }
    return headers;
  };

  static get = ({ url, headers }: IParams) =>
    Api.xhr({ url, method: 'GET', headersCustom: headers });

  static put = ({ url, data, headers }: IParams) =>
    Api.xhr({ url, method: 'PUT', data, headersCustom: headers });

  static post = ({ url, data, headers }: IParams) =>
    Api.xhr({ url, method: 'POST', data, headersCustom: headers });

  static delete = ({ url, data, headers }: IParams) =>
    Api.xhr({ url, method: 'DELETE', headersCustom: headers, data });

  static patch = ({ url, data, headers }: IParams) =>
    Api.xhr({ url, method: 'PATCH', data, headersCustom: headers });

  static async xhr({ url, method, data, headersCustom = h => h }: IXhrParams) {
    const currentUser = auth().currentUser;
    if (currentUser) {
      //refresh token
      const token = await currentUser.getIdToken();
      Actions.auth.dispatch({ access_token: token });
    }

    return axios({
      method,
      url: encodeURI(url),
      data,
      headers: headersCustom(Api.headers()),
    })
      .then(response => {
        return response;
      })
      .catch(async err => {
        console.log(err?.response?.data ?? err);
        const message = getErrorMessage(err);
        const notifyErr = (err: any) => notify({ message });

        if (get(err, 'response.status') === 401) {
          Actions.auth.clear();
          history.push(pageUrl.login());
          // try {
          //   await getTokenByRefreshToken();
          //   // Api.xhr({ url, method, data, options, headersCustom });
          // } catch (err) {
          //   notifyErr(err);
          // }
        } else {
          notifyErr(err);
        }
        return Promise.reject(err);
      });
  }
}
