import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';

import { Input, TextWrapper } from 'components';
import { Actions } from 'actions';
import { apiUrl } from 'config';
import { notify, login, getToken } from 'utils';

import './Login.css';

const styles = theme => ({
  loginButton: {
    margin: '30px 0 8px',
    minHeight: '45px',
  },
});

const Login = props => {
  const [state, setStateRaw] = useState({
    username: '',
    password: '',
    isLogging: false,
  });

  function setState(newState) {
    return setStateRaw(oldState => ({ ...oldState, ...newState }));
  }

  async function handleLogin() {
    try {
      Actions.auth.clear();
      Actions.graph.clear();
      Actions.flags.clear();
      setState({ isLogging: true });

      const { user } = await login({
        email: state.username,
        password: state.password,
      });

      const token = await getToken();
      // console.log('token', token);
      // console.log('response', user ?? 'no user found');

      // let {
      //   data: { uuid },
      // } = await Actions.auth.GET({
      //   url: apiUrl.auth.getUUID(),
      //   headers: { Authorization: `${token}` },
      //   mapData: ({ data }) => data,
      // });

      setState({ isLogging: false });
      Actions.auth.dispatch({ access_token: token });
    } catch (err) {
      setState({ isLogging: false });
      console.log(err);
      notify({ title: 'Note!', message: err.message });
    }
  }

  const { classes } = props;
  return (
    <div className="login-container">
      <div className="login-controls-container">
        <TextWrapper className="margin-top" variant="h5" align="center">
          Atlas
        </TextWrapper>
        <Input
          id="username"
          placeholder="example@mail.com"
          label="Email"
          that={{ setState, state }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleLogin();
            }
          }}
        />
        <Input
          id="password"
          type="password"
          label="Password"
          that={{ setState, state }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleLogin();
            }
          }}
        />
        <Button
          size="large"
          className={classes.loginButton}
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleLogin}
          disabled={state.isLogging}
        >
          <span
            style={{
              display: 'inline-flex',
              minWidth: '50px',
              lineHeight: '30px',
              height: '30px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {state.isLogging ? (
              <CircularProgress
                color="secondary"
                style={{ width: '20px', height: '20px' }}
              />
            ) : (
              'login'
            )}
          </span>
        </Button>
        <dir
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
          }}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(Login);
