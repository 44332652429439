import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import { TextWrapper } from 'components';
import { Table, Loading } from 'components';
import { StudyApi, PortfolioApi } from 'api';
import { ModalAddFile, ModalDelete } from '../index';

import './Study.css';

const columnsConfig = [
  { id: 'name', label: 'Name' },
  { id: 'additionalInfo', label: 'Description' },
];

const rowsConfig = [
  { prop: 'name', format: val => (val ? val : '-') },
  { prop: 'additionalInfo', format: val => (val ? val : '-') },
];

const Study = ({ study: studyUpper }) => {
  const { portfolioId, studyId } = useParams();
  const [study, setStudy] = useState({
    title: '',
    additionalInfo: '',
    created: '',
  });
  useEffect(() => {
    if (!studyUpper?.id) {
      StudyApi.getStudyDetails({ portfolioId, studyId }).then(({ data }) => {
        setStudy(data);
      });
    }
  }, []);

  const [showAddFile, setShowAddFile] = useState(false);
  const [showDeleteStudy, setShowDeleteStudy] = useState(false);

  function handleDeleteStudy() {
    return StudyApi.deleteStudy({ portfolioId, studyId }).then(() => {
      setShowDeleteStudy(false);
      return PortfolioApi.getPortfoliosTree();
    });
  }

  const title = studyUpper?.title ?? study.title;
  const additionalInfo = studyUpper?.additionalInfo ?? study.additionalInfo;
  const created = new Date(
    parseInt(studyUpper?.created || study.created),
  ).toLocaleString();

  return (
    <div>
      <div className="profile-heading-wrapper">
        <div className="profile-title-wrapper">
          <TextWrapper variant="h6">Study: {title}</TextWrapper>
          <TextWrapper variant="subtitle2">Info: {additionalInfo}</TextWrapper>
          <TextWrapper variant="subtitle2">Created at: {created}</TextWrapper>
        </div>
      </div>
      <div className="Study-controls-section">
        {/* <Button className="Study-btn" variant="outlined">
          Add Study
        </Button> */}
        <Button onClick={() => setShowDeleteStudy(true)} variant="outlined">
          Delete Study
        </Button>
      </div>

      <Table
        showRadio
        topMargin={158}
        showDeleteButton={true}
        showControlColumn={true}
        noItemsFoundPlaceholder={''}
        loading={false}
        columnsConfig={columnsConfig}
        rowsConfig={rowsConfig}
        LoadingComponent={Loading}
        tableName=""
        items={[]}
        rowClassName="row"
        addButtonItemName="Add File"
        totalRowsQuantity={0}
        rowsPerPageOptions={[10]}
        onDeleteClick={user => {}}
        onChangePage={(e, nextPge) => {}}
        onColumnHeaderClick={(order, { id: orderBy }) => {}}
        onAddButtonClick={() => setShowAddFile(true)}
      />
      <ModalAddFile
        showModal={showAddFile}
        handleClose={() => setShowAddFile(false)}
      />
      <ModalDelete
        showModal={showDeleteStudy}
        handleClose={() => setShowDeleteStudy(false)}
        warningMessage="Are you sure you want to delete this study?"
        entityName="Study"
        deleteEntity={handleDeleteStudy}
      />
    </div>
  );
};

export default Study;
