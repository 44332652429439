import React, { useEffect, useMemo, useRef } from 'react';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import { d3 } from 'utils';
import { BarChartData, HistogramData } from 'reducers';
import useEffectDeepCompare from 'hooks/useEffectDeepCompare';

type LineSegment = {
  x1: number,
  x2: number,
  y1: number,
  y2: number
}

type D3_SVGGElement = d3.Selection<SVGGElement, unknown, null, undefined>;

type ChartRenderedParts = {
  //d3 dom svg groups
  $barsGroup: D3_SVGGElement,
  $tooltipsHelperGroup: D3_SVGGElement,
  $yAxisGroup: D3_SVGGElement,
  $kdeLineGroup?: D3_SVGGElement,
  $kdePointsGroup?: D3_SVGGElement,
  $rugsGroup?: D3_SVGGElement
}

type ChartRenderingOptions = {
  bars?: boolean,
  kde?: boolean,
  kdePoints?: boolean,
  rugs?: boolean
}

type BarSVGCSSStyle = {
  [key: string]: string | number
}

type BarClickCallback = (chartIndex: number, barIndex: number, color: string) => void;

type BarsInteractionOpacityLoose = {
  default?: {
    bars?: number,
    selectedBars?: number
  },
  hover?: {
    bars?: number,
    selectedBars?: number
  }
};

type BarsInteractionOpacityStrict = {
  default: {
    bars: number,
    selectedBars: number
  },
  hover: {
    bars: number,
    selectedBars: number
  }
};

type SelectedBarColors = string[][];

interface ChartProps {
  chartData: BarChartData | HistogramData;
  viewBoxWidth: number;
  viewBoxHeight: number;
  chartsVisibility?: {
    visible: boolean,
    rugs: boolean,
    kdePoints: boolean
  }[];
  renderingOptions?: ChartRenderingOptions;
  barsInteractionOpacity?: BarsInteractionOpacityLoose;
  showTitle?: boolean;
  showDescription?: boolean;

  //only applicable for basic charts
  //list of colors to apply selectedBarStyle
  selectedBarColors?: SelectedBarColors;

  additionalBarStyle?: BarSVGCSSStyle;
  selectedBarStyle?: BarSVGCSSStyle;
  onBarClick?: BarClickCallback;
}

const transitionDuration = 200;

/** d3 Si prefix with removal of insignificant trailing zeros */
const Y_AXIS_TICK_FORMAT = '~s';

function getSegmentIntersectionByX(
  { x1, y1, x2, y2 }: LineSegment, targetX: number
): [number, number] {
  if (x1 === x2) {
    return[targetX, y1];
  }
  //y = kx + c;
  const k = (y2 - y1) / (x2 - x1);
  const c = (y1 + y2 - k * (x1 + x2)) / 2;
  return [targetX, k * targetX + c];
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%'
  },
  svg: {
    width: '100%',
    display: 'block'
  },
  titleContainer: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  descriptionContainer: {
    paddingLeft: 40,
    paddingRight: 40,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    wordBreak: 'break-word'
  }
}));

const Chart = (props: ChartProps) => {
  const {
    chartData,
    viewBoxWidth,
    viewBoxHeight,
    chartsVisibility,
    renderingOptions,
    additionalBarStyle,
    selectedBarColors,
    selectedBarStyle,
    onBarClick = () => {},
    barsInteractionOpacity,
    showTitle = false,
    showDescription = false
  } = props;
  const finalBarsInteractionOpacity = {
    default: {
      bars: 0.3,
      selectedBars: 1,
      ...barsInteractionOpacity?.default
    },
    hover: {
      bars: 1,
      selectedBars: 1,
      ...barsInteractionOpacity?.hover
    }
  }

  const classes = useStyles();
  const svgContainer = useRef<HTMLDivElement>(null);
  const svgRoot = useRef<SVGSVGElement>(null);
  const svgChartsGroups = useRef<ChartRenderedParts[]>([]);
  const onBarClickRef = useRef<BarClickCallback>(() => {});
  const selectedBarColorsRef = useRef<SelectedBarColors>();
  onBarClickRef.current = onBarClick;
  selectedBarColorsRef.current = selectedBarColors;

  const renderingOptionsForDependency = useMemo(() => {
    const { bars, kde, kdePoints, rugs } = renderingOptions ?? {};
    return [bars, kde, kdePoints, rugs];
  }, [renderingOptions]);

  const { title: chartTitle, description: chartDescription } = chartData;

  useEffect(() => {
    if (svgRoot.current && svgContainer.current) {
      const chartType = chartData.type;

      const { $root, width, height } = prepareSVGRoot(
        svgContainer.current, svgRoot.current, viewBoxWidth, viewBoxHeight, chartData
      );
      if ($root) {
        switch (chartType) {
          case 'basic_bar_chart':
          case 'complex_bar_chart': {
            svgChartsGroups.current = renderBarChart(
              chartData as BarChartData, $root, width, height
            );
            break;
          }
          case 'basic_histogram':
          case 'complex_histogram': {
            svgChartsGroups.current = renderHistogram(
              chartData as HistogramData, $root, width, height, renderingOptions
            );
            break;
          }
        }
      }
    }
    adjustChartPartsVisibility();
    applyEventHandlers(finalBarsInteractionOpacity);
    applyAdditionalBarStyles(additionalBarStyle ?? {}, selectedBarStyle ?? {});
  }, [chartData, viewBoxWidth, viewBoxHeight, ...renderingOptionsForDependency]);

  useEffectDeepCompare(() => {
    adjustChartPartsVisibility();
  }, [chartsVisibility]);

  useEffectDeepCompare(() => {
    resetInteractionOpacity(finalBarsInteractionOpacity);
  }, [barsInteractionOpacity]);

  useEffectDeepCompare(() => {
    resetInteractionOpacity(finalBarsInteractionOpacity);
    applyAdditionalBarStyles(additionalBarStyle ?? {}, selectedBarStyle ?? {});
  }, [additionalBarStyle, selectedBarColors, selectedBarStyle]);

  function adjustChartPartsVisibility() {
    if (svgChartsGroups.current) {
      chartsVisibility?.forEach(({ visible, rugs, kdePoints }, index) => {
        const {
          $barsGroup,
          $tooltipsHelperGroup,
          $yAxisGroup,
          $kdeLineGroup,
          $kdePointsGroup,
          $rugsGroup
        } = svgChartsGroups.current[index];
        if ($barsGroup) {
          $barsGroup.attr('visibility', visible ? 'visible' : 'hidden');
        }
        if ($tooltipsHelperGroup) {
          $tooltipsHelperGroup.attr('visibility', visible ? 'visible' : 'hidden');
        }
        if ($barsGroup) {
          $barsGroup.attr('visibility', visible ? 'visible' : 'hidden');
        }
        if ($yAxisGroup) {
          $yAxisGroup.attr('visibility', visible ? 'visible' : 'hidden');
        }
        if ($kdeLineGroup) {
          $kdeLineGroup.attr('visibility', visible ? 'visible' : 'hidden');
        }
        if ($kdePointsGroup) {
          $kdePointsGroup.attr('visibility', (visible && kdePoints) ? 'visible' : 'hidden');
        }
        if ($rugsGroup) {
          $rugsGroup.attr('visibility', (visible && rugs) ? 'visible' : 'hidden');
        }
      });
    }
  }

  function applyAdditionalBarStyles(
    barStyles: BarSVGCSSStyle,
    selectedBarStyles: BarSVGCSSStyle
  ) {
    const combinedStyles = {
      ...barStyles,
      ...selectedBarStyles
    }
    const stylesStringified = Object.entries(barStyles).map(entry => entry.join(':')).join(';');
    const combinedStylesStringified = Object.entries(combinedStyles).map(entry => entry.join(':')).join(';');

    if (svgChartsGroups.current) {
      svgChartsGroups.current.forEach(svgChartGroup => {
        const { $barsGroup } = svgChartGroup;
        $barsGroup
          .selectAll('rect')
          .attr('style', stylesStringified);
        const [firstChartSelectedBarColors = []] = selectedBarColorsRef.current ?? [];
        firstChartSelectedBarColors.forEach(color => {
          $barsGroup
            .select(`rect[data-color='${color}']`)
            .attr('style', combinedStylesStringified);
        });
      });
    }
  }

  function resetInteractionOpacity(barsInteractionOpacity: BarsInteractionOpacityStrict) {
    if (svgChartsGroups.current) {
      const allBarGroups = svgChartsGroups.current.map(({ $barsGroup }) => $barsGroup);
      svgChartsGroups.current.forEach((svgChartGroup, chartIndex) => {
        allBarGroups.forEach(binGroup => {
          binGroup
            .selectAll('rect')
            .transition()
            .duration(transitionDuration)
            .attr('opacity', (data: unknown, index) => {
              const selectedBarColorsInChart = (selectedBarColorsRef.current ?? [])[chartIndex] ?? [];
              const { color } = data as {color: string};
              if (selectedBarColorsInChart.includes(color)) {
                return barsInteractionOpacity.default.selectedBars;
              }
              return barsInteractionOpacity.default.bars;
            })
        });
      });
    }
  }

  function applyEventHandlers(barsInteractionOpacity: BarsInteractionOpacityStrict) {
    if (svgChartsGroups.current) {
      const allBarGroups = svgChartsGroups.current.map(({ $barsGroup }) => $barsGroup);
      resetInteractionOpacity(barsInteractionOpacity);

      svgChartsGroups.current.forEach((svgChartGroup, chartIndex) => {
        const { $tooltipsHelperGroup } = svgChartGroup;

        $tooltipsHelperGroup
          .selectAll('rect')
          .on('mouseenter', null)
          .on('mouseenter', function(data, tooltipHelperIndex) {
            allBarGroups.forEach((binGroup, binGroupIndex) => {
              binGroup
                .selectAll('rect')
                .transition()
                .duration(transitionDuration)
                .attr('opacity', (data: unknown, barIndex) => {
                  const selectedBarColorsInChart = (selectedBarColorsRef.current ?? [])[chartIndex] ?? [];
                  const { color } = data as {color: string};
                  if (selectedBarColorsInChart.includes(color)) {
                    if (binGroupIndex === chartIndex && tooltipHelperIndex === barIndex) {
                      return barsInteractionOpacity.hover.selectedBars;
                    }
                    return barsInteractionOpacity.default.selectedBars;
                  }
                  if (binGroupIndex === chartIndex && tooltipHelperIndex === barIndex) {
                    return barsInteractionOpacity.hover.bars;
                  }
                  return barsInteractionOpacity.default.bars;
                })
            });
          })
          .on('mouseleave', null)
          .on('mouseleave', function(data, index) {
            allBarGroups.forEach(binGroup => {
                binGroup
                  .selectAll('rect')
                  .transition()
                  .duration(transitionDuration)
                  .attr('opacity', (data: unknown, index) => {
                    const selectedBarColorsInChart = (selectedBarColorsRef.current ?? [])[chartIndex] ?? [];
                    const { color } = data as {color: string};
                    if (selectedBarColorsInChart.includes(color)) {
                      return barsInteractionOpacity.default.selectedBars;
                    }
                    return barsInteractionOpacity.default.bars;
                  })
            });
          })
          .on('click', null)
          .on('click', (data: unknown, index) => {
            const { color } = data as {color: string};
            if (onBarClickRef.current) {
              onBarClickRef.current(chartIndex, index, color);
            }
        })
      });
    }
  }

  return (
    <div
      className={classes.root}
      ref={svgContainer}
    >
      {showTitle && chartTitle ? (
        <Tooltip title={chartTitle} placement="top">
          <Typography align="center" className={classes.titleContainer} noWrap>{chartTitle}</Typography>
        </Tooltip>
      ) : null}
      {showDescription && chartDescription ? (
        <Tooltip title={chartDescription} placement="top">
          <Typography align="center" variant="caption" className={classes.descriptionContainer}>{chartDescription}</Typography>
        </Tooltip>
      ) : null}
      <svg ref={svgRoot} className={classes.svg} />
    </div>
  );
};

function prepareSVGRoot(
  svgContainer:HTMLDivElement,
  svgRoot: SVGSVGElement,
  viewBoxWidth:number,
  viewBoxHeight:number,
  chartData: HistogramData | BarChartData,
): {
  $root: D3_SVGGElement | null, //d3 selected svg node
  width: number,
  height: number
} {
  const container = d3.select(svgContainer);
  const containerNode = container.node();

  if (containerNode) {
    const boundingClientRect = containerNode.getBoundingClientRect();
    const containerWidth = boundingClientRect.width;
    const containerHeight = boundingClientRect.height;
    const margin = {
      top: 10,
      right: 40,
      bottom: chartData.showXAxisTitles ? 30 : 10,
      left: 40
    };
    const svgWidth = (viewBoxWidth ?? containerWidth) - margin.left - margin.right;
    const svgHeight = (viewBoxHeight ?? containerHeight) - margin.top - margin.bottom;

    d3.select(svgRoot).selectAll('*').remove();
    const $svgRootGroup = d3.select(svgRoot)
      .attr('viewBox', '0 0 ' + (viewBoxWidth ?? containerWidth) + ' ' + (viewBoxHeight ?? containerHeight))
      .attr('preserveAspectRatio', 'xMinYMid')
      .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    return { $root: $svgRootGroup, width: svgWidth, height: svgHeight };
  }
  return {
    $root: null,
    width: 0,
    height: 0
  }
}

function renderHistogram(
  chartData: HistogramData,
  $root: D3_SVGGElement, //d3 dom element
  width: number,
  height: number,
  renderingOptions: ChartRenderingOptions = {}
): ChartRenderedParts[] {
  const {
    bars: renderBars = true,
    kde: renderKde = true,
    kdePoints: renderKdePoints = true,
    rugs: renderRugs = true
  } = renderingOptions;
  const BARS_SPACING = 1.5;
  const chartRenderedParts: ChartRenderedParts[] = [];
  const horizontalDomainRelativePadding = .05;

  const {
    heights: allHeights,
    showXAxisTitles,
    yAxisRangeEnds: allYAxisRangeEnds,
    xAxis: allXAxis,
    tooltips: allTooltips,
    colors: allColors,
    type: chartType,
    rugHeight = height * 0.04,
    kdePoints: allKdePoints,
    kde: allKde,
  } = chartData;

  //calculate domain for all bars
  const commonXAxis = allXAxis.flat().map((value: string | number) => Number(value));
  const domainXMin = d3.min(commonXAxis) || 0;
  const domainXMax = d3.max(commonXAxis) || 0;
  const domainPadding = (domainXMax - domainXMin) * horizontalDomainRelativePadding;
  const domainXLeftEdge = domainXMin - domainPadding;
  const domainXRightEdge = domainXMax + domainPadding;
  const domainX = [domainXLeftEdge, domainXRightEdge];
  const isComplexChart = chartType.startsWith('complex');

  const x = d3.scaleLinear()
    .domain(domainX)
    .range([0, width]);

  allHeights.forEach((heights, chartIndex) => {
    const chartColors = allColors[chartIndex];
    const tooltips = allTooltips[chartIndex].map((tooltipRows: string[]) => tooltipRows.join('\n'));
    const xAxis = allXAxis[chartIndex].map((value: string | number) => Number(value));
    const yAxisRangeEnds = allYAxisRangeEnds ? allYAxisRangeEnds[chartIndex] : (d3.max(allHeights.flat()) ?? 0);
    const kdePoints = allKdePoints ? allKdePoints[chartIndex] : [];
    const kdeCurveOriginalPoints = allKde ? allKde[chartIndex] : [];
    const chartCommonColor = isComplexChart ? chartColors[0] : '#000';
    const domainY = [0, yAxisRangeEnds];

    const kdeCurveSegments = kdeCurveOriginalPoints.reduce((
      segments: LineSegment[],
      [ x, y ]: [number, number]
    ) => {
      let lastSegment = [...segments].pop();

      if (lastSegment) {
        lastSegment.x2 = x;
        lastSegment.y2 = y;
      }

      if (segments.length < (kdeCurveOriginalPoints.length - 1)) {
        segments.push({
          x1: x,
          y1: y,
          x2: Infinity,
          y2: Infinity
        });
      }
      return segments;
    }, []).filter(({ x1, x2 }) => {
      return (
        (x1 <= domainXRightEdge && x1 >= domainXLeftEdge) ||
        (x2 <= domainXRightEdge && x2 >= domainXLeftEdge)
      );
    });

    const kdeCurvePoints: [number, number][] = kdeCurveSegments.map(({ x1, y1, x2, y2 }) => [x2, y2]);
    //remove last point, so it will not interfere with intersection calculation
    //it will be replaced with proper intersection point
    kdeCurvePoints.pop();

    if (kdeCurveSegments.length >= 2) {
      const [firstSegment] = kdeCurveSegments;
      const lastSegment = kdeCurveSegments.pop() ?? {x1: 0, y1: 0, x2: Infinity, y2: Infinity};

      const [xFirstSegment, yFirstSegment] = getSegmentIntersectionByX(firstSegment, domainXLeftEdge);
      const [xLastSegment, yLastSegment] = getSegmentIntersectionByX(lastSegment, domainXRightEdge);

      //kde points Y cannot be below zero
      kdeCurvePoints.unshift([xFirstSegment, Math.max(0, yFirstSegment)]);
      kdeCurvePoints.push([xLastSegment, Math.max(0, yLastSegment)]);
    }

    const bins = heights.map((value, index) => {
      return {
        height: Number(value),
        x0: xAxis[index],
        x1: xAxis[index + 1],
        color: isComplexChart ? chartCommonColor : chartColors[index],
        tooltip: tooltips[index]
      }
    });

    const y = d3.scaleLinear()
      .domain(domainY)
      .range([height, 0]);

    const $barsGroup = $root.append("g");
    if (renderBars) {
      $barsGroup
        .selectAll("rect")
        .data(bins)
        .enter()
        .append("rect")
          .attr("x", 1)
          .attr("transform", ({ x0, height }) => {
            return "translate(" + x(x0) + "," + y(height) + ")";
          })
          .attr("width", ({ x0, x1 }) => ((x(x1) ?? 0) - (x(x0) ?? 0) - BARS_SPACING))
          .attr("height", d => (height - (y(d.height) ?? 0)))
          .attr("fill", ({ color }) => color)
          .attr('data-color', ({ color }) => color)
    }

    //draw axis
    const $yAxisGroup = $root.append("g");
    const useSpecialFormattingForYAxis = yAxisRangeEnds >= 1000;
    $yAxisGroup
      .attr('class', 'axis-group')
      .attr('transform', (
        chartIndex === 0 ? '' : `translate(${width}, 0 )`
      ))
      .call(
        chartIndex === 0 ? (
          d3.axisLeft(y).tickFormat(useSpecialFormattingForYAxis ? d3.format(Y_AXIS_TICK_FORMAT) : y.tickFormat())
        ) : (
          d3.axisRight(y).tickFormat(useSpecialFormattingForYAxis ? d3.format(Y_AXIS_TICK_FORMAT) : y.tickFormat())
        )
      )
    if (isComplexChart) {
      $yAxisGroup.selectAll('.tick text').attr('fill', chartCommonColor);
      $yAxisGroup.selectAll('.domain').attr('stroke', chartCommonColor);
    }

    //draw kde
    let line1 = d3
      .line()
      .curve(d3.curveBasis)
      .x(d => (x(d[0]) ?? 0))
      .y(d => (y(d[1]) ?? 0));

    const $kdeLineGroup = $root.append("g");
    if (renderKde) {
      $kdeLineGroup
        .append('path')
        .attr('d', line1(kdeCurvePoints) ?? '')
        .attr('fill', 'none')
        .attr('stroke', chartCommonColor)
        .attr('stroke-width', 3);
    }

    const $kdePointsGroup = $root.append("g");
    if (renderKdePoints) {
      $kdePointsGroup
        .selectAll('circle')
        .data(kdePoints)
        .enter()
        .append('circle')
          .attr('cx', d => (x(d[0]) ?? 0))
          .attr('cy', d => (y(d[1]) ?? 0))
          .attr('r', 3)
          .attr('fill', chartCommonColor)
    }

    const $rugsGroup = $root.append("g");
    if (renderRugs) {
      $rugsGroup
        .selectAll('line')
        .data(kdePoints)
        .enter()
        .append('line')
          .attr('x1', d => (x(d[0]) ?? 0))
          .attr('y1', height - rugHeight)
          .attr('x2', d => (x(d[0]) ?? 0))
          .attr('y2', height)
          .attr('stroke', chartCommonColor)
          .attr('stroke-width', 3);
    }

    const $tooltipsHelperGroup = $root.append('g');
    if (renderBars) {
      $tooltipsHelperGroup.selectAll("rect")
        .data(bins)
        .enter()
        .append("rect")
          .attr("x", 0)
          .attr("transform", ({ x0 }) => {
            return "translate(" + x(x0) + ",0)";
          })
          .attr("width", ({ x0, x1 }) => ((x(x1) ?? 0) - (x(x0) ?? 0)))
          .attr("height", height)
          .attr("fill", "transparent")
          .append('title')
            .text(d => d.tooltip)
    }
    chartRenderedParts.push({
      $barsGroup,
      $tooltipsHelperGroup,
      $yAxisGroup,
      $kdeLineGroup,
      $kdePointsGroup,
      $rugsGroup
    });
  });

  const useSpecialFormattingForXAxis = Math.abs(domainXLeftEdge) >= 1000 || Math.abs(domainXRightEdge) >= 1000;
  $root.append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(showXAxisTitles ? (
      d3.axisBottom(x).tickFormat(useSpecialFormattingForXAxis ? d3.format(Y_AXIS_TICK_FORMAT) : x.tickFormat())
    ) : d3.axisBottom(x).tickValues([]));

  return chartRenderedParts;
}
function renderBarChart(
  chartData: BarChartData,
  $root: D3_SVGGElement, //d3 selected element
  width: number,
  height: number,
): ChartRenderedParts[] {
  const chartRenderedParts: ChartRenderedParts[] = [];
  const horizontalDomainRelativePadding = .2;
  const {
    heights: allHeights,
    showXAxisTitles,
    yAxisRangeEnds: allYAxisRangeEnds,
    xAxis: allXAxis,
    tooltips: allTooltips,
    colors: allColors,
    type: chartType,
    isYAxisLog10 = false
  } = chartData;
  const barSectionWidth = width / allHeights.length;

  allHeights.forEach((heights, chartIndex) => {
    const yAxisRangeEnds = allYAxisRangeEnds ? allYAxisRangeEnds[chartIndex] : (d3.max(heights) ?? 0);
    const chartColors = allColors[chartIndex];
    const xAxis = allXAxis[chartIndex];
    const tooltips = allTooltips[chartIndex].map(tooltipRows => tooltipRows.join('\n'));
    const domainX = xAxis.map(item => item.toString());
    const domainY = [isYAxisLog10 ? 1 : 0, yAxisRangeEnds];
    const chartOffsetX = barSectionWidth * chartIndex;
    const isComplexChart = chartType.startsWith('complex');
    const chartCommonColor = isComplexChart ? chartColors[0] : '#000';

    const x = d3.scaleBand()
      .domain(domainX)
      .range([0, barSectionWidth])
      .padding(horizontalDomainRelativePadding);

    const y = (isYAxisLog10 ? d3.scaleLog() : d3.scaleLinear())
      .domain(domainY)
      .range([height, 0]);

    const $yAxisGroup = $root.append("g");
    const useSpecialFormattingForYAxis = yAxisRangeEnds >= 1000;
    $yAxisGroup
      .attr("transform", (
        chartIndex === 0 ? '' : "translate( " + width + ", 0 )"
      ))
      .call(
        (chartIndex === 0 ? d3.axisLeft(y) : d3.axisRight(y))
        //when we have logarithmic scale axis/tick labels need special care
        .tickFormat(isYAxisLog10 ? (domainValue, index) => {
          return y.tickFormat(5, useSpecialFormattingForYAxis ? Y_AXIS_TICK_FORMAT : '')(domainValue);
        } : (useSpecialFormattingForYAxis ? d3.format(Y_AXIS_TICK_FORMAT) : y.tickFormat()))
      )
    if (isComplexChart) {
      $yAxisGroup.selectAll('.tick text').attr('fill', chartCommonColor)
      $yAxisGroup.selectAll('.domain').attr('stroke', chartCommonColor)
    }

    $root.append("g")
      .attr("transform", `translate(${chartOffsetX},${height})`)
      .call(showXAxisTitles ? d3.axisBottom(x) : d3.axisBottom(x).tickValues([]));

    const barData = heights.map((height, index) => {
      return {
        label: xAxis[index].toString(),
        height,
        color: isComplexChart ? chartCommonColor : chartColors[index],
        tooltip: tooltips[index]
      }
    });

    const $barsGroup = $root.append("g");
    $barsGroup
      .selectAll("rect")
      .data(barData)
      .enter()
      .append("rect")
        .attr("x", 0)
        .attr("transform", function({ label, height }) {
          const tx = chartOffsetX + (x(label) ?? 0);
          const ty = y(height);
          return `translate(${tx}, ${ty})`;
        })
        .attr("width", function() { return x.bandwidth() ; })
        .attr("height", function(d) {
          return height - (y(d.height) ?? 0);
        })
        .attr("fill", ({ color }) => color)
        .attr('data-color', ({ color }) => color)

    const $tooltipsHelperGroup = $root.append('g');
    $tooltipsHelperGroup.selectAll("rect")
      .data(barData)
      .enter()
      .append("rect")
        .attr("x", 0)
        .attr("transform", ({ label }) => {
          const tx = chartOffsetX + (x(label) ?? 0);
          return `translate(${tx}, 0)`;
        })
        .attr("width", () => x.bandwidth())
        .attr("height", height)
        .attr("fill", "transparent")
        .append('title')
          .text((d) => d.tooltip)

    chartRenderedParts.push({
      $barsGroup,
      $tooltipsHelperGroup,
      $yAxisGroup
    });
  });
  return chartRenderedParts;
}

export default Chart;