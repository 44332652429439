import { d3 } from 'utils';
import { Actions } from 'actions';

export function mousedown(svg, componentThis, upperThis) {
  let frameSelection = svg.select('rect.selection');

  if (
    frameSelection.empty() &&
    (componentThis.props.red || componentThis.props.blue)
  ) {
    let [mouseX, mouseY] = d3.mouse(upperThis);

    if (componentThis.props.red || componentThis.props.blue) {
      let transform = componentThis._transform;
      // let selection = [];
      d3.selectAll('circle').each(function(eachCircle) {
        let circleNode = d3.select(this);
        let r = parseInt(circleNode.attr('r'));

        let leftCircleEdge = (eachCircle.x - r) * transform.k;
        let rightCircleEdge = (eachCircle.x + r) * transform.k;
        let topCircleEdge = (eachCircle.y - r) * transform.k;
        let bottomCircleEdge = (eachCircle.y + r) * transform.k;

        let mouseXTransformed = mouseX - transform.x;
        let mouseYTransformed = mouseY - transform.y;

        // if (eachCircle.index == 0) {
        // console.log('==============================');
        // console.log('transform ', transform);
        // console.log(
        //   'mouseX ',
        //   mouseX,
        //   ' mouseXTransformed ',
        //   mouseXTransformed,
        // );
        // console.log(
        //   'mouseY ',
        //   mouseY,
        //   ' mouseYTransformed ',
        //   mouseYTransformed,
        // );
        // console.log(`left c ${leftCircleEdge} `);
        // console.log(`right c ${rightCircleEdge} `);
        // console.log(`top c ${topCircleEdge} `);
        // console.log(`bottom c ${bottomCircleEdge} `);
        //   console.log(typeof circleNode.attr('class'));
        // }

        if (
          leftCircleEdge <= mouseXTransformed &&
          rightCircleEdge >= mouseXTransformed &&
          topCircleEdge <= mouseYTransformed &&
          bottomCircleEdge >= mouseYTransformed
        ) {
          switch (true) {
            // circle already BLUE - make it red or clear
            case circleNode.attr('class') === 'selected-blue':
              if (componentThis.props.blue) {
                circleNode.attr('class', null);
              }
              if (componentThis.props.red) {
                circleNode.attr('class', 'selected-red');
              }
              break;
            // circle already RED - make it blue or clear
            case circleNode.attr('class') === 'selected-red':
              if (componentThis.props.blue) {
                circleNode.attr('class', 'selected-blue');
              }
              if (componentThis.props.red) {
                circleNode.attr('class', null);
                break;
              }
            // circle is EMPTY - make it red or blue
            case !circleNode.attr('class'):
              if (componentThis.props.blue) {
                circleNode.attr('class', 'selected-blue');
              }
              if (componentThis.props.red) {
                circleNode.attr('class', 'selected-red');
              }
          }
        }
      });
    }

    svg.append('rect').attrs({
      rx: 3,
      ry: 3,
      class: 'selection',
      fill: componentThis.props.red ? 'red' : 'blue',
      x: mouseX,
      y: mouseY,
      width: 0,
      height: 0,
    });
  }
}

export function mousemove(svg, componentThis, upperThis) {
  let frameSelection = svg.select('rect.selection');

  // console.log(s);
  if (
    !frameSelection.empty() &&
    (componentThis.props.red || componentThis.props.blue)
  ) {
    componentThis._shouldFade = false;
    let [mouseX, mouseY] = d3.mouse(upperThis);

    let updatedFrame = {
      x: parseInt(frameSelection.attr('x'), 10),
      y: parseInt(frameSelection.attr('y'), 10),
      width: parseInt(frameSelection.attr('width'), 10),
      height: parseInt(frameSelection.attr('height'), 10),
    };

    let move = {
      // difference between current mouse pos and
      diffX: mouseX - updatedFrame.x,
      diffY: mouseY - updatedFrame.y,
    };

    if (move.diffX < 1 || move.diffX * 2 < updatedFrame.width) {
      updatedFrame.x = mouseX;
      updatedFrame.width = updatedFrame.width - move.diffX;
    } else {
      updatedFrame.width = move.diffX;
    }

    if (move.diffY < 1 || move.diffY * 2 < updatedFrame.height) {
      updatedFrame.y = mouseY;
      updatedFrame.height = updatedFrame.height - move.diffY;
    } else {
      updatedFrame.height = move.diffY;
    }

    updatedFrame.width = Math.abs(updatedFrame.width);
    updatedFrame.height = Math.abs(updatedFrame.height);

    frameSelection.attrs(updatedFrame);
  }
}

export function mouseup(svg, componentThis, upperThis) {
  let frameSelection = svg.select('rect.selection');
  if (
    !frameSelection.empty() &&
    (componentThis.props.red || componentThis.props.blue)
  ) {
    let transform = componentThis._transform;
    let frame = frameSelection.node().getBBox();
    let selection = [];
    d3.selectAll('circle').each(function(eachCircle) {
      let circleNode = d3.select(this);
      // console.log(eachCircle.radius);
      let r = parseInt(circleNode.attr('r'));
      // let r = eachCircle.radius;

      let leftCircleEdge = (eachCircle.x - r) * transform.k;
      let rightCircleEdge = (eachCircle.x + r) * transform.k;
      let topCircleEdge = (eachCircle.y - r) * transform.k;
      let bottomCircleEdge = (eachCircle.y + r) * transform.k;

      let leftFrameEdge = frame.x - transform.x;
      let rightFrameEdge = frame.x - transform.x + frame.width;
      let topFrameEdge = frame.y - transform.y;
      let bottomFrameEdge = frame.y - transform.y + frame.height;

      // if (eachCircle.index == 0) {
      //   console.log('==============================');
      //   console.log('frame ', frame, ' transform ', transform);
      //   console.log(`left c ${leftCircleEdge} f ${leftFrameEdge}`);
      //   console.log(`right c ${rightCircleEdge} f ${rightFrameEdge}`);
      //   console.log(`top c ${topCircleEdge} f ${topFrameEdge}`);
      //   console.log(`bottom c ${bottomCircleEdge} f ${bottomFrameEdge}`);
      // }

      if (
        leftCircleEdge >= leftFrameEdge &&
        rightCircleEdge <= rightFrameEdge &&
        topCircleEdge >= topFrameEdge &&
        bottomCircleEdge <= bottomFrameEdge
      ) {
        if (componentThis.props.blue) {
          circleNode.attr('class', 'selected-blue');
        }
        if (componentThis.props.red) {
          circleNode.attr('class', 'selected-red');
        }
      }

      let classed = circleNode.attr('class');
      selection[eachCircle.index] =
        classed === 'selected-blue' ? 1 : classed === 'selected-red' ? 2 : 0;
    });
    Actions.graph.dispatch({ selection });
  }

  // svg.style('cursor', 'initial');
  componentThis._shouldFade = true;
  // remove selection frame
  svg.selectAll('rect.selection').remove();
  // remove temporary selection marker class
  d3.selectAll('rect.selection').remove();
}

// export function mouseout(d3, svg, componentThis) {
//   // if (d3.event.relatedTarget.tagName == 'HTML') {
//   // remove selection frame
//   svg.selectAll('.selection').remove();

//   // remove temporary selection marker class
//   d3.selectAll('.selection').classed('selection', false);
//   // }
// }
