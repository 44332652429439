import { Actions } from '../../actions';
import { apiUrl } from '../../config';

export class DatasetApi {
  static createDataset = data => {
    return Actions.datasets.POST({
      url: apiUrl.datasets.createDataset(),
      data,
      mapData: response => {
        // console.log(response);
        return {};
      },
    });
  };

  static listDatasets = (portfolioId, studyId, fileId) => {
    return Actions.datasets.POST({
      url: apiUrl.datasets.listDatasets(),
      data: {
        filtering: {
          isDeleted: false,
        },
        // "pagination": {
        //   "page": 0,
        //   "perPage": 0
        // },
        portfolioId,
        studyId,
        fileId,
        // "sorting": {
        //   "title": "string"
        // }
      },
      // mapData: response => {
      //   console.log(response);
      //   return {};
      // },
    });
  };

  static getDatasetDetails = data => {
    return Actions.datasets.POST({
      url: apiUrl.datasets.getDatasetDetails(),
      data,
    });
  };

  static deleteDataset = data => {
    return Actions.datasets.DELETE({
      url: apiUrl.datasets.deleteDataset(),
      data: { force: true, ...data },
    });
  };
}
