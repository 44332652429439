import React, { useState } from 'react';
import { PredictorsBarChart } from 'containers';
import { useShallowSelector } from 'hooks';
import { useSelector } from 'react-redux';
import { CircularProgress, makeStyles, Typography, Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import { Actions } from 'actions';
import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { fade, lighten } from '@material-ui/core/styles';
import { BarChartData, HistogramData } from 'reducers';

interface PredictorsChartsProps {
  itemsPerRow?: number
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: 15,
    flexWrap: 'wrap',
    overflow: 'auto',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  rootLoading: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerLabel: {
    marginRight: 'auto'
  },
  navButtonWrapper: {
    position: 'absolute',
    top: '50%',
    marginTop: -20,
  }
}));

const useNavButtonStyles = makeStyles(theme => ({
  root: {
    background: lighten(theme.palette.background.paper, 0.5),
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    "&$disabled": {
      backgroundColor: theme.palette.background.paper
    }
  },
  disabled: {} //this rule is required, otherwise background color override will not work
}));

const PredictorsCharts = (props: PredictorsChartsProps) => {
  const { itemsPerRow = 2 } = props;
  const statisticTable: any = useSelector(
    s => (s as any).predictors.statisticTable,
  );
  const loading: any = useShallowSelector(s => s.predictors.loading);

  const [chartDialogState, setChartDialogState] = useState<{
    predictorIndex: number,
    visible: boolean,
    chartData: any, //todo: proper typing
    predictorName: string
  }>({
    predictorIndex: -1,
    visible: false,
    chartData: null,
    predictorName: ''
  });

  const classes = useStyles();
  const navButtonClasses = useNavButtonStyles();
  const predictorChartsToShow = statisticTable.filter((row: any) => row.pinned);
  const itemWidth = (100 / itemsPerRow).toFixed(2);

  function handleUnpin(predictorName: string) {
    Actions.predictors.dispatch(({ statisticTable }) => {
      const newStatTable = [...statisticTable];
      newStatTable.forEach((row) => {
        if (row.predictor === predictorName) {
          row.pinned = false;
        }
      });
      return { statisticTable: newStatTable };
    });
  }

  function handleChartClick(predictorName: string) {
    const predictorIndex = predictorChartsToShow.findIndex((row: any) => row.predictor === predictorName);

    if (predictorIndex !== -1) {
      const predictorData = predictorChartsToShow[predictorIndex];

      setChartDialogState({
        ...chartDialogState,
        predictorIndex,
        predictorName,
        chartData: predictorData.chartData,
        visible: true
      });
    }
  }

  function handleNextPredictorClick() {
    const nextPredictorIndex = (chartDialogState.predictorIndex + 1) % predictorChartsToShow.length;
    const predictorData = predictorChartsToShow[nextPredictorIndex];

    setChartDialogState({
      ...chartDialogState,
      predictorIndex: nextPredictorIndex,
      predictorName: predictorData.predictor,
      chartData: predictorData.chartData,
    });
  }

  function handlePreviousPredictorClick() {
    let previousPredictorIndex = chartDialogState.predictorIndex - 1;
    if (previousPredictorIndex < 0) {
      previousPredictorIndex = predictorChartsToShow.length - 1;
    }
    const predictorData = predictorChartsToShow[previousPredictorIndex];

    setChartDialogState({
      ...chartDialogState,
      predictorIndex: previousPredictorIndex,
      predictorName: predictorData.predictor,
      chartData: predictorData.chartData,
    });
  }

  return (
    loading ? (
      <div className={[classes.root, classes.rootLoading].join(' ')}>
        <CircularProgress />
      </div>
    ) : (
      <>
        <div className={classes.root}>
          {predictorChartsToShow.length > 0 ? predictorChartsToShow.map((row: any) => {
            const predictorName = row.predictor;
            const chart = row.chartData;

            if (row.pinned) {
              return (
                <div style={{width: `${itemWidth}%`}} key={predictorName}>
                  <PredictorsBarChart
                    svgWidth={400}
                    svgHeight={200}
                    predictorName={predictorName}
                    predictor={chart}
                    onUnpin={handleUnpin}
                    onChartClick={handleChartClick}
                  />
                </div>
              );
            }
            return null;
          }) : (
            <Typography variant="h6" align="center" style={{margin: 'auto'}}>No predictors selected</Typography>
          )}
        </div>
        {chartDialogState.chartData ? (
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={chartDialogState.visible}
            onClose={() => {
              setChartDialogState({
                ...chartDialogState,
                visible: false
              });
            }}
            PaperProps={{
              style: {
                overflow: 'visible'
              }
            }}
          >
            <div className={classes.navButtonWrapper} style={{
              left: -20,
            }}>
              <IconButton classes={navButtonClasses} onClick={handlePreviousPredictorClick}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <div className={classes.navButtonWrapper} style={{
              right: -20,
            }}>
              <IconButton classes={navButtonClasses} onClick={handleNextPredictorClick}>
                <ChevronRightIcon />
              </IconButton>
            </div>
            <DialogTitle>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <div>
                  <b>{chartDialogState.predictorName}</b> - {chartDialogState?.chartData?.chart.title}
                </div>
                <div style={{marginLeft: 'auto'}}>
                  <IconButton onClick={() => {
                    setChartDialogState({
                      ...chartDialogState,
                      visible: false
                    });
                  }}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <PredictorsBarChart
                svgWidth={600}
                svgHeight={300}
                predictorName={chartDialogState.predictorName}
                predictor={chartDialogState.chartData}
                displayMode="dialog"
                key={chartDialogState.predictorName}
              />
            </DialogContent>
          </Dialog>
        ) : null}
      </>
    )
  );
};

export default PredictorsCharts;