/**
 *
 * @param {Number} y
 * @param {Number} duration
 * @param {Node} container
 */
export function scroll(
  y = document.getElementsByClassName('react-grid-layout')[0].scrollHeight,
  duration = 500,
  container = document.getElementsByClassName('content-wrapper')[0],
) {
  var initialY = (container && container.scrollTop) || document.body.scrollTop;
  var baseY = (initialY + y) * 0.5;
  var difference = initialY - baseY;
  var startTime = performance.now();

  function step() {
    var normalizedTime = (performance.now() - startTime) / duration;
    if (normalizedTime > 1) normalizedTime = 1;

    container.scrollTop =
      baseY + difference * Math.cos(normalizedTime * Math.PI);
    if (normalizedTime < 1) window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}
