import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { TextWrapper, Input } from 'components';

import './ModalEditUser.css';

const ModalEditUser = ({
  showModal,
  handleClose,
  handleEditUser,
  state,
  setState,
}) => {
  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={showModal}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Edit User</DialogTitle>
        <br />
        <DialogContent style={{ width: '550px' }}>
          <div style={{ marginBottom: '50px' }}>
            <Input
              id="email"
              placeholder="example@mail.com"
              label="Email"
              that={{ setState, state }}
            />
            <Input
              id="displayName"
              placeholder="Mrs. Merry Johnson"
              label="Name"
              that={{ setState, state }}
            />
            <Input
              id="password"
              placeholder="123456qwerty"
              label="Password"
              that={{ setState, state }}
            />
            <Input
              id="phoneNumber"
              placeholder="0123032412323"
              label="Phone number"
              that={{ setState, state }}
            />
            <div className={'checkbox-wrapper'}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.emailVerified}
                    onChange={e =>
                      setState({ emailVerified: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label="Email Verified"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.disabled}
                    onChange={e => setState({ disabled: e.target.checked })}
                    color="primary"
                  />
                }
                label="Disabled"
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 0 10px',
            }}
          >
            <Button
              variant="contained"
              style={{ marginRight: '25px' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditUser}
            >
              Edit User
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalEditUser;
