import React, { useState, FC, BaseSyntheticEvent } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { SketchPicker, ColorResult } from 'react-color';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';

import { ColorPickerProps } from './types';
import './ColorPicker.css';

const useStyles = makeStyles({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const DEFAULT_COLOR = '#cccccc';

const ColorPicker: FC<ColorPickerProps & {forbiddenColors?: string[]}> = ({
  handleColorChange,
  initialColor,
  forbiddenColors = []
}) => {
  const classes = useStyles();
  const [color, setColor] = useState<ColorResult['hex']>(
    initialColor || DEFAULT_COLOR,
  );
  const [anchorEl, setAnchorEl] = useState(null);

  function onColorChange(color: string) {
    if (!forbiddenColors.includes(color)) {
      setColor(color);
      handleColorChange(color);
    }
  }

  function handleColorPresenterClick(event: BaseSyntheticEvent) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <div className="color-picker-icon-wrapper">
        <IconButton onClick={handleColorPresenterClick}>
          <span
            className="color-picker-icon"
            aria-controls="simple-menu"
            aria-haspopup="true"
            style={{ backgroundColor: color }}
          />
        </IconButton>
      </div>
      <Menu
        classes={{ list: classes.list }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          <SketchPicker
            color={color}
            onChangeComplete={color => onColorChange(color.hex)}
          />
        </div>
      </Menu>
    </React.Fragment>
  );
};

export default ColorPicker;
