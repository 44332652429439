import React, { useState, FC } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Input } from 'components';

import { IModalAddLayer } from './types';

const ModalAddLayer: FC<IModalAddLayer> = ({
  showModal,
  handleClose,
  addLayer,
}) => {
  const [addingLoading, setAddingLoading] = useState(false);
  const defaultLayer = {
    title: '',
    additionalInfo: '',
  };
  const [newLayer, setNewLayerRaw] = useState({ ...defaultLayer });

  function setNewLayer(newSate: any) {
    return setNewLayerRaw(s => ({ ...s, ...newSate }));
  }

  async function handleAddLayer() {
    setAddingLoading(true);
    await addLayer(newLayer.title, newLayer.additionalInfo);
    setAddingLoading(false);
    handleClose();
  }
  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={showModal}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Create Layer</DialogTitle>
        <br />
        <DialogContent style={{ width: '550px' }}>
          <div style={{ marginBottom: '50px' }}>
            <Input
              id="title"
              placeholder="New Layer"
              label="Title"
              that={{ setState: setNewLayer, state: newLayer }}
            />
            <Input
              id="additionalInfo"
              placeholder="This layer contains..."
              label="Description"
              that={{ setState: setNewLayer, state: newLayer }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 0 10px',
            }}
          >
            <Button
              variant="contained"
              style={{ marginRight: '25px' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={addingLoading}
              variant="contained"
              style={{ minWidth: '168px' }}
              color="primary"
              onClick={handleAddLayer}
            >
              {addingLoading ? (
                <CircularProgress
                  color="secondary"
                  style={{ width: '20px', height: '20px' }}
                />
              ) : (
                'Create Layer'
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalAddLayer;
