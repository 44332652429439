import { auth } from 'firebase/app';

export const register = ({ email, password }) => {
  return auth()
    .createUserWithEmailAndPassword(email, password)
    .catch(function(error) {
      console.log(error);
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      return Promise.reject(error);
      // ...
    });
};

export const login = ({ email, password }) => {
  return auth()
    .signInWithEmailAndPassword(email, password)
    .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
      return Promise.reject(error);
    });
};

export const getToken = () => auth().currentUser.getIdToken();

export const handleAuthStateChange = fn => {
  auth().onAuthStateChanged(function(user) {
    if (user) {
      fn(user);
      // User is signed in.
      var displayName = user.displayName;
      var email = user.email;
      var emailVerified = user.emailVerified;
      var photoURL = user.photoURL;
      var isAnonymous = user.isAnonymous;
      var uid = user.uid;
      var providerData = user.providerData;
      // ...
    } else {
      // User is signed out.
      // ...
    }
  });
};
