import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingCircle = ({ marginTop = 148, wrapperStyles = {} }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: `calc( 100vh - ${marginTop})`,
        ...wrapperStyles,
      }}
    >
      <CircularProgress color="secondary" />
    </div>
  );
};

export default LoadingCircle;
