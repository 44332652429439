import React, { useState, useEffect, useRef } from 'react';

import { fade, makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CollectionsIcon from '@material-ui/icons/Collections';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import DescriptionIcon from '@material-ui/icons/Description';
import TableChartIcon from '@material-ui/icons/TableChart';
import GrainIcon from '@material-ui/icons/Grain';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { TextWrapper, LoadingCircle } from 'components';
import { pageUrl, history } from 'config';
// import { Actions } from 'actions';
import { useShallowSelector } from 'hooks';
import { PortfolioApi } from 'api';
import { Actions } from 'actions';
import {
  ModalAddPortfolio,
  CustomTreeItem,
  ProfileRoutes,
} from '../components';

import './Main.css';

const useStyles = makeStyles(theme => ({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: '100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    zIndex: 1,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    background: '#d3d3d357',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
}));

//portfolios -> studies -> files -> datasets -> models -> graphs

const Main = () => {
  let [defaultExpanded, setDefaultExpand] = useState<string[]>([]);
  let isTreeLoading: any = useShallowSelector(s => s.portfolios.loading);
  function goToMainPage() {
    history.push(pageUrl.main());
  }
  useEffect(() => {
    // goToMainPage();
    const asyncHelper = async () => {
      const { entitiesWithChildIds } = await PortfolioApi.getPortfoliosTree();

      setDefaultExpand(entitiesWithChildIds);
      setExpanded(entitiesWithChildIds);
    };

    asyncHelper();
  }, []);

  const trackArrowClick = useRef<boolean | null>(null);
  const classes = useStyles();

  const [expanded, setExpanded] = useState<string[]>(defaultExpanded);
  const [currentEntity, setCurrentEntity] = useState(null);
  const [showAddPortfolio, setShowAddPortfolio] = useState(false);
  const portfolios: any = useShallowSelector(s => s.portfolios.portfolios);
  const defaultPortfolio = {
    title: '',
    additionalInfo: '',
  };
  const [newPortfolio, setNewPortfolio] = useState({ ...defaultPortfolio });

  async function handleAddPortfolio() {
    await PortfolioApi.createPortfolio(
      newPortfolio.title,
      newPortfolio.additionalInfo,
    );
    setShowAddPortfolio(false);
    setNewPortfolio({ ...defaultPortfolio });
    return PortfolioApi.getPortfoliosTree();
  }

  function enableExpandHide() {
    trackArrowClick.current = true;
  }
  function disableExpandHide() {
    trackArrowClick.current = false;
  }

  function checkIfSelected(itsURL: string) {
    return window.location.pathname === itsURL;
  }

  return (
    <div className="main-page-wrapper">
      <div className="main-title-wrapper">
        <TextWrapper variant="h6">Portfolios</TextWrapper>
        {/* <div className={`${classes.search} search-expand-wrapper`}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div> */}
        <Button
          className="add-portfolio-btn"
          variant="outlined"
          onClick={() => {
            setShowAddPortfolio(true);
          }}
        >
          Add Portfolio
        </Button>
      </div>
      <div className="tree-profile-wrapper">
        {isTreeLoading ? (
          <LoadingCircle wrapperStyles={{ flex: 2 }} />
        ) : (
          <div className="tree-wrapper">
            <div className="expand-control-wrapper">
              {!expanded.length ? (
                <IconButton
                  onClick={() => {
                    setExpanded(defaultExpanded);
                  }}
                >
                  <ExpandMoreIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    setExpanded([]);
                  }}
                >
                  <ExpandLessIcon />
                </IconButton>
              )}
            </div>
            <TreeView
              className={classes.root}
              expanded={expanded}
              defaultCollapseIcon={
                <ArrowDropDownIcon onClick={enableExpandHide} />
              }
              defaultExpandIcon={<ArrowRightIcon onClick={enableExpandHide} />}
              defaultEndIcon={<div style={{ width: 24 }} />}
              onNodeToggle={(e, nodes) => {
                if (trackArrowClick.current) {
                  setExpanded(nodes);
                  disableExpandHide();
                }
              }}
            >
              {portfolios.map((portfolio: any) => {
                return (
                  <CustomTreeItem
                    isSelected={checkIfSelected(
                      pageUrl.portfolio(portfolio.id),
                    )}
                    key={portfolio.id}
                    nodeId={portfolio.id}
                    labelText={'Portfolio: ' + portfolio.title}
                    labelIcon={CollectionsIcon}
                    onClick={() => {
                      setCurrentEntity(portfolio);
                      history.push(pageUrl.portfolio(portfolio.id));
                    }}
                  >
                    {(portfolio?.studies ?? []).map((study: any) => {
                      return (
                        <CustomTreeItem
                          isSelected={checkIfSelected(
                            pageUrl.study(portfolio.id, study.id),
                          )}
                          key={study.id}
                          nodeId={study.id}
                          labelText={'Study: ' + study.title}
                          labelIcon={GroupWorkIcon}
                          onClick={() => {
                            setCurrentEntity(study);
                            history.push(pageUrl.study(portfolio.id, study.id));
                          }}
                        >
                          {(study?.files ?? []).map((file: any) => {
                            return (
                              <div
                                key={file.id}
                                style={{ paddingLeft: '16px' }}
                              >
                                <CustomTreeItem
                                  isSelected={checkIfSelected(
                                    pageUrl.file(
                                      portfolio.id,
                                      study.id,
                                      file.id,
                                    ),
                                  )}
                                  nodeId={file.id}
                                  labelText={'File: ' + file.title}
                                  labelIcon={DescriptionIcon}
                                  onClick={() => {
                                    setCurrentEntity(file);
                                    history.push(
                                      pageUrl.file(
                                        portfolio.id,
                                        study.id,
                                        file.id,
                                      ),
                                    );
                                  }}
                                >
                                  {(file?.datasets ?? []).map(
                                    (dataset: any) => {
                                      return (
                                        <div
                                          key={dataset.id}
                                          style={{ paddingLeft: '16px' }}
                                        >
                                          <CustomTreeItem
                                            isSelected={checkIfSelected(
                                              pageUrl.dataset(
                                                portfolio.id,
                                                study.id,
                                                file.id,
                                                dataset.id,
                                              ),
                                            )}
                                            nodeId={dataset.id}
                                            labelText={
                                              'Dataset: ' + dataset.title
                                            }
                                            labelIcon={TableChartIcon}
                                            onClick={() => {
                                              setCurrentEntity(dataset);
                                              history.push(
                                                pageUrl.dataset(
                                                  portfolio.id,
                                                  study.id,
                                                  file.id,
                                                  dataset.id,
                                                ),
                                              );
                                            }}
                                          >
                                            {(dataset?.models ?? []).map(
                                              (model: any) => {
                                                return (
                                                  <div
                                                    key={model.id}
                                                    style={{
                                                      paddingLeft: '16px',
                                                    }}
                                                  >
                                                    <CustomTreeItem
                                                      isSelected={checkIfSelected(
                                                        pageUrl.model(
                                                          portfolio.id,
                                                          study.id,
                                                          file.id,
                                                          dataset.id,
                                                          model.id,
                                                        ),
                                                      )}
                                                      nodeId={model.id}
                                                      labelText={
                                                        'Model: ' + model.title
                                                      }
                                                      labelIcon={GrainIcon}
                                                      onClick={() => {
                                                        setCurrentEntity(model);
                                                        history.push(
                                                          pageUrl.model(
                                                            portfolio.id,
                                                            study.id,
                                                            file.id,
                                                            dataset.id,
                                                            model.id,
                                                          ),
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                );
                                              },
                                            )}
                                          </CustomTreeItem>
                                        </div>
                                      );
                                    },
                                  )}
                                </CustomTreeItem>
                              </div>
                            );
                          })}
                        </CustomTreeItem>
                      );
                    })}
                  </CustomTreeItem>
                );
              })}
            </TreeView>
          </div>
        )}
        <ProfileRoutes currentEntity={currentEntity} />
      </div>
      <ModalAddPortfolio
        showModal={showAddPortfolio}
        handleClose={() => {
          setShowAddPortfolio(false);
          setNewPortfolio({ ...defaultPortfolio });
        }}
        handleAddPortfolio={handleAddPortfolio}
        state={newPortfolio}
        setState={setNewPortfolio}
      />
    </div>
  );
};

export default Main;
