import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router-dom';

import { Input } from 'components';
import { PortfolioApi, StudyApi } from 'api';

import './ModalAddStudy.css';

const ModalAddStudy = ({ showModal, handleClose }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { portfolioId } = useParams();

  async function handleAddProxy() {
    setDeleteLoading(true);
    await handleAddStudy();
    setDeleteLoading(false);
  }

  const [newStudy, setNewStudy] = useState({
    title: '',
    additionalInfo: '',
  });

  function onClose() {
    handleClose();
    setNewStudy({ title: '', additionalInfo: '' });
  }

  function handleAddStudy() {
    return StudyApi.createStudy(
      newStudy.title,
      newStudy.additionalInfo,
      portfolioId,
    ).then(() => {
      onClose();
      setNewStudy({ title: '', additionalInfo: '' });
      return PortfolioApi.getPortfoliosTree();
    });
  }
  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={showModal}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Create Study</DialogTitle>
        <br />
        <DialogContent style={{ width: '550px' }}>
          <div style={{ marginBottom: '50px' }}>
            <Input
              id="title"
              placeholder="New Study"
              label="Title"
              that={{ setState: setNewStudy, state: newStudy }}
            />
            <Input
              id="additionalInfo"
              placeholder="This Study contains..."
              label="Description"
              that={{ setState: setNewStudy, state: newStudy }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 0 10px',
            }}
          >
            <Button
              variant="contained"
              style={{ marginRight: '25px' }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              style={{ minWidth: '136px' }}
              disabled={deleteLoading}
              variant="contained"
              color="primary"
              onClick={handleAddProxy}
            >
              {deleteLoading ? (
                <CircularProgress
                  color="secondary"
                  style={{ width: '20px', height: '20px' }}
                />
              ) : (
                'Create Study'
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalAddStudy;
