import { reducers } from 'reducers';
import { Api } from 'utils';
import { requestFail, requestPending, requestSuccess } from '../index';
import { store } from '../../index';
import { IActions } from './types';

const mapDataDefault = (response: any) => ({ ...response.data });

const successHandler = ({ basicType, mapData }: any) => async (
  response: any,
) => {
  requestSuccess({
    type: basicType,
    payload: mapData ? await mapData(response) : {},
  });
  return response;
};

const errorHandler = ({ basicType }: any) => (err: any) => {
  requestFail({ type: basicType, err });
  return Promise.reject(err);
};

const callback = (actionsObj: any, { name, basicType }: any) => ({
  ...actionsObj,
  ...{
    [name]: {
      /**
       * Common action
       * @param payload
       */
      dispatch: (payload: any) => {
        let payloadFinal = payload;
        if (typeof payload === 'function') {
          payloadFinal = payload(store.getState()[name], store); // passing reducer to obtain needed props
        }
        return store.dispatch({
          type: basicType,
          payload: payloadFinal,
        });
      },

      /**
       * Clear action
       */
      clear: () =>
        store.dispatch({
          type: `${basicType}_CLEAR`,
        }),

      /**
       * GET request action
       * @param url
       * @param mapData
       * @returns {Promise.<T>}
       */
      GET: ({ url, mapData = mapDataDefault, headers }: any) => {
        requestPending({ type: basicType });
        return Api.get({ url, headers })
          .then(successHandler({ basicType, mapData }))
          .catch(errorHandler({ basicType }));
      },

      /**
       * POST request action. Requires mapData func to dispatch response.
       * @param url
       * @param data
       * @param mapData
       * @returns {Promise.<T>}
       */
      POST: ({ url, data, mapData, headers }: any) => {
        requestPending({ type: basicType });
        return Api.post({ url, data, headers })
          .then(successHandler({ basicType, mapData }))
          .catch(errorHandler({ basicType }));
      },

      /**
       * PUT request action. Requires mapData func to dispatch response.
       * @param url
       * @param data
       * @param mapData
       * @returns {Promise.<T>}
       */
      PUT: ({ url, data, mapData, headers }: any) => {
        requestPending({ type: basicType });
        return Api.put({ url, data, headers })
          .then(successHandler({ basicType, mapData }))
          .catch(errorHandler({ basicType }));
      },

      /**
       * PATCH request action. Requires mapData func to dispatch response.
       * @param url
       * @param data
       * @param mapData
       * @returns {Promise.<TResult>}
       */
      PATCH: ({ url, data, mapData, headers }: any) => {
        requestPending({ type: basicType });
        return Api.patch({ url, data, headers })
          .then(successHandler({ basicType, mapData }))
          .catch(errorHandler({ basicType }));
      },

      /**
       * DELETE request action. Requires mapData func to dispatch response.
       * @param url
       * @param data
       * @param mapData
       * @returns {Promise.<T>}
       */
      DELETE: ({ url, data, mapData, headers }: any) => {
        requestPending({ type: basicType });
        return Api.delete({ url, data, headers })
          .then(successHandler({ basicType, mapData }))
          .catch(errorHandler({ basicType }));
      },
    },
  },
});

export const Actions: IActions = reducers.reduce(callback, {});
