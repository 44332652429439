import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import omit from 'lodash/omit';
import set from 'lodash/set';
// import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

const styles = theme => ({
  formControl: {
    minWidth: '100%',
  },
  textField: {
    minWidth: '100%',
  },
});

class Input extends Component {
  _onChangeInner = (e, val = 'value', that) => {
    let path = e.target.id; // reducer's property
    let value = e.target[val]; // input value
    let state = { ...that.state };
    // allows nested path
    set(state, path, value);
    that.setState(state);
  };

  render() {
    const value = get(this.props, `that.state.${this.props.id}`, '');
    return (
      <FormControl
        className={this.props.classes.formControl}
        error={!!this.props.error}
      >
        <TextField
          error={!!this.props.error}
          id={this.props.id}
          value={value}
          onChange={
            this.props.onChange ||
            (e => this._onChangeInner(e, 'value', this.props.that))
          }
          label={this.props.label}
          placeholder={this.props.placeholder}
          className={this.props.classes.textField}
          style={this.props.style}
          margin="normal"
          helperText={this.props.error}
          {...omit(this.props, ['setState'])}
        />
      </FormControl>
    );
  }
}

Input.propTypes = {
  id: PropTypes.string,
  error: PropTypes.string,
  classes: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  that: PropTypes.any,
};

export default withStyles(styles)(Input);
