import React from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import './ThemeProvider.css';

const theme = theme =>
  createMuiTheme({
    palette: {
      type: theme,
    },
    props: {
      ListItemText: {
        // The properties to apply
        // disableRipple: true, // No more ripple, on the whole application 💣!
        fontSize: '14px',
      },
    },
    typography: {
      // In Japanese the characters are usually larger.
      fontSize: 13,
    },
  });

export const ThemeProvider = ({ children, themeMode }) => {
  return (
    <MuiThemeProvider theme={theme(themeMode)}>
      <CssBaseline />
      <div>{children}</div>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
