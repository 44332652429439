import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Fab from '@material-ui/core/Fab';
import DragIndicator from '@material-ui/icons/DragIndicator';
// import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';

// import { notify } from '../../utils';

const DRAWER_WIDTH = 400;

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    width: DRAWER_WIDTH,
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  dragger: {
    width: '5px',
    cursor: 'ew-resize',
    // padding: '4px 0 0',
    // borderTop: '1px solid #ddd',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: '100',
    backgroundColor: '#f4f7f9',
    display: 'flex',
    alignItems: 'center',
    borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
  },
  toolbar: theme.mixins.toolbar,
});

let isResizing = false;

function ClippedDrawer(props) {
  const {
    classes,
    children,
    resizable,
    onResizeCB,
    onResizeEndCB,
    onResizeStartCB,
  } = props;

  const [state, setStateRaw] = useState({
    mobileOpen: false,
    // isResizing: false,
    lastDownX: 0,
    newWidth: { width: props.drawerWidth || DRAWER_WIDTH },
  });

  useEffect(() => {
    setState({ newWidth: { width: props.drawerWidth || DRAWER_WIDTH } });
  }, [props.drawerWidth]);

  function setState(newState, callback) {
    setStateRaw(prevState => ({ ...prevState, ...newState }));
    if (typeof callback === 'function') {
      setTimeout(callback, 0);
    }
  }

  function handleMousedown(e) {
    // e.preventDefault();
    if (resizable) {
      onResizeStartCB();
      isResizing = true;
      setState({ lastDownX: e.clientX });
    }
  }

  function handleMousemove(e) {
    // we don't want to do anything if we aren't resizing.
    if (!isResizing) {
      return;
    }
    onResizeCB();
    let offsetRight =
      document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
    let minWidth = 250;
    let maxWidth = 700;
    if (offsetRight > minWidth && offsetRight < maxWidth) {
      setState({ newWidth: { width: offsetRight } });
    }
  }

  function handleTouchmove(e) {
    // we don't want to do anything if we aren't resizing.
    if (!isResizing) {
      return;
    }
    e.preventDefault();
    // event.touches[0].clientX;
    // event.touches[0].clientY;
    onResizeCB();
    let offsetRight =
      document.body.offsetWidth -
      (e.touches[0].clientX - document.body.offsetLeft);
    let minWidth = 50;
    let maxWidth = 600;
    if (offsetRight > minWidth && offsetRight < maxWidth) {
      setState({ newWidth: { width: offsetRight } });
    }
  }

  function handleMouseup(e) {
    // e.preventDefault();
    // e.stopPropagation();
    // if (isResizing) {
    onResizeEndCB();
    isResizing = false;
    // }
  }

  useEffect(() => {
    if (resizable) {
      document.addEventListener('mousemove', handleMousemove);
      document.addEventListener('mouseup', handleMouseup);
      document.addEventListener('touchmove', handleTouchmove);
      document.addEventListener('touchend', handleMouseup);
    }

    return () => {
      if (resizable) {
        document.removeEventListener('mousemove', handleMousemove);
        document.removeEventListener('mouseup', handleMouseup);
        document.removeEventListener('touchmove', handleTouchmove);
        document.removeEventListener('touchend', handleMouseup);
      }
    };
  }, []);

  return (
    <Drawer
      //className={classes.drawer}
      onClose={props.handleClose}
      anchor={props.side}
      variant={props.drawerVariant}
      open={props.isOpen}
      SlideProps={{
        unmountOnExit: true,
        onEnter: props.handleEnter,
        onEntering: props.handleEntering,
        onEntered: props.handleEntered,
        onExit: props.handleExit,
        onExiting: props.handleExiting,
        onExited: props.handleExited,
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
      PaperProps={{ style: state.newWidth }}
    >
      {resizable ? (
        <div
          id="dragger"
          onMouseDown={handleMousedown}
          onTouchStart={handleMousedown}
          onTouchEnd={e => e.preventDefault()}
          className={classes.dragger}
        >
          <div
            style={{
              display: 'inline-flex',
              width: '45px',
              height: '45px',
              borderRadius: '50%',
              // backgroundColor: '#f4f7f9',
              // borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
              position: 'absolute',
              left: '-22px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DragIndicator style={{ width: '18px', marginLeft: '-2px' }} />
          </div>
        </div>
      ) : null}
      {props.showToolbar && <div className={classes.toolbar} />}
      {children}
    </Drawer>
  );
}

ClippedDrawer.defaultProps = {
  drawerWidth: DRAWER_WIDTH,
  showToolbar: true,
  handleClose: () => console.log('drawer onClose event'),
  handleEnter: () => {},
  handleEntering: () => {},
  handleEntered: () => {},
  handleExit: () => {},
  handleExiting: () => {},
  handleExited: () => {},
  onResizeCB: () => {},
  onResizeStartCB: () => {},
  onResizeEndCB: () => {},
  resizable: false,
  drawerVariant: 'persistent',
};

export default withStyles(styles)(ClippedDrawer);
