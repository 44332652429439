import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import './ContentWrapper.css';

const useStales = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    // paddingTop: 0,
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
}));

const ContentWrapper = ({ children }) => {
  const classes = useStales();

  return (
    <>
      <div className={classes.toolbar} />
      <main className={`content-wrapper ${classes.content}`}>{children}</main>
    </>
  );
};

export default ContentWrapper;
