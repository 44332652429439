import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import isEqual from 'lodash/isEqual';

// import barMetric from './graphs/hist1_metric.json';
// import { DraggableLabel } from 'components';
import { d3 } from 'utils';
import { useShallowSelector } from 'hooks';

import './BarChart.css';

const margin = { top: 20, bottom: 20, left: 30, right: 20 };

const BarChart = React.memo(function BarChart() {
  const themeMode = useShallowSelector(s => s.common.themeMode);
  const graphData = useSelector(s => s.graph.graphData);
  const histogram = useSelector(s => s.graph.graphData.histogram);
  // const graphType = useSelector(s => s.graph.graphType);
  const [showBarChart, setShowBarChart] = useState(false);
  let svgContainer = null;
  let svgNode = null;
  const [chartData] = graphData.charts;

  useEffect(() => {
    if (chartData) {
      setShowBarChart(true);
    } else {
      setShowBarChart(false);
    }
  }, [chartData]);

  useEffect(() => {
    switch (chartData.type) {
      case 'basic_histogram':
        renderBasicHistogram(chartData);
        break;
    }
    /*if (graphData.histogram.xAxis) {
      if (graphData.histogram.type === 'continuous' || graphData.histogram.type === 'mixed') {
        renderBarChartContinuos(graphData.histogram);
      } else {
        renderBarChart(graphData.histogram);
      }
    }*/
  }, [showBarChart, histogram]);

  function renderBasicHistogram(data) {
    const container = d3.select(svgContainer);
    const containerWidth = parseFloat(container.style('width'));
    const containerHeight = parseFloat(
      container.node().getBoundingClientRect().height
    );
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    const svgRoot = d3.select(svgNode);
    svgRoot.selectAll('*').remove();

    const svg = svgRoot
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('class', 'bordered')
      .attr('viewBox', '0 0 ' + containerWidth + ' ' + containerHeight)
      .attr('preserveAspectRatio', 'xMinYMid')
      .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const tooltips = data.tooltips.map(tooltipRows => tooltipRows.join('\n'));
    const xAxis = data.xAxis.map(value => Number(value));
    const histogramData = data.heights.map((value, index) => {
      return {
        height: Number(value),
        color: data.colors[index],
        tooltip: tooltips[index]
      }
    });

    const domainPadding = (xAxis[xAxis.length - 1] - xAxis[0]) * .05;
    const domain = [xAxis[0] - domainPadding, xAxis[xAxis.length - 1] + domainPadding];

    const x = d3.scaleLinear()
      .domain(domain)
      .range([0, width]);
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr('class', `${themeMode === 'light' ? '' : 'axisWhite'}`)
      .call(d3.axisBottom(x));

    const bins = histogramData.map((binData, index) => {
      const result = [binData];
      result.x0 = xAxis[index];
      result.x1 = xAxis[index + 1];
      return result;
    });

    const y = d3.scaleLinear().range([height, 0]);
    y.domain([0, d3.max(bins, (d) => {
      const [barData] = d;
      return barData.height;
    })]);

    svg
      .append("g")
      .attr('class', `${themeMode === 'light' ? '' : 'axisWhite'}`)
      .call(d3.axisLeft(y));

    const binsGroup = svg.append('g');
    binsGroup.selectAll("rect")
      .data(bins)
      .enter()
      .append("rect")
        .attr("x", 1)
        .attr("transform", d => {
          const [barData] = d;
          return "translate(" + x(d.x0) + "," + y(barData.height) + ")";
        })
        .attr("width", d => (x(d.x1) - x(d.x0) -1))
        .attr("height", function(d) {
          const [barData] = d;
          return height - y(barData.height);
        })
        .style("fill", d => {
          const [barData] = d;
          return barData.color;
        })
        .attr('stroke', '#999')
        .attr('stroke-width', '1')

    const transitionDuration = 200;
    const tooltipsHelperGroup = svg.append('g');
    tooltipsHelperGroup.selectAll("rect")
      .data(bins)
      .enter()
      .append("rect")
        .attr("x", 0)
        .attr("transform", d => {
          return `translate(${x(d.x0)},0)`;
        })
        .attr("width", d => (x(d.x1) - x(d.x0)))
        .attr("height", height)
        .style("fill", "transparent")
        .on('mouseenter', function(data, index) {
          binsGroup
            .selectAll('rect')
              .transition()
              .duration(transitionDuration)
              .attr('opacity', function(binData, binIndex) {
                if (binIndex === index) {
                  return '1';
                }
                return '0.3';
              })

        })
        .on('mouseleave', function() {
          binsGroup.selectAll('rect').transition().duration(transitionDuration).attr('opacity', "1");
        })
        .append('title')
          .text(d => {
            const [barData] = d;
            return barData.tooltip;
          })
  }

  function renderBarChart(graphRow) {
    let margin = { top: 20, bottom: 20, left: 30, right: 20 };
    let container = d3.select(svgContainer);
    let containerWidth = parseFloat(container.style('width'));
    // let containerHeight = parseFloat(container.style('height'));
    let containerHeight = parseFloat(
      container.node().getBoundingClientRect().height,
    );
    // let width = 600 - margin.left - margin.right;
    // let height = 600 - margin.top - margin.bottom;
    let width = containerWidth - margin.left - margin.right;
    let height = containerHeight - margin.top - margin.bottom;
    let graph = graphRow.heights.map((h, i) => ({
      height: parseFloat(h),
      xAxis: isNaN(parseFloat(graphRow.xAxis[i]))
        ? graphRow.xAxis[i]
        : parseFloat(graphRow.xAxis[i]).toFixed(1),
      color: graphRow.colors[i],
      // bin_edges: graphRow.xAxis[i],
    }));

    let svg = d3.select(svgNode);

    svg.selectAll('*').remove();

    // prettier-ignore
    let svgG = d3
      .select(svgNode)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('class', 'bordered')
    .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // let svg = d3.select(svgNode);
    // svg.selectAll('*').remove();

    // let containerAspect = parseFloat(containerWidth / containerHeight);
    let rectWidth = width / graph.length;

    // for x domain func
    // let [xMin, xMax] = d3.extent(graph, (d, i) => i);

    // only for starting points
    let xScale = d3
      .scaleBand()
      .rangeRound([0, width]) // actual width of the container
      .paddingInner(0.05)
      .domain(graph.map(d => d.xAxis)) // data range,
      .padding(0.2);

    // for domain func
    let [yMin, yMax] = d3.extent(graph, d => d.height);

    let yScale = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([height, 0]);

    let heightScale = d3
      .scaleLinear()
      // range that reflects data
      .domain([0, yMax])
      // range that reflects dimensions on the screen
      .range([0, height]);

    function resize() {
      var targetWidth = parseFloat(container.style('width'));
      // var targetHeight = parseFloat(container.style('height'));
      var targetHeight = container.node().getBoundingClientRect().height;
      svg.attr('width', targetWidth);
      // svg.attr('height', Math.round(targetWidth / containerAspect));
      svg.attr('height', targetHeight);
    }

    svg
      .attr('viewBox', '0 0 ' + containerWidth + ' ' + containerHeight)
      .attr('preserveAspectRatio', 'xMinYMid')
      .call(resize);

    function updateBars(data) {
      let t = d3.transition().duration(1000);

      let bars = svgG.selectAll('rect').data(data);

      // EXIT
      bars
        .exit()
        // .transition(t)
        .attr('y', height)
        .attr('height', 0)
        .remove();

      // ENTER
      let enter = bars
        .enter()
        .append('rect')
        //.attr('width', rectWidth)
        .attr('width', xScale.bandwidth())
        .attr('stroke', '#fff')
        .attr('opacity', 0.7)
        .attr('y', height);

      // ENTER + UPDATE
      bars = enter
        .merge(bars)
        .attr('fill', d => d.color)
        // x - starting point for rect on x axis
        .attr('x', (d, i) => xScale(d.xAxis))
        // .transition(t)
        // starting point for rect on y axis
        .attr('y', d => yScale(d.height))
        .attr('height', d => heightScale(d.height));

      let xAxis = d3.axisBottom().scale(xScale);
      // .ticks(5)
      // .tickSize(20);
      // .tickPadding(10);

      let yAxis = d3.axisLeft().scale(yScale);

      svgG
        .append('g')
        .attr('class', `${themeMode === 'light' ? '' : 'axisWhite'}`)
        .attr('transform', `translate(${0}, ${height})`)
        .call(xAxis);

      svgG
        .append('g')
        .attr('class', `${themeMode === 'light' ? '' : 'axisWhite'}`)
        .attr('transform', `translate(0, ${0})`)
        .call(yAxis);

      // removes every second lable for X axis
      // d3.selectAll('.tick text').each(function(d, i) {
      //   if (i % 2 != 0) d3.select(this).remove();
      // });
    }

    updateBars(graph);
  }
  // currentPreset[currentColSize].forEach(el => {
  //   if ((el.i = 'bar')) {
  //     console.log(el.w);
  //   }
  // });
  // console.log('bar rerender');
  return (
    <div
      style={{ position: 'relative', height: '100%' }}
      ref={node => node && (svgContainer = node)}
    >
      {showBarChart && (
        <svg id="svgBar" ref={node => node && (svgNode = node)} />
      )}
    </div>
  );
});

export default BarChart;
