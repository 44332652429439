import React, { useState, FC } from 'react';
import { useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { TextWrapper } from 'components';
import { GraphApi } from 'api';

import './ModalDeleteLayer.css';
import { ModalDeleteLayerProps } from './types';

const ModalDeleteLAyer: FC<ModalDeleteLayerProps> = ({
  handleClose,
  communityLayerId,
  handleDeleteCallback,
}) => {
  const {
    portfolioId,
    studyId,
    fileId,
    datasetId,
    modelId,
    graphId,
  } = useParams();
  const allIds = { portfolioId, studyId, fileId, datasetId, modelId, graphId };
  const [deleteLoading, setDeleteLoading] = useState(false);

  async function handleDeleteProxy() {
    try {
      setDeleteLoading(true);
      await GraphApi.deleteLayer({
        ...allIds,
        communityLayerId,
        force: true,
        root: true,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      handleClose();
      handleDeleteCallback();
    }
  }

  return (
    <div style={{ width: '550px' }}>
      <div style={{ marginBottom: '50px' }}>
        <TextWrapper>Are you sure you want to delete this layer?</TextWrapper>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '20px 0 10px',
        }}
      >
        <Button
          variant="contained"
          style={{ marginRight: '25px' }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={deleteLoading}
          variant="contained"
          style={{ minWidth: '168px' }}
          color="primary"
          onClick={handleDeleteProxy}
        >
          {deleteLoading ? (
            <CircularProgress
              color="secondary"
              style={{ width: '20px', height: '20px' }}
            />
          ) : (
            'Delete Layer'
          )}
        </Button>
      </div>
    </div>
  );
};

export default ModalDeleteLAyer;
