import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import { TextWrapper } from 'components';
import { PortfolioApi } from 'api';
import { Table, Loading } from 'components';
import { ModalAddStudy, ModalDelete } from '../index';

import './Portfolio.css';

const columnsConfig = [
  { id: 'name', label: 'Name' },
  { id: 'additionalInfo', label: 'Description' },
];

const rowsConfig = [
  { prop: 'name', format: (val: any) => (val ? val : '-') },
  { prop: 'additionalInfo', format: (val: any) => (val ? val : '-') },
];

const Portfolio = ({ portfolio: portfolioUpper }: any) => {
  const { portfolioId } = useParams();
  const [portfolio, setPortfolio] = useState({
    title: '',
    additionalInfo: '',
    created: '',
  });

  useEffect(() => {
    if (!portfolioUpper?.id) {
      PortfolioApi.getPortfolioDetails(portfolioId).then(({ data }: any) => {
        setPortfolio(data);
      });
    }
  }, []);

  const [showAddStudy, setShowAddStudy] = useState(false);
  const [showDeletePortfolio, setShowDeletePortfolio] = useState(false);

  function handleDeletePortfolio() {
    return PortfolioApi.deletePortfolio({ portfolioId }).then(() => {
      setShowDeletePortfolio(false);
      return PortfolioApi.getPortfoliosTree();
    });
  }

  const title = portfolioUpper?.title ?? portfolio.title;
  const additionalInfo =
    portfolioUpper?.additionalInfo ?? portfolio.additionalInfo;
  const created = new Date(
    parseInt(portfolioUpper?.created || portfolio.created),
  ).toLocaleString();

  return (
    <div>
      <div className="profile-heading-wrapper">
        <div className="profile-title-wrapper">
          <TextWrapper variant="h6">Portfolio: {title}</TextWrapper>
          <TextWrapper variant="subtitle2">Info: {additionalInfo}</TextWrapper>
          <TextWrapper variant="subtitle2">Created at: {created}</TextWrapper>
        </div>
      </div>
      <div className="portfolio-controls-section">
        <Button onClick={() => setShowDeletePortfolio(true)} variant="outlined">
          Delete Portfolio
        </Button>
      </div>

      <Table
        showRadio
        topMargin={158}
        showDeleteButton={true}
        showControlColumn={true}
        noItemsFoundPlaceholder={''}
        loading={false}
        columnsConfig={columnsConfig}
        rowsConfig={rowsConfig}
        LoadingComponent={Loading}
        tableName=""
        items={[]}
        rowClassName="row"
        addButtonItemName="Add Study"
        totalRowsQuantity={0}
        rowsPerPageOptions={[10]}
        onDeleteClick={user => {}}
        onChangePage={(e, nextPge) => {}}
        onColumnHeaderClick={(order, { id: orderBy }) => {}}
        onAddButtonClick={() => setShowAddStudy(true)}
      />
      <ModalAddStudy
        showModal={showAddStudy}
        handleClose={() => setShowAddStudy(false)}
      />
      <ModalDelete
        showModal={showDeletePortfolio}
        handleClose={() => setShowDeletePortfolio(false)}
        warningMessage="Are you sure you want to delete this portfolio?"
        entityName="Portfolio"
        deleteEntity={handleDeletePortfolio}
      />
    </div>
  );
};

export default Portfolio;
