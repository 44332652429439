import React, { useState, FC } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import capitalize from 'lodash/capitalize';

import { TextWrapper } from 'components';
import { IModalDeleteProps } from './types';

import './ModalDelete.css';

const ModalDelete: FC<IModalDeleteProps> = ({
  showModal,
  handleClose,
  deleteEntity,
  warningMessage,
  entityName,
}) => {
  const [deleteLoading, setDeleteLoading] = useState(false);

  async function handleDeleteProxy() {
    setDeleteLoading(true);
    await deleteEntity();
    setDeleteLoading(false);
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={showModal}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Delete {capitalize(entityName)}
        </DialogTitle>
        <br />
        <DialogContent style={{ width: '550px' }}>
          <div style={{ marginBottom: '50px' }}>
            <TextWrapper>{warningMessage}</TextWrapper>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 0 10px',
            }}
          >
            <Button
              variant="contained"
              style={{ marginRight: '25px' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={deleteLoading}
              variant="contained"
              color="primary"
              style={{ minWidth: '167px' }}
              onClick={handleDeleteProxy}
            >
              {deleteLoading ? (
                <CircularProgress
                  color="secondary"
                  style={{ width: '20px', height: '20px' }}
                />
              ) : (
                `Delete ${entityName}`
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalDelete;
