import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Input, TextWrapper } from 'components';
import { Actions } from 'actions';
import { notify, register } from 'utils';
import './Register.css';

const styles = theme => ({
  registerButton: {
    margin: '16px 0 8px',
  },
});

const regExp = RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/);

const Register = ({ classes }) => {
  const [state, setStateRaw] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    newUser: null,
    isSigning: false,
  });

  function setState(newState) {
    return setStateRaw(oldState => ({ ...oldState, ...newState }));
  }

  async function handleRegister() {
    if (state.password !== state.confirmPassword) {
      notify({
        message: 'Password must match confirmation password.',
      });
      return;
    }
    setState({ isSigning: true });
    try {
      const newUser = await register({
        email: state.email,
        password: state.password,
      });
      notify({
        message: 'Account was created successfully. Please login to proceed.',
      });
    } catch (err) {
      console.log(err);
      notify({
        message: err.message,
      });
    }

    setState({ isSigning: false });
  }

  return (
    <div className="register-container">
      <div className="register-controls-container">
        <TextWrapper className="margin-top" variant="h5" align="center">
          Atlas
        </TextWrapper>
        <Input
          id="email"
          placeholder="example@mail.com"
          label="Email"
          that={{ setState, state }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleRegister();
            }
          }}
        />
        <Input
          id="password"
          type="password"
          label="Password"
          that={{ setState, state }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleRegister();
            }
          }}
        />
        <Input
          id="confirmPassword"
          type="password"
          label="Confirm Password"
          that={{ setState, state }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleRegister();
            }
          }}
        />
        <Button
          size="large"
          className={classes.registerButton}
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleRegister}
          disabled={state.isSigning}
        >
          <span
            style={{
              display: 'inline-flex',
              minWidth: '50px',
              lineHeight: '30px',
              height: '30px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {state.isSigning ? (
              <CircularProgress
                color="secondary"
                style={{ width: '20px', height: '20px' }}
              />
            ) : (
              'register'
            )}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(Register);
