//this file probably deprecated
import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Grain from '@material-ui/icons/Grain';
import { withStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

// import lassoImg from './lasso.png';
import coloringImg from './coloring.png';

import { Actions } from 'actions';
import { useShallowSelector } from 'hooks';
import { GraphApi } from 'api';
import { IAppStore } from 'reducers';

const styles = (theme: Theme) => ({
  formControl: {
    minWidth: '100%',
  },
});

export const SelectionFloatingButtons = () => {
  const graphType = useShallowSelector(s => s.graph.graphType);
  const listColorings = useShallowSelector(s => s.graph.list_colorings);
  const graphData = useShallowSelector(s => s.graph.graphData);
  const isDrawerRightOpen = useShallowSelector(s => s.common.isDrawerRightOpen);

  const [state, setState] = useState({
    coloringAnchorEl: null,
    mobileMoreAnchorEl: null,
    selectionAnchorEl: null,
    shouldPrint: false,
    anchorEl: null,
  });

  const {
    portfolioId,
    studyId,
    fileId,
    datasetId,
    modelId,
    graphId,
  } = useParams();
  const allIds = { portfolioId, studyId, fileId, datasetId, modelId, graphId };
  return (<div></div>);
  /*function setColoring(coloring: string) {
    Actions.graph.dispatch({ coloring });
  }

  function handleChangeColoring(coloring: string) {
    setState(state => ({
      ...state,
      coloringAnchorEl: null,
      mobileMoreAnchorEl: null,
    }));
    setColoring(coloring);
    getColoring(coloring);
  }

  function getColoring(coloring: string) {
    setState(state => ({
      ...state,
      coloringAnchorEl: null,
      mobileMoreAnchorEl: null,
    }));
    return GraphApi.getColoring(
      {
        ...allIds,
        graphId: graphData.id,
        colorBy: coloring,
      },
      graphData,
    );
  }

  useEffect(() => {
    return () => {
      Actions.graph.dispatch({
        blue: false,
        red: false,
      });
      setState(state => ({ ...state, selectionAnchorEl: null }));
    };
  }, []);

  return (
    <div style={{ position: 'absolute', width: '100%', zIndex: 10 }}>
      <div
        style={{
          position: 'absolute',
          top: '90px',
          left: '15px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        {!isDrawerRightOpen && (
          <>
            <Tooltip title="Color" placement="top">
              <Fab
                variant="extended"
                size="small"
                style={{ marginBottom: '25px', minWidth: '130px' }}
                aria-owns={state.anchorEl ? 'coloring-menu' : undefined}
                aria-haspopup="true"
                onClick={e => {
                  setState(state => ({
                    ...state,
                    coloringAnchorEl: (e as any).currentTarget,
                  }));
                }}
              >
                Color
                <img
                  style={{ width: '20px', marginLeft: '10px' }}
                  src={coloringImg}
                  alt="color"
                />
              </Fab>
            </Tooltip>
            <Menu
              id="coloring-menu"
              anchorEl={state.coloringAnchorEl}
              open={Boolean(state.coloringAnchorEl)}
              onClose={() =>
                setState(state => ({ ...state, coloringAnchorEl: null }))
              }
            >
              {listColorings.map((coloring: string, i: number) => {
                return (
                  <MenuItem
                    key={i}
                    onClick={() => handleChangeColoring(coloring)}
                  >
                    {coloring}
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
        <Tooltip
          title={`Current Graph Type: "${
            graphType === 'clustered' ? 'Simple' : 'Detailed'
          }"`}
          placement="top"
        >
          <Fab
            variant="extended"
            size="small"
            style={{ marginBottom: '25px', minWidth: '130px' }}
            // aria-owns={graphTypeAnchorEl ? 'graph-type-menu' : undefined}
            // aria-haspopup="true"
            onClick={e => {
              // setState({ graphTypeAnchorEl: e.currentTarget });
              Actions.graph.dispatch(graph => {
                const [chart] = graph.initialGraphData.charts;
                return {
                  graphType: graphType === 'clustered' ? 'metric' : 'clustered',
                  'graphData.chart': chart,
                }
              });
            }}
          >
            Type
            <Grain
              style={{ width: '20px', height: '20px', margin: '0 0 0 5px' }}
            />
          </Fab>
        </Tooltip>{' '}
      </div>
    </div>
  );*/
};

export default withStyles(styles)(SelectionFloatingButtons);
