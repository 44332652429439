import React, { useState, useRef } from 'react';

import { useParams } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';

import { Input, TextWrapper } from 'components';
import { GraphApi } from 'api';
import { notify } from 'utils';

import './ModalCreateGraph.css';

const ModalCreateGraph = ({ showModal, handleClose, listGraphs }) => {
  const { portfolioId, studyId, fileId, datasetId, modelId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  async function createGraph() {
    const data = {
      portfolioId,
      studyId,
      fileId,
      datasetId,
      modelId,
    };
    setIsLoading(true);
    await GraphApi.calculateGraph(data);
    notify({ message: 'Graphs were generated successfully' });
    setIsLoading(false);
    handleClose();
    listGraphs();
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={showModal}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Generate Graphs</DialogTitle>
        <br />
        <DialogContent style={{ width: '550px' }}>
          <div>
            <TextWrapper>
              After clicking button Generate Graph - system will automatically
              calculate a set of graphs.
            </TextWrapper>
            <TextWrapper>It may take up to two minutes.</TextWrapper>
          </div>
          <div style={{ marginBottom: '50px' }}></div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 0 10px',
            }}
          >
            <Button
              variant="contained"
              style={{ marginRight: '25px' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ minWidth: '167px' }}
              variant="contained"
              color="primary"
              onClick={createGraph}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress
                  style={{ width: '22px', height: '22px' }}
                  color="secondary"
                />
              ) : (
                'Generate Graphs'
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalCreateGraph;
