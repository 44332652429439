import React, { useEffect } from 'react';

import { history, pageUrl } from '../../config';
import { useShallowSelector } from '../../hooks';

function AuthChecker(props) {
  const auth = useShallowSelector(s => s.auth);
  const graph = useShallowSelector(s => s.graph);

  useEffect(() => {
    if (!auth.access_token || (!graph.options && !auth.access_token)) {
      // debugger;
      return history.push(pageUrl.login());
    }
  }, [auth.access_token]);

  const { innerComponent: App } = props;

  return <App {...props} />;
}

export default AuthChecker;
