import React, { useState, useEffect, useRef } from 'react';
import { ListItem, ListItemText, IconButton, Tooltip, Collapse, List, ListSubheader, Menu, MenuItem, ListItemIcon, Typography, Divider, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import SharedIcon from 'components/CustomIcons/SharedIcon';
import CommunityItem from '../CommunityItem/CommunityItem';
import pink from '@material-ui/core/colors/pink';
import SaveIcon from '@material-ui/icons/Save';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
import EditIcon from '@material-ui/icons/Edit';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DownloadIcon from '@material-ui/icons/GetApp';
import ColorizeIcon from '@material-ui/icons/Colorize';

import { makeStyles } from '@material-ui/core/styles';
import { DEFAULT_NODE_COLOR } from 'utils';
import { useShallowSelector } from 'hooks';
import { IAppStore, CommunityData, LayerData } from 'reducers';
import { Actions } from 'actions';
import { useSelector } from 'react-redux';

const MAX_DEFAULT_COMMUNITES_QUANTITY_TO_SHOW = 15;
const COMMUNITIES_QUANTITY_DEVIATION = 5;

type LayerItemProps = {
  layer: LayerData,
  selected: boolean,
  innerLoading: boolean,
  onSelectLayer: (layer: LayerData) => void,
  onSave: (layer: LayerData) => void,
  onDeleteLayer: (layer: LayerData) => void,
  onCopyLayer: (layer: LayerData) => void,
  onChangePublicLayerAccess: (layer: LayerData, isPublic: boolean) => void,
  onMergeCommunities: (layer: LayerData) => void,
  onDownloadLayerCommunities: (layer: LayerData) => void,
  rerenderLayers: () => void
};

const useStyles = makeStyles(theme => ({
  layerNameWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  layerNameIconWrapper: {
    width: theme.spacing(6)
  },
  layerNameIcon: {
    margin: 12
  },
  menuItemIcon: {
    minWidth: 32
  },
  communityActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingLeft: theme.spacing(6)
  },
  communityActionsLabel: {
    marginRight: 'auto'
  },
  noLeftPadding: {
    paddingLeft: 0
  }
}));

const StyledCircle = () => {
  return (
    <CircularProgress
      style={{ width: '25px', height: '25px', margin: '5px', color: pink[500] }}
    />
  );
};

const LayerItem = ({
    layer,
    selected = false,
    innerLoading,
    onSelectLayer = () => {},
    onSave = () => {},
    onDeleteLayer = () => {},
    onCopyLayer = () => {},
    onChangePublicLayerAccess = () => {},
    onMergeCommunities = () => {},
    onDownloadLayerCommunities = () => {},
    rerenderLayers
}: LayerItemProps) => {
  const eyeDropToolActive = useShallowSelector(s => s.graph.eyeDropToolActive);
  const layerCommunitiesIds = layer.communities.map(({ id }) => id);
  const selectedLayerCommunityIds = useShallowSelector((s: IAppStore) => (
    s.graph.selectedLayerCommunityIds
  )).filter(id => layerCommunitiesIds.includes(id));

  //not saved communities have id as Symbol()
  const canCommunitiesMerge = (
    selectedLayerCommunityIds.length > 1 &&
    selectedLayerCommunityIds.every(id => typeof id === 'string')
  );

  const classes = useStyles();

  const [expanded, setExpanded] = useState(selected);
  const [showAllCommunities, setShowAllCommunities] = useState(false);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  //disable auto-expand when selected by request
  /*useEffect(() => {
    setExpanded(selected);
  }, [selected]);*/

  const onOpenMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setMenuAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  function isLayerHavingChanges(layer: any) {
    return (layer?.communities ?? []).some((c: any) => (c.hasChanges || c.removed));
  }

  function addCommunity(layer: any) {
    layer.communities = layer.communities || [];
    layer.communities.push({
      id: Symbol(),
      nodes: [],
      color: DEFAULT_NODE_COLOR,
      hasChanges: true,
      removed: false
    });

    rerenderLayers();
  }

  function deleteCommunity(communityToDelete: CommunityData) {
    if (communityToDelete.hasChanges) {
      layer.communities = layer.communities.filter((c: any) => c !== communityToDelete);
    } else {
      communityToDelete.removed = true;
    }
    rerenderLayers();
  }

  function handleCommunityChange(community: CommunityData) {
    rerenderLayers();
  }

  const layerCommunities = (layer?.communities ?? []);
  let communitiesToShow = layerCommunities;
  if (
    !showAllCommunities
    && layerCommunities.length > (MAX_DEFAULT_COMMUNITES_QUANTITY_TO_SHOW + COMMUNITIES_QUANTITY_DEVIATION)
  ) {
      communitiesToShow = layerCommunities.slice(0, MAX_DEFAULT_COMMUNITES_QUANTITY_TO_SHOW);
  }

  const communityColors = layer.communities.map(({ color }) => color);

  return (
    <div key={layer.id}>
      <ListItem
        button
        onClick={() => onSelectLayer(layer)}
        className={classes.noLeftPadding}
      >
        <ListItemText
          style={{
            color: selected ? pink[500] : '',
            fontWeight: selected ? 'bold' : 'normal',
          }}
          primary={(
            <Tooltip title={`${layer.title} (${layer.communities.length})`} placement="left">
              <div className={classes.layerNameWrapper}>
                <div className={classes.layerNameIconWrapper}>
                  {layer.isDefault ? (
                    <BookmarkIcon className={classes.layerNameIcon} />
                  ) : layer.isShared ? (
                    <LockOpenIcon className={classes.layerNameIcon} />
                  ) : layer.isPublic ? (
                    <SharedIcon className={classes.layerNameIcon} />
                  ) : (
                    <LockIcon className={classes.layerNameIcon} />
                  )}
                </div>
                <span>{layer.title}</span>
              </div>
            </Tooltip>
          )}
        />
        {isLayerHavingChanges(layer) && (
          <Tooltip title="Save changes" placement="top">
            {innerLoading ? (
              <span>
                <StyledCircle />
              </span>
            ) : (
              <IconButton
                onClick={() => onSave(layer)}
              >
                <SaveIcon
                  style={{
                    color: pink[500],
                    fontWeight: 'bold',
                  }}
                />
              </IconButton>
            )}
          </Tooltip>
        )}
        <IconButton onClick={onOpenMenuClick}>
          <MoreVertIcon />
        </IconButton>
        {expanded ? (
          <IconButton onClick={() => setExpanded(false)}>
            <ExpandLessIcon
              style={{
                color: selected ? pink[500] : '',
                fontWeight: 'bold',
              }}
            />
          </IconButton>
        ) : (
          <IconButton onClick={() => setExpanded(true)}>
            <ExpandMoreIcon
              style={{
                color: selected ? pink[500] : '',
                fontWeight: 'normal',
              }}
            />
          </IconButton>
        )}
      </ListItem>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        style={{ minHeight: '35px' }}
      >
        <List component="div" disablePadding>
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            disableSticky
            className={classes.noLeftPadding}
          >
            <div className={classes.communityActions}>
              {!layer.isDefault && (layer.isShared || !layer.isPublic) && (
                <>
                  <span className={classes.communityActionsLabel}>Communities</span>
                  {canCommunitiesMerge && !layer.isDefault && (
                    <span>
                      <Tooltip
                        title="Merge selected communities"
                        placement="top"
                      >
                        {innerLoading ? (
                          <StyledCircle />
                        ) : (
                          <IconButton onClick={() => onMergeCommunities(layer)}>
                            <CallMergeIcon
                              style={{
                                color: pink[500],
                                fontWeight: 'bold',
                              }}
                            />
                          </IconButton>
                        )}
                      </Tooltip>
                    </span>
                  )}
                  <span>
                    <Tooltip
                      title="Add new community"
                      placement="top"
                    >
                      <IconButton
                        onClick={() => addCommunity(layer)}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                </>
              )}
            </div>
          </ListSubheader>
          {communitiesToShow.map(
            (community: any, i: number) => {
              return community.removed ? null : (
                <CommunityItem
                  key={i}
                  isDefault={layer.isDefault}
                  isOwned={layer.isShared || !layer.isPublic}
                  community={community}
                  onDelete={deleteCommunity}
                  onChange={handleCommunityChange}
                  selected={selectedLayerCommunityIds.includes(community.id)}
                  forbiddenColors={communityColors.filter(color => (color !== community.color))}
                />
              );
            },
          )}
          {layerCommunities.length !== communitiesToShow.length ? (
            <ListItem>
              <Button variant="contained" onClick={() => setShowAllCommunities(true)}>
                Show all ({layerCommunities.length - communitiesToShow.length} more)
              </Button>
            </ListItem>
          ) : null}
        </List>
      </Collapse>
      <Menu
        id="layer-menu"
        getContentAnchorEl={null}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => {
          onCopyLayer(layer);
          handleMenuClose();
        }}>
          <ListItemIcon className={classes.menuItemIcon}>
            <FileCopyIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Copy Layer</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          onDeleteLayer(layer);
          handleMenuClose();
        }} disabled={layer.isDefault || (layer.isPublic && !layer.isShared)}>
          <ListItemIcon className={classes.menuItemIcon}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Delete Layer</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          //todo, not implemented
          handleMenuClose();
        }} disabled>
          <ListItemIcon className={classes.menuItemIcon}>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Edit details</Typography>
        </MenuItem>
        { !layer.isPublic && !layer.isDefault && (
          <MenuItem onClick={() => {
            onChangePublicLayerAccess(layer, true);
            handleMenuClose();
          }}>
            <ListItemIcon className={classes.menuItemIcon}>
              <LockOpenIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Share</Typography>
          </MenuItem>
        )}
        { layer.isShared && !layer.isDefault && (
          <MenuItem onClick={() => {
            onChangePublicLayerAccess(layer, false);
            handleMenuClose();
          }}>
            <ListItemIcon className={classes.menuItemIcon}>
              <LockIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Make private</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={() => {
          onDownloadLayerCommunities(layer);
          handleMenuClose();
        }}>
          <ListItemIcon className={classes.menuItemIcon}>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Download Communities</Typography>
        </MenuItem>
      </Menu>
    </div>
  )
};

export default LayerItem;