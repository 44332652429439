import React, { useState, useRef, ChangeEvent } from 'react';

import { useParams } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Input, TextWrapper } from 'components';
import { ModelApi, PortfolioApi } from 'api';
import { IRouterParams } from './types';

import './ModalAddModel.css';

const ModalAddModel = ({ showModal, handleClose }: any) => {
  const {
    portfolioId,
    studyId,
    fileId,
    datasetId,
  }: IRouterParams = useParams();
  const defaultFileState = {
    isSelected: false,
    files: [],
    file: null as File | null,
  };
  const defaultInputState = {
    title: '',
    additionalInfo: '',
    normalization: false,
  };
  const [fileState, setFileState] = useState({ ...defaultFileState });
  const [fileMatrixState, setFileMatrixState] = useState({ ...defaultFileState });
  const [loadingCreateModel, setLoadingCreateModel] = useState(false);
  const [modelState, setModelState] = useState({ ...defaultInputState });
  const fileInputNode = useRef(null);
  const fileMatrixInputNode = useRef(null);

  async function onModelInputChange(e: ChangeEvent<HTMLInputElement>) {
    setFileState(s => ({
      ...s,
      isSelected: !!e.target.value,
      file: e.target.files?.[0] ?? null,
    }));
  }

  async function onModelMatrixInputChange(e: ChangeEvent<HTMLInputElement>) {
    setFileMatrixState(s => ({
      ...s,
      isSelected: !!e.target.value,
      file: e.target.files?.[0] ?? null,
    }));
  }

  async function uploadFile(file: File) {
    const formData = new FormData();

    formData.append('portfolioId', portfolioId);
    formData.append('studyId', studyId);
    formData.append('file', file as Blob);

    const {
      data: { fileLink },
    } = await ModelApi.uploadModel(formData);

    return fileLink;
  }

  async function createModel() {
    if (fileState.file) {
      setLoadingCreateModel(true);

      const fileLink = await uploadFile(fileState.file);
      let fileMatrixLink = null;
      let distanceMatrixData = null;

      if (fileMatrixState.file) {
        fileMatrixLink = await uploadFile(fileMatrixState.file);

        distanceMatrixData = {
          distanceMatrixData: {
            fileLink: fileMatrixLink
          }
        };
      }

      const data = {
        portfolioId,
        studyId,
        datasetId,
        fileId,
        title: modelState.title,
        normalization: modelState.normalization,
        additionalInfo: modelState.additionalInfo,
        graphsData: {
          fileLink: fileLink,
        },
        ...distanceMatrixData
      };

      await ModelApi.createModel(data);
      setLoadingCreateModel(true);
      onClose();
      PortfolioApi.getPortfoliosTree();
    }
  }

  function onClose() {
    handleClose();
    setFileState({ ...defaultFileState });
    setFileMatrixState({ ...defaultFileState });
    setModelState({ ...defaultInputState });
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={showModal}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Create Model</DialogTitle>
        <br />
        <DialogContent style={{ width: '550px' }}>
          <div style={{ marginBottom: '50px' }}>
            <Input
              id="title"
              placeholder="New Model"
              label="Title"
              that={{ setState: setModelState, state: modelState }}
            />
            <Input
              id="additionalInfo"
              placeholder="This Model contains..."
              label="Description"
              that={{ setState: setModelState, state: modelState }}
            />
            <FormControlLabel
              className="normalization"
              control={
                <Checkbox
                  checked={modelState.normalization}
                  onChange={e =>
                    setModelState(s => ({
                      ...s,
                      normalization: e?.target?.checked,
                    }))
                  }
                  color="primary"
                />
              }
              label="Use normalization"
            />
            {fileState.isSelected ? (
              <div className="upload-file-wrapper">
                <DescriptionIcon className="upload-file-placeholder" />
                <TextWrapper variant="subtitle1">
                  {fileState.file?.name ?? '-'}
                </TextWrapper>
              </div>
            ) : (
              <Button
                className="upload-file-btn"
                variant="contained"
                component="label"
              >
                Pick a Model File
                <input
                  ref={fileInputNode}
                  onChange={onModelInputChange}
                  multiple={false}
                  accept=".csv,.json"
                  type="file"
                  style={{ display: 'none' }}
                />
              </Button>
            )}
            {fileMatrixState.isSelected ? (
              <div className="upload-file-wrapper">
                <DescriptionIcon className="upload-file-placeholder" />
                <TextWrapper variant="subtitle1">
                  {fileMatrixState.file?.name ?? '-'}
                </TextWrapper>
              </div>
            ) : (
              <Button
                className="upload-file-btn"
                variant="contained"
                component="label"
              >
                Pick an optional Distance Matrix File
                <input
                  ref={fileMatrixInputNode}
                  onChange={onModelMatrixInputChange}
                  multiple={false}
                  accept=".csv"
                  type="file"
                  style={{ display: 'none' }}
                />
              </Button>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 0 10px',
            }}
          >
            <Button
              variant="contained"
              style={{ marginRight: '25px' }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={!fileState.file || loadingCreateModel}
              variant="contained"
              color="primary"
              style={{ minWidth: '138px' }}
              onClick={createModel}
            >
              {loadingCreateModel ? (
                <CircularProgress
                  color="secondary"
                  style={{ width: '20px', height: '20px' }}
                />
              ) : (
                'Create Model'
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalAddModel;
