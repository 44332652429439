import React from 'react';

import CloseIcon from '@material-ui/icons/Close';

import { history, pageUrl } from 'config';

import './ProfileWrapper.css';

const ProfileWrapper = ({ children, ...props }) => {
  function returnToMain() {
    history.push(pageUrl.main());
  }
  return (
    <>
      <div className="column-divider" />
      <div className="profile-wrapper">
        <CloseIcon className="close-icon" onClick={returnToMain} />

        {React.cloneElement(children, props)}
      </div>
    </>
  );
};

export default ProfileWrapper;
