import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

import { constants } from '../config/constants';
import {
  commonInitialState,
  authInitialState,
  notificationsInitialState,
  predictorsInitialState,
  flagsInitialState,
  usersInitialState,
  portfoliosInitialState,
  studiesInitialState,
  filesInitialState,
  datasetsInitialState,
  modelsInitialState,
  graphsInitialState,
  sqlQueriesInitialState
} from './initialStates';

import { graphInitialState } from './graph';

// separate reducer config for saving its data in sessionStorage via redux-persist API
const authPersistConfig = {
  key: 'auth',
  storage: sessionStorage,
};

/**
 * List of reducers
 * Example:
 * {
 *   name: 'auth',
 *   basicType: constants.LOGIN,
 *   initialState: authInitialState,
 *   config: authPersistConfig,
 *   handlers: {
 *      [constants.LOGIN + '_CUSTOME']: (state, action) => ({ ...state }),
 *      [constants.LOGIN + '_CUSTOME2']: (state, action) => {
 *        return { ...state };
 *      },
 *      [constants.LOGIN + '_CUSTOME3']: (state, action) => {
 *        return { ...state };
 *      },
 *    },
 *  },
 * },
 * @type {[*]}
 */

export const reducers = [
  {
    name: 'auth',
    basicType: constants.AUTH,
    initialState: authInitialState,
    config: authPersistConfig,
  },
  {
    name: 'notifications',
    basicType: constants.NOTIFICATIONS,
    initialState: notificationsInitialState,
  },
  {
    name: 'common',
    basicType: constants.COMMON,
    initialState: commonInitialState,
  },
  {
    name: 'graph',
    basicType: constants.GRAPH,
    initialState: graphInitialState,
  },
  {
    name: 'req',
    basicType: constants.REQ,
  },
  {
    name: 'predictors',
    basicType: constants.TABS,
    initialState: predictorsInitialState,
  },
  {
    name: 'users',
    basicType: constants.USERS,
    initialState: usersInitialState,
  },
  {
    name: 'portfolios',
    basicType: constants.PORTFOLIOS,
    initialState: portfoliosInitialState,
  },
  {
    name: 'studies',
    basicType: constants.STUDIES,
    initialState: studiesInitialState,
  },
  {
    name: 'files',
    basicType: constants.FILES,
    initialState: filesInitialState,
  },
  {
    name: 'datasets',
    basicType: constants.DATASETS,
    initialState: datasetsInitialState,
  },
  {
    name: 'models',
    basicType: constants.MODELS,
    initialState: modelsInitialState,
  },
  {
    name: 'graphs',
    basicType: constants.GRAPHS,
    initialState: graphsInitialState,
  },
  {
    name: 'flags',
    basicType: constants.FLAGS,
    initialState: flagsInitialState,
  },
  {
    name: 'sqlQueries',
    basicType: constants.SQLQUERIES,
    initialState: sqlQueriesInitialState,
  }
];
