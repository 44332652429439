import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { Actions } from 'actions';
import { pageUrl } from 'config';
import { Header, ContentWrapper } from 'components';
import {
  NavBar,
  LayersDrawer,
} from 'containers';
import { Graph, Users, Main } from 'pages';
import { useShallowSelector } from 'hooks';

import './App.css';

// const a4Dimensions = { height: 1133, width: 793 };

export function App(props) {
  useEffect(() => {
    window.addEventListener('resize', debounce(handleWindowResize, 150));
    return () => {
      window.removeEventListener('resize', debounce(handleWindowResize, 150));
    };
  }, []);

  const themeMode = useShallowSelector(s => s.common.themeMode);
  const showTools = useShallowSelector(s => s.flags.showTools);

  function handleWindowResize(e) {
    return Actions.common.dispatch({
      width: e.target.innerWidth,
      height: e.target.innerHeight,
    });
  }

  return (
    <>
      <Header
        showTools={showTools}
        themeMode={themeMode}
        handleLeftMenuClick={() => {}}
        handleRightMenuClick={() => {}}
      />

      <ContentWrapper>
        <Switch>
          <Route path={pageUrl.main()}>
            <NavBar />
          </Route>
          <Route path={pageUrl.users.users()}>
            <NavBar />
          </Route>
        </Switch>

        <div className="pages">
          <Switch>
            <Route path={pageUrl.main()}>
              <Main />
            </Route>
            <Route path={pageUrl.graph()}>
              <Graph />
            </Route>
            <Route path={pageUrl.users.users()}>
              <Users />
            </Route>
            <Redirect to={pageUrl.main()} />
          </Switch>
        </div>


      </ContentWrapper>
    </>
  );
}

export default App;
