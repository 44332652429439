import { useShallowSelector } from 'hooks';
import { LayerData } from 'reducers';
import { Actions } from 'actions';

import { ReturnVal } from './types';

export const useCurrentLayer = (): ReturnVal => {
  const currentLayerId = useShallowSelector(s => s.graph.currentLayerId);
  const layers = useShallowSelector(s => s.graph.layers);

  const currentLayer = layers.find(
    (layer: LayerData) => layer.id === currentLayerId,
  );

  function setCurrentLayerId(currentLayerId: string) {
    Actions.graph.dispatch({ currentLayerId });
  }

  return { currentLayer, setCurrentLayerId };
};
