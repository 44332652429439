export const pageUrl = {
  root: () => `/app`,
  main: () => `/app/main`,
  portfolio: (portfolioId = ':portfolioId') => `/app/main/${portfolioId}`,
  study: (portfolioId = ':portfolioId', studyId = ':studyId') =>
    `/app/main/${portfolioId}/${studyId}`,
  file: (
    portfolioId = ':portfolioId',
    studyId = ':studyId',
    fileId = ':fileId',
  ) => `/app/main/${portfolioId}/${studyId}/${fileId}`,
  dataset: (
    portfolioId = ':portfolioId',
    studyId = ':studyId',
    fileId = ':fileId',
    datasetId = ':datasetId',
  ) => `/app/main/${portfolioId}/${studyId}/${fileId}/${datasetId}`,
  model: (
    portfolioId = ':portfolioId',
    studyId = ':studyId',
    fileId = ':fileId',
    datasetId = ':datasetId',
    modelId = ':modelId',
  ) => `/app/main/${portfolioId}/${studyId}/${fileId}/${datasetId}/${modelId}`,
  graph: (
    portfolioId = ':portfolioId',
    studyId = ':studyId',
    fileId = ':fileId',
    datasetId = ':datasetId',
    modelId = ':modelId',
    graphId = ':graphId',
  ) =>
    `/app/graph/${portfolioId}/${studyId}/${fileId}/${datasetId}/${modelId}/${graphId}`,

  users: {
    users: () => `/app/users`,
  },
  login: () => `/login`,
  testApi: () => `/app/testApi`,
};
