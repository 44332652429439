import { Actions } from '../../actions';
import { apiUrl } from '../../config';

export class FileApi {
  static createFile = data => {
    return Actions.files.POST({
      url: apiUrl.files.createFile(),
      data,
    });
  };

  static listFiles = (portfolioId, studyId) => {
    return Actions.files.POST({
      url: apiUrl.files.listFile(),
      data: {
        filtering: {
          isDeleted: false,
        },
        // "pagination": {
        //   "page": 0,
        //   "perPage": 0
        // },
        portfolioId,
        studyId,
        // "sorting": {
        //   "title": "string"
        // }
      },
      // mapData: response => {
      //   console.log(response);
      //   return {};
      // },
    });
  };

  static getFileDetails = data => {
    return Actions.files.POST({
      url: apiUrl.files.getFileDetails(),
      data,
    });
  };

  static deleteFile = data => {
    return Actions.files.DELETE({
      url: apiUrl.files.deleteFile(),
      data: { force: true, ...data },
    });
  };

  static uploadFile = formData => {
    return Actions.files.POST({
      url: apiUrl.files.uploadFile(),
      data: formData,
      headersCustom: h => {
        delete h['Content-Type'];
        return h;
      },
    });
  };
}
