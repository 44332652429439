import React, { Component } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';

// import metric from './graphs/graph1_metric.json';
// import clustered from './graphs/graph1_clustered.json';
import { Actions } from 'actions';
import { d3 } from 'utils';
import { DraggableLabel } from 'components';
import { SelectionFloatingButtons } from 'containers';
import { mousedown, mousemove, mouseup } from './localUtils';

import './ForceChart.css';
import 'd3-selection-multi';

class ForceChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      red: false,
      blue: false,
    };

    this._graphs = this.props.graphData;
    this._svgContainer = null;
    this._commonContainer = null;
    this._svg = null;
    this._svgD3 = null;
    this._zoom = null;
    this._transform = { x: 0, y: 0, k: 1 };
    this._resize = () => console.log('dummy resize');
    this._update = () => console.log('dummy update');
    this._shouldFade = true;
  }

  componentDidMount() {
    this._renderStaticForce(...this._prepareNodesAndLinks(this.props.graph));
    this._selectionModeSwitcher();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.width !== this.props.width ||
      nextProps.graph !== this.props.graph ||
      // nextProps.isDrawerLeftVisible !== this.props.isDrawerLeftVisible ||
      nextProps.forceUpdate !== this.props.forceUpdate ||
      nextProps.coloringUpdate !== this.props.coloringUpdate ||
      nextProps.red !== this.props.red ||
      nextProps.blue !== this.props.blue
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.width !== this.props.width ||
      prevProps.isDrawerLeftVisible !== this.props.isDrawerLeftVisible ||
      prevProps.forceUpdate !== this.props.forceUpdate
    ) {
      this._resize();
    }
    if (
      prevProps.coloringUpdate !== this.props.coloringUpdate ||
      prevProps.graph !== this.props.graph
    ) {
      this._update(...this._prepareNodesAndLinks(this.props.graph), true);
    }

    this._selectionModeSwitcher();
  }

  componentWillUnmount() {
    Actions.graph.dispatch({ selection: [], red: false, blue: false });
  }

  _selectionModeSwitcher = () => {
    if (this.props.red || this.props.blue) {
      this._svgD3.classed('selection', true);
      this._svgD3.classed('zoom', false);
      this._svgD3.on('.zoom', null);
    } else {
      this._svgD3.classed('selection', false);
      this._svgD3.classed('zoom', true);
      this._svgD3.call(this._zoom);
    }
  };

  _prepareNodesAndLinks = graph => {
    const { nodes, links } = cloneDeep(this._graphs[graph]);
    let linksRaw = links.map(d => ({
      ...d,
      source: d.s,
      target: d.t,
    }));
    return [nodes, linksRaw];
  };

  _renderStaticForce = (nodesRaw, linksRaw) => {
    let margin = { top: 5, bottom: 5, right: 5, left: 5 };
    let width = 700;
    let height = 600;
    let aspect = parseFloat(width / height);
    let radius = 8;
    let links = [];
    let circles = [];
    let that = this;
    let t = d3
      .transition()
      .duration(450)
      .ease(d3.easeLinear);

    let svg = d3
      .select(this._svg)
      .attr('width', width)
      .attr('height', height);
    let container = d3.select(this._svgContainer);
    let commonContainer = d3.select(this._commonContainer);

    this._svgD3 = svg;

    let resize = () => {
      var targetWidth = parseFloat(container.style('width'));
      // var targetHeight = parseFloat(container.style('height'));
      // var targetHeight = container.node().getBoundingClientRect().height;
      var targetHeightG = commonContainer.node().getBoundingClientRect().height;
      svg.attr('width', Math.round(targetWidth));
      // svg.attr('height', Math.round(targetWidth / aspect));
      svg.attr('height', Math.round(targetHeightG - 18));
      // svg.attr('height', Math.round(targetHeight));
    };
    this._resize = resize;
    svg
      .attr(
        'viewBox',
        '0 0 ' +
          (width + margin.left + margin.right) +
          ' ' +
          (height + margin.top + margin.bottom),
      )
      .call(resize);

    let yScale = d3.scaleLinear().range([height, 0]);
    let xScale = d3.scaleLinear().range([0, width]);

    let groupAll = svg.append('g');

    let zoom = d3.zoom().on('zoom', () => {
      let transform = d3.event.transform;
      const { k } = transform;
      transform.k = k < 0.5 ? 0.5 : k;
      this._transform = transform;
      let getTransformK = () =>
        transform && transform.k >= 1 ? transform.k : 1;
      let scaleValue = (val, fallback) =>
        val ? val / getTransformK() : fallback / getTransformK();

      circles.attr('r', d => scaleValue(d.radius, radius));
      circles.attr('stroke-width', d => scaleValue(d.weight, 1));

      links.attr('stroke-width', d => scaleValue(d.lineWidth, 1));
      return groupAll.attr('transform', transform);
    });

    svg.call(zoom);

    this._zoom = zoom;

    let groupLinks = groupAll.append('g');
    let groupCircles = groupAll.append('g');

    let div = d3
      .select(this._svgContainer)
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    const simulation = d3
      .forceSimulation()
      .force(
        'link',
        d3.forceLink().id(d => d.index),
      )
      .force('charge', d3.forceManyBody().strength(-10))
      .velocityDecay(0.6) // default 0.4
      .alphaDecay(0.3) // default 0.0228
      .on('tick', ticked);

    let thisPasser = handler =>
      function() {
        handler(svg, that, this);
      };

    svg.on('touchstart', thisPasser(mousedown));
    svg.on('mousedown', thisPasser(mousedown));

    svg.on('touchmove', thisPasser(mousemove));
    svg.on('mousemove', thisPasser(mousemove));

    svg.on('touchend', thisPasser(mouseup));
    svg.on('mouseup', thisPasser(mouseup));
    // .on('mouseout', mouseout);

    let update = (nodesRaw, linksRaw) => {
      // groupAll.selectAll('*').remove();

      xScale.domain(d3.extent(nodesRaw, d => d.x));

      yScale.domain(d3.extent(nodesRaw, d => d.y));

      let nodes = nodesRaw.map(d => ({
        ...d,
        x: xScale(d.x),
        y: yScale(d.y),
      }));

      // prettier-ignore
      links = groupLinks.selectAll('line')
        .data(linksRaw)
      // .data([])

      links.exit().remove();

      // prettier-ignore
      links = links.enter().append('line')
      .merge(links)
        .attr('stroke', '#bbb')
        .attr('stroke-width', d => d.lineWidth || 0.75)

      // prettier-ignore
      circles = groupCircles.selectAll('circle')
        // .data(nodes.slice(0,1), d => this.props.graph + d.index)
      .data(nodes, d => this.props.graph  + d.index)

      circles
        .exit()
        .transition()
        .attr('opacity', 0)
        .remove();

      // prettier-ignore
      circles = circles.enter().append('circle')
      .merge(circles)
        .attr('r', d => d.radius || radius)
        .attr('fill', d => d.fillColor)
        .attr('stroke', '#e0e0e0')
        .attr('class', '')
        .on('mouseover', function(d) {
          if (that._shouldFade) {
          fade(.10)(d)
          }
          div
            .style('opacity', 0.8)
            .html(`<p>Attr: ${d.attr} </p><p>Data: ${d.data.join(', ')}</p>`)
            .style('top', function() {
              return d3.event.offsetY - 30 - this.offsetHeight + 'px';
            })
            .style('left', function() {
              return d3.event.offsetX - this.offsetWidth / 2 + 'px';
            });
        })
        .on('mouseout', function(d) {
          div.style('opacity', 0);
          if (that._shouldFade) {
          fade(1)(d)
          }
        })

      // build a dictionary of nodes that are linked
      var linkedByIndex = {};
      linksRaw.forEach(function(d) {
        linkedByIndex[d.source + ',' + d.target] = 1;
      });

      // check the dictionary to see if nodes are linked
      function isConnected(a, b) {
        return (
          linkedByIndex[a.index + ',' + b.index] ||
          linkedByIndex[b.index + ',' + a.index] ||
          a.index == b.index
        );
      }

      // fade nodes on hover
      function fade(opacity) {
        return function(d) {
          // check all other nodes to see if they're connected
          // to this one. if so, keep the opacity at 1, otherwise
          // fade
          circles
            .transition()
            .duration(250)
            .style('stroke-opacity', circle =>
              isConnected(d, circle) ? 1 : opacity,
            );
          circles
            .transition()
            .duration(250)
            .style('fill-opacity', circle =>
              isConnected(d, circle) ? 1 : opacity,
            );
          // also style link accordingly
          links
            .transition()
            .duration(250)
            .style('stroke-opacity', link =>
              link.source === d || link.target === d ? 1 : opacity,
            );
        };
      }

      // Update and restart the simulation.
      simulation.nodes(nodes);
      simulation.force('link').links(linksRaw);
      simulation.alpha(1).restart();
    };

    function ticked() {
      links
        // x1; y1; - point from source node
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        // x2; y2; - point to target node
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);
      circles
        // cx - circle center per x axis
        .attr('cx', d => d.x)
        // cy - circle center per y axis
        .attr('cy', d => d.y);
    }

    update(nodesRaw, linksRaw);

    this._update = update;
  };

  render() {
    return (
      <div
        ref={node => node && (this._commonContainer = node)}
        style={{ height: '100%' }}
      >
        <DraggableLabel themeMode={this.props.themeMode} />
        <div
          style={{
            position: 'relative',
          }}
          ref={node => node && (this._svgContainer = node)}
        >
          <SelectionFloatingButtons />
          <svg
            className="zoom"
            preserveAspectRatio="xMinYMid"
            id="svg"
            ref={node => node && (this._svg = node)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  themeMode: state.common.themeMode,
  graphType: state.graph.graphType,
  red: state.graph.red,
  blue: state.graph.blue,
  graphData: state.graph.graphData,
  // graphData: { metric, clustered },
  isDrawerLeftVisible: state.common.isDrawerLeftVisible,
  width: state.common.width,
  forceUpdate: state.flags.forceUpdate,
  coloringUpdate: state.flags.coloringUpdate,
});

export default connect(mapStateToProps)(ForceChart);
