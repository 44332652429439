import React, { useEffect, useState } from "react";
import { Switch, Toolbar, makeStyles, Tooltip, Divider } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import './ForceChartCanvasToolbar.css';
import clsx from 'clsx';
import { ThemeProvider, Theme } from "@material-ui/core/styles";
import { Actions } from 'actions';
import { useShallowSelector } from 'hooks';
import GraphListToolbarSection from "./toolbar-components/GraphListToolbarSection";
import { SelectionToolbarSection } from "./toolbar-components/SelectionToolbarSection";
import ColoringToolbarSection from "./toolbar-components/ColoringToolbarSection";
import { cloneDeep } from "lodash";
import BubbleChartIcon from '@material-ui/icons/BubbleChart';

interface ForceChartCanvasToolbarProps {
  eyeDropToolSelectionColor: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid'
  },
  divider: {
    height: '70%',
    marginRight: 5,
    marginLeft: 5
  },
  rightAlignedToolbarSection: {
    marginLeft: 'auto',
    marginRight: 0
  }
}));

const ForceChartCanvasToolbar = (props: ForceChartCanvasToolbarProps) => {
  const classes = useStyles();

  const isDrawerRightVisible = useShallowSelector(
    s => s.common.isDrawerRightVisible,
  );
  const initialGraphData = useShallowSelector(s => s.graph.initialGraphData);
  const coloringData = useShallowSelector(s => s.graph.coloringData);
  const isDrawerRightOpen = useShallowSelector(s => s.common.isDrawerRightOpen);
  const { eyeDropToolSelectionColor } = props;

  function toggleLayersDrawer() {
    if (isDrawerRightOpen) {
      const originalGraphData = cloneDeep(initialGraphData);
      const [chartData] = originalGraphData.charts;
      const [coloringChartData] = coloringData?.charts ?? [];

      Actions.graph.dispatch({
        graphData: {
          ...originalGraphData
        },
        chartData: coloringChartData || chartData,
        layers: [],
        currentLayerId: null,
        selectionColor: '',
      });
    }
    Actions.common.dispatch({ isDrawerRightOpen: !isDrawerRightOpen });
  }

  return (
    <Toolbar className={classes.root}>
      <GraphListToolbarSection />
      <Divider orientation="vertical" className={classes.divider} />
      {!isDrawerRightVisible ? (
        <>
          <ColoringToolbarSection />
          <Divider orientation="vertical" className={classes.divider} />
        </>
      ) : null}
      <SelectionToolbarSection eyeDropToolSelectionColor={eyeDropToolSelectionColor} />
      <Divider orientation="vertical" className={classes.divider} />
      <div
        className={classes.rightAlignedToolbarSection}
      >
        <Tooltip title="Communities" placement="top">
          <IconButton
            color={isDrawerRightOpen ? 'secondary' : 'inherit'}
            aria-label="Communities drawer"
            onClick={toggleLayersDrawer}
          >
            <BubbleChartIcon />
          </IconButton>
        </Tooltip>
      </div>
    </Toolbar>
  )
};
export default ForceChartCanvasToolbar;