import React, { useEffect } from 'react';

import { useParams, useLocation } from 'react-router-dom';

import { LoadingCircle } from 'components';
// import { useCurrentLayer } from 'reducers';
import { ForceChartCanvas, LayersDrawer } from 'containers';
import { useShallowSelector } from 'hooks';
import { GraphApi } from 'api';

import './styles.css';
import { Actions } from 'actions';

export function Graph() {
  const graphData = useShallowSelector(s => s.graph.graphData);
  const loading: any = useShallowSelector(s => s.graph.loading);
  const location = useLocation();
  const {
    portfolioId,
    studyId,
    fileId,
    datasetId,
    modelId,
    graphId,
  } = useParams();
  const allIds = { portfolioId, studyId, fileId, datasetId, modelId, graphId };

  useEffect(() => {
    clearPredictorsTableOnGraphChange();
    GraphApi.getGraph(allIds);
  }, [location]);

  useEffect(() => {
    return () => {
      Actions.graph.clear();
    };
  }, []);

  function clearPredictorsTableOnGraphChange() {
    Actions.predictors.clear();
  }

  return (
    <div className="main-wrapper">
      {loading || !graphData.metric ? <LoadingCircle /> : (
        <ForceChartCanvas />
      )}
    </div>
  );
}

export default Graph;
