import axios from 'axios';

let baseUrl = '';

if (process.env.REACT_APP_MODE === 'stage') {
  baseUrl = 'http://172.18.1.96:5000/api';
} else if (process.env.REACT_APP_MODE === 'prod') {
  // TODO: add prod config
  baseUrl = 'https://atlastda.com/api';
} else {
  baseUrl = 'http://172.18.1.95:5000/api';
}

axios.defaults.baseURL = baseUrl;

export const apiUrl = {
  graph: {
    getOptions: () => `/experiment-options/`,
    getData: () => `/experiment-data/`,
    getGraphByIndex: () => `/graph-by-index/`,
    getStatistic: () => `/statistic/`,
    getPredictorBarChart: () => `/predictor-chart/`,
    mergeCommunities: () => `/communities/merge/`,
    deleteLayer: () => `/communities/layers/`,
    copyLayer: () => `/communities/layers/copy/`,
    updateLayer: () => `/communities/layers/`,
    downloadLayerCommunities: () => `/communities/layers/download/`,
  },
  predictors: {
    getPredictors: () => `/analyzes/calculate/`,
  },
  users: {
    getUsers: () => `/users/`,
    createUser: () => `/users/`,
    deleteUsers: (userId: string) => `/users/${userId}/`,
    getUser: (userId: string) => `/users/${userId}/`,
    editUser: (userId: string) => `/users/${userId}/`,
    disableUser: (userId: string) => `/users/${userId}/disable/`,
    likeResource: () => '/users/like/',
  },
  portfolios: {
    createPortfolio: () => `/portfolios/`,
    listPortfolios: () => `/portfolios/list/`,
    deletePortfolio: () => `/portfolios/`,
    getPortfolioDetails: () => `/portfolios/detail/`,
    getPortfoliosTree: () => `/portfolios/entities-tree/`,
  },
  files: {
    createFile: () => `/files/`,
    deleteFile: () => `/files/`,
    listFile: () => `/files/list/`,
    getFileDetails: () => `/files/detail/`,
    uploadFile: () => `/files/upload/`,
  },
  models: {
    createModel: () => `/models/`,
    deleteModel: () => `/models/`,
    listModel: () => `/models/list/`,
    getModelDetails: () => `/models/detail/`,
    uploadModel: () => `/files/upload/`,
  },
  studies: {
    createStudy: () => `/studies/`,
    deleteStudy: () => `/studies/`,
    listStudy: () => `/studies/list/`,
    getStudyDetails: () => `/studies/detail/`,
    updateStudy: () => `/studies/`,
  },
  datasets: {
    createDataset: () => `/datasets/`,
    deleteDataset: () => `/datasets/`,
    listDatasets: () => `/datasets/list/`,
    getDatasetDetails: () => `/datasets/detail/`,
  },
  graphs: {
    createGraphs: () => `/graphs/`,
    updateCommunity: () => `/communities/layers/`,
    calculateGraph: () => `/graphs/calculate/`,
    listGraphs: () => `/graphs/list/`,
    getGraph: () => `/graphs/detail/`,
    listLayers: () => `/communities/layers/list/`,
    addLayer: () => `/communities/layers/`,
    getColoring: () => `/graphs/coloring/`,
  },
  auth: {
    login: () => `/login`,
    getBackEndUser: () => `/cognito/private`,
  },
  sqlQueries: {
    getFields: () => '/queries/predictors-data/',
    execute: () => '/queries/execute/',
    list: () => '/queries/list/',
    add: () => '/queries/',
    update: () => '/queries/',
    delete: () => '/queries/',
  },
  colorings: {
    list: () => '',
    getColoringData: () => ''
  }
};
