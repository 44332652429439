import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';

import { TextWrapper, Input } from 'components';

import './ModalAddPortfolio.css';

const ModalAddPortfolio = ({
  showModal,
  handleClose,
  handleAddPortfolio,
  state,
  setState,
}) => {
  const [deleteLoading, setDeleteLoading] = useState(false);

  async function handleDeleteProxy() {
    setDeleteLoading(true);
    await handleAddPortfolio();
    setDeleteLoading(false);
  }
  return (
    <Dialog
      fullWidth={false}
      maxWidth="md"
      open={showModal}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">Create Portfolio</DialogTitle>
      <br />
      <DialogContent style={{ width: '550px' }}>
        <div style={{ marginBottom: '50px' }}>
          <Input
            id="title"
            placeholder="New Portfolio"
            label="Title"
            that={{ setState, state }}
          />
          <Input
            id="additionalInfo"
            placeholder="This portfolio contains..."
            label="Description"
            that={{ setState, state }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '20px 0 10px',
          }}
        >
          <Button
            variant="contained"
            style={{ marginRight: '25px' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={deleteLoading}
            variant="contained"
            style={{ minWidth: '168px' }}
            color="primary"
            onClick={handleDeleteProxy}
          >
            {deleteLoading ? (
              <CircularProgress
                color="secondary"
                style={{ width: '20px', height: '20px' }}
              />
            ) : (
              'Create Portfolio'
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAddPortfolio;
