import { GraphState } from './types';

export const graphInitialState: GraphState = {
  loading: false,
  graphType: 'metric', // clustered
  graphData: {
    id: '',
    charts: [],
    studyId: '',
    datasetId: '',
    modelId: '',
    metric: null,
    clustered: null,
    chart: null
  }, // metric
  initialGraphData: {
    id: '',
    charts: [],
    studyId: '',
    datasetId: '',
    modelId: '',
    metric: null,
    clustered: null,
    chart: null
  },
  isOptionsOpen: true,
  layers: [],
  currentLayerId: null,
  list_colorings: [],
  predictorBarChart: {},
  options: null,
  isModalAddLayerOpen: false,
  selectionColor: '',
  coloring: '',
  balancedColoring: false,
  eyeDropToolActive: false,
  graphAggregatedInfo: {
    clusteredLinksCount: 0,
    clusteredNodesCount: 0,
    dataset: {
      title: '',
    },
    file: {
      title: '',
    },
    metricDefaultCommunitiesCount: 0,
    metricLinksCount: 0,
    metricNodesCount: 0,
    model: {
      title: '',
    },
    portfolio: {
      title: '',
    },
    study: {
      title: '',
    },
  },
  coloringModeCommunities: [null, null],
  selectedLayerCommunityIds: [],
  sqlQueryResult: []
};
