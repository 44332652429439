import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useRef } from 'react';

export default function useEffectDeepCompare(
  callback: React.EffectCallback,
  deps: React.DependencyList
) {
  if (deps.length === 0) {
    throw new Error('useEffectDeepCompare should not be used with no dependencies.');
  }
  const depsRef = useRef<React.DependencyList>(deps);
  const memoizedDeps = useMemo<React.DependencyList>(() => {
    const notEqual = !depsRef.current.every((item, index) => isEqual(item, deps[index]));
    if (notEqual) {
      depsRef.current = deps;
    }
    return depsRef.current;
  }, deps);

  useEffect(() => {
    callback();
  }, memoizedDeps);
}