export const authInitialState = {
  error: null,
  access_token: null,
};

export const commonInitialState = {
  locale: 'en',
  themeMode: 'light',
  drawerRightVariant: 'persistent',
  drawerLeftVariant: 'persistent',
  isDrawerRightOpen: false,
  isDrawerLeftOpen: false,
  isDrawerRightVisible: false,
  isDrawerRightResizeEnd: Symbol(),
  isDrawerRightResize: Symbol(),
  isDrawerLeftVisible: false,
  width: window.innerWidth,
  height: window.innerHeight,
  // currentPreset: layoutConfig['preset1'],
};

export const notificationsInitialState = {
  open: false,
  message: '',
  key: new Date().getTime(),
  vertical: 'top',
  horizontal: 'right',
  autoHideDuration: 4500,
  status: 'error',
};

export const predictorsInitialState = {
  statisticTable: [],
  predictorGroupsInfo: {
    groups: [],
    selectedGroups: []
  },
  //nodes that were used to request predictors calculation
  //so components will not need to mess with selection etc
  lastSelectedNodes: [],
  loading: false,
  autoOpen: false,
};

export const usersInitialState = {
  loading: false,
  users: [],
};

export const portfoliosInitialState = {
  loading: false,
  portfolios: [],
};

export const studiesInitialState = {
  loading: false,
  studies: [],
};

export const filesInitialState = {
  loading: false,
  files: [],
};

export const datasetsInitialState = {
  loading: false,
  datasets: [],
};

export const modelsInitialState = {
  loading: false,
  models: [],
};

export const graphsInitialState = {
  loading: false,
  graphs: [],
  pagination: {
    page: 0,
    perPage: 0,
    totalCount: 0,
  },
};

export const flagsInitialState = {
  showTools: false,
  forceUpdate: Symbol(),
  forceCanvasResize: Symbol(),
  barUpdate: Symbol(),
  predictorsUpdate: Symbol(),
  coloringUpdate: Symbol(),
  forceUpdateEnd: Symbol(),
};

export const sqlQueriesInitialState = {
  fields: {},
};
