import { Actions } from '../../actions';
import { apiUrl } from '../../config';

export class ModelApi {
  static createModel = data => {
    return Actions.models.POST({
      url: apiUrl.models.createModel(),
      data,
      mapData: response => {
        console.log(response);
        return {};
      },
    });
  };

  static listModels = (portfolioId, studyId, fileId, datasetId) => {
    return Actions.models.POST({
      url: apiUrl.models.listModel(),
      data: {
        filtering: {
          isDeleted: false,
        },
        // "pagination": {
        //   "page": 0,
        //   "perPage": 0
        // },
        portfolioId,
        studyId,
        fileId,
        datasetId,
        // "sorting": {
        //   "title": "string"
        // }
      },
      // mapData: response => {
      //   console.log(response);
      //   return {};
      // },
    });
  };

  static getModelDetails = allIds => {
    return Actions.models.POST({
      url: apiUrl.models.getModelDetails(),
      data: allIds,
    });
  };

  static deleteModel = allIds => {
    return Actions.models.DELETE({
      url: apiUrl.models.deleteModel(),
      data: { force: true, ...allIds },
    });
  };

  static uploadModel = formData => {
    return Actions.models.POST({
      url: apiUrl.models.uploadModel(),
      data: formData,
      headersCustom: h => {
        delete h['Content-Type'];
        return h;
      },
    });
  };
}
