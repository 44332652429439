import { store } from '../../index';

export const requestPending = ({ type, payload = {} }) =>
  store.dispatch({
    type: `${type}_PENDING`,
    payload,
  });

export const requestSuccess = ({ type, payload }) =>
  store.dispatch({
    type: `${type}_SUCCESS`,
    payload,
  });

export const requestFail = ({ type, err }) =>
  store.dispatch({
    type: `${type}_FAIL`,
    payload: { error: err },
  });
