import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

class TableToolbarAdjusted extends Component {
  render() {
    const addButtonComponent = this.props.showAddButton ? (
      <Button
        className={this.props.classes.button}
        onClick={this.props.onAddButtonClick}
        variant="outlined"
      >
        {this.props.addButtonItemName}
      </Button>
    ) : null;
    const deleteIcon = this.props.showDeleteButton ? (
      <Tooltip title="Delete">
        <IconButton aria-label="Delete">
          <DeleteIcon onClick={this.props.onDeleteClick} />
        </IconButton>
      </Tooltip>
    ) : null;

    const finalAddButtonComponent =
      this.props.customAddButtonComponent || addButtonComponent;
    const finalDeleteButtonComponent =
      this.props.customDeleteButtonComponent || deleteIcon;

    return (
      <Toolbar className={`${this.props.classes.root}`}>
        <div className={this.props.classes.title}>
          {this.props.numSelected > 0 ? (
            <Typography variant="h6">
              {this.props.numSelected} selected
            </Typography>
          ) : (
            <Typography variant="h6">{this.props.tableName}</Typography>
          )}
        </div>

        <div className={this.props.classes.spacer} />
        <div className={this.props.classes.actions}>
          {this.props.numSelected > 0
            ? finalDeleteButtonComponent
            : finalAddButtonComponent}
        </div>
      </Toolbar>
    );
  }
}

TableToolbarAdjusted.propTypes = {
  classes: PropTypes.object,
  numSelected: PropTypes.number,
  tableName: PropTypes.string,
  onAddButtonClick: PropTypes.func,
  addButtonItemName: PropTypes.string,
  onDeleteClick: PropTypes.func,
  showAddButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  customAddButtonComponent: PropTypes.any,
  customDeleteButtonComponent: PropTypes.any,
};

TableToolbarAdjusted.defaultProps = {
  tableName: '*please add tableName prop',
};

const toolbarStyles = theme => ({
  root: {
    padding: 0,
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    width: '200px',
  },
});

export default withStyles(toolbarStyles)(TableToolbarAdjusted);
