import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';

import { Actions } from 'actions';
import { useShallowSelector } from 'hooks';

import lassoImg from './lasso.png';
import './CommunityItem.css';

import { ColorPicker } from 'components';
import { CommunityData } from 'reducers';

interface CommunityItemProps {
  community: CommunityData;
  selected: boolean;
  onDelete(community: CommunityData): void;
  onChange(community: CommunityData): void;
  forbiddenColors: string[];
  isDefault: boolean;
  isOwned: boolean;
}

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(5),
  },
}));

const CommunityItem = ({
  community,
  selected,
  onDelete = (community) => {},
  onChange = (community) => {},
  forbiddenColors = [],
  isDefault,
  isOwned
}: CommunityItemProps) => {
  const classes = useStyles();
  const [newColor, setNewColor] = useState(community.color);
  const selectionColor = useShallowSelector(s => s.graph.selectionColor);
  const selectedLayerCommunityIds = useShallowSelector(s => s.graph.selectedLayerCommunityIds);
  const popperRef = useRef<any>(null);
  let communityTitle = community.title ? community.title : `${newColor}`;
  communityTitle = `${communityTitle} (${community.nodes.length})`;

  useEffect(() => {
    //this is the only found way to update tooltip position
    //when changes happen in parent component
    if (popperRef.current) {
      popperRef.current?.update();
    }
  });

  function updateColor(color: string) {
    if (!forbiddenColors.includes(color)) {
      setNewColor(color);
      community.hasChanges = true;
      community.color = color;

      onChange(community);

      Actions.graph.dispatch({
        selectionColor: color,
      });
    }
  }

  function updateChecked(checked: boolean) {
    if (checked) {
      Actions.graph.dispatch({
        selectedLayerCommunityIds: [...selectedLayerCommunityIds, community.id]
      });
    } else {
      Actions.graph.dispatch({
        selectedLayerCommunityIds: selectedLayerCommunityIds.filter(id => (id !== community.id))
      });
    }
  }

  function toggleSelection(color: string) {
    Actions.graph.dispatch({
      selectionColor: selectionColor ? '' : color,
    });
  }

  function deleteCommunity() {
    Actions.graph.dispatch({
      selectionColor: '',
    });
    onDelete(community);
  }

  return (
    <ListItem className={classes.nested}>
      <Tooltip
        open={Boolean(community.hasChanges)}
        placement="left"
        title="not saved"
        PopperProps={{
          popperRef
        }}
      >
        <Checkbox
          checked={selected}
          onChange={e => {
            e.stopPropagation();
            updateChecked(e.target.checked);
          }}
          style={{
            color: newColor
          }}
          color="primary"
          disabled={community.nodes.length === 0}
        />
      </Tooltip>
      <ListItemText
        style={{ color: newColor }}
        primary={communityTitle}
      />
      {!isDefault && isOwned && (
        <IconButton
          onClick={() => toggleSelection(community.color)}
          style={{
            backgroundColor:
              selectionColor === newColor ? selectionColor : 'transparent',
          }}
        >
          <span className="lasso-img-wrapper">
            <img
              src={lassoImg}
              style={{
                width: '20px',
                height: '20px',
              }}
            />
          </span>
        </IconButton>
      )}
      {!isDefault && isOwned && (
        <ColorPicker
          initialColor={newColor}
          handleColorChange={color => updateColor(color)}
          forbiddenColors={forbiddenColors}
        />
      )}
      {!isDefault && isOwned && (
        <IconButton
          onClick={deleteCommunity}
        >
          <DeleteIcon style={{ fontWeight: 'bold' }} />
        </IconButton>
      )}
    </ListItem>
  );
};

export default CommunityItem;
