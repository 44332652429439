import { Actions } from '../../actions';
import { apiUrl } from '../../config';
import cloneDeep from 'lodash/cloneDeep';
import { GraphColoringData, GraphListAPIResponse, GraphListItemData } from 'reducers/graph/types';

export class GraphApi {
  static getGraph = (data: any) => {
    return Actions.graph.POST({
      url: apiUrl.graphs.getGraph(),
      data,
      mapData: ({ data }) => {
        const [defaultColoring] = data.coloring.filter(({ isDefault }: any) => isDefault);
        const [chartData] = data.charts;
        const {
          id,
          datasetId,
          modelId,
          studyId,
          metric,
          clustered,
          graphAggregatedInfo
        } = data;

        return {
          coloring: defaultColoring.title,
          coloringData: undefined,
          graphData: {
            id,
            datasetId,
            modelId,
            studyId,
            metric,
            clustered,
          },
          chartData,
          initialGraphData: cloneDeep(data),
          list_colorings: data?.coloring ?? [],
          graphAggregatedInfo,
          selectedLayerCommunityIds: [],
          coloringModeCommunities: []
        };
      },
    });
  };

  static getPredictors = (data: any) => {
    const { selections: lastSelectedNodes } = data;
    return Actions.predictors.POST({
      url: apiUrl.predictors.getPredictors(),
      data,
      mapData: ({ data }) => {
        // let statisticTable: any = [];
        // console.log(data);

        // data.predictor.forEach((d: any, i: number) =>
        //   statisticTable.push({
        //     predictor: data.predictor[i],
        //     pvalue: data.pvalue[i],
        //     type: data.type[i],
        //   }),
        // );
        // return { statisticTable };
        const { items, predictorGroupsInfo } = data;
        return {
          statisticTable: items,
          predictorGroupsInfo,
          lastSelectedNodes
        };
      },
    });
  };

  static getColoring = (data: any, graphData: any) => {
    return Actions.graphs
      .POST({
        url: apiUrl.graphs.getColoring(),
        data,
      })
      .then(({ data }: { data: GraphColoringData }) => {
        const [chartData] = data.charts;
        Actions.graph.dispatch({
          chartData,
          coloringData: data
        });
      });
  };

  static createGraph = (data: any) => {
    return Actions.graphs.POST({
      url: apiUrl.graphs.createGraphs(),
      data,
      mapData: response => {
        console.log(response);
        return {};
      },
    });
  };

  static updateCommunity = (data: any) => {
    return Actions.req.PATCH({
      url: apiUrl.graphs.updateCommunity(),
      data,
    });
  };

  static calculateGraph = (data: any) => {
    return Actions.graphs.POST({
      url: apiUrl.graphs.calculateGraph(),
      data,
    });
  };

  static mergeCommunities = (data: any) => {
    return Actions.graphs.POST({
      url: apiUrl.graph.mergeCommunities(),
      data,
    });
  };

  static listGraphs = (
    data: any, page: any = null, perPage: any = null, sortingSettings: any = null
  ): Promise<{ data: GraphListAPIResponse }> => {
    const pagination = (page && perPage) ? {
      pagination:  {
        page: page,
        perPage: perPage,
      },
    } : {};
    const sorting = sortingSettings ? {
      sorting: sortingSettings
    } : {};
    return Actions.graphs.POST({
      url: apiUrl.graphs.listGraphs(),
      data: {
        ...data,
        // filtering: {
        //   isDeleted: false,
        // },
        ...pagination,
        ...sorting,
        // "sorting": {
        //   "title": "string"
        // }
        lightList: true,
      },
      mapData: ({ data: { items: graphs, pagination } }) => ({
        graphs,
        'pagination.totalCount': pagination[0]?.totalCount ?? 5,
      }),
    });
  };

  static changeGraphFavoriteStatus = (data: any) => {
    return Actions.graphs.POST({
      url: apiUrl.users.likeResource(),
      data,
    });
  };

  static changeColoringFavoriteStatus = (data: any) => {
    return Actions.graphs.POST({
      url: apiUrl.users.likeResource(),
      data,
    });
  };

  static listLayers = (data: any, page: any = null, perPage: any = null) => {
    const pagination = (page && perPage) ? {
      pagination:  {
        page: page,
        perPage: perPage,
      },
    } : {};

    //Actions.graph.dispatch({ coloring: '', balancedColoring: false });

    return Actions.graphs.POST({
      url: apiUrl.graphs.listLayers(),
      data: {
        ...data,
        // filtering: {
        //   isDeleted: false,
        // },
        ...pagination,
        // "sorting": {
        //   "title": "string"
        // }
      },
      // mapData: ({ data: { items: graphs, pagination } }) => ({
      //   graphs,
      //   'pagination.totalCount': pagination[0]?.totalCount ?? 5,
      // }),
    });
  };

  static addLayer = (data: any) => {
    return Actions.graphs.POST({
      url: apiUrl.graphs.addLayer(),
      data,
    });
  };

  static deleteLayer = (data: any) => {
    return Actions.graphs.DELETE({
      url: apiUrl.graph.deleteLayer(),
      data,
    });
  };

  static copyLayer = (data: any) => {
    return Actions.graphs.POST({
      url: apiUrl.graph.copyLayer(),
      data,
    });
  };

  static updateLayer = (data: any) => {
    return Actions.graphs.PATCH({
      url: apiUrl.graph.updateLayer(),
      data,
    });
  };

  static downloadLayerCommunities = (data: any) => {
    return Actions.graphs.POST({
      url: apiUrl.graph.downloadLayerCommunities(),
      data,
    });
  }
}
