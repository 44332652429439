import React, { useEffect, useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Brightness4 from '@material-ui/icons/Brightness4';
import grey from '@material-ui/core/colors/grey';
import Tooltip from '@material-ui/core/Tooltip';

import './Header.css';

import { TextWrapper } from 'components';
import { Actions } from 'actions';
import { pageUrl, history } from 'config';
import { notify } from 'utils';
import { useShallowSelector } from 'hooks';
import HeaderMobileControls from './HeaderMobileControls/HeaderMobileControls';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: grey[900],
  },
  menuRightButton: {
    marginLeft: 20,
    marginRight: -12,
  },
});

function Header({ classes }) {
  const themeMode = useShallowSelector(s => s.common.themeMode);

  function handleToggleTheme() {
    Actions.common.dispatch({
      themeMode: themeMode === 'light' ? 'dark' : 'light',
    });
  }

  async function logout() {
    try {
      Actions.auth.clear();
      Actions.graph.clear();
      Actions.flags.clear();
    } catch (err) {
      console.log(err);
      notify({ title: 'Note!', message: err.message });
    }
  }

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(pageUrl.main())}
          >
            <TextWrapper variant="h5" color="inherit">
              Atlas TDA
            </TextWrapper>
          </div>
          <div className={classes.grow} />
          {/* section of ICON BUTTONS */}

          <Tooltip title="Theme" placement="top">
            <IconButton color="inherit" onClick={handleToggleTheme}>
              <Brightness4 />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout" placement="top">
            <IconButton color="inherit" onClick={logout}>
              <ExitToApp />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <HeaderMobileControls />
    </React.Fragment>
  );
}

export default withStyles(styles)(Header);
