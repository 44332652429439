import React, { useState, useEffect } from 'react';
import capitalize from 'lodash/capitalize';

import { TextWrapper } from 'components';

import './PredictorsBarColorIndicator.css';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface ChartInfo {
  label: string,
  color: string,
  visible: boolean;
}

interface PredictorsBarColorIndicatorProps {
  chartsInfo: ChartInfo[],
  onChartsVisibilityChange(newVisibility: {
    chartsVisibility: boolean[],
    rugsVisibility: boolean,
    kdePointsVisibility: boolean
  }): void;
  showRugsAndKdeOptions?: boolean
}

export function PredictorsBarColorIndicator(props: PredictorsBarColorIndicatorProps) {
  const { chartsInfo, onChartsVisibilityChange, showRugsAndKdeOptions = true } = props;
  const [ chartsVisibility, setChartsVisibility ] = useState(chartsInfo.map(({ visible }) => visible));
  const [ rugsVisibility, setRugsVisibility ] = useState(true);
  const [ kdePointsVisibility, setKdePointsVisibility ] = useState(true);

  useEffect(() => {
    onChartsVisibilityChange({
      chartsVisibility,
      rugsVisibility,
      kdePointsVisibility
    });
  }, [chartsVisibility, rugsVisibility, kdePointsVisibility]);

  function toggleChartVisibility(targetIndex: number) {
    const newChartsVisibilityState = chartsVisibility.map((visible, index) => {
      if (index === targetIndex) {
        return !visible;
      }
      return visible;
    });
    const canToggle = newChartsVisibilityState.some(visible => visible);
    if (canToggle) {
      setChartsVisibility(newChartsVisibilityState);
    }
  }

  return (
    <div
      style={{
        margin: '0 7px 0',
        flex: 'none',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {chartsInfo.map(({ label, color }, index) => (
        <FormControlLabel
          key={color}
          control={
            <Checkbox
              checked={chartsVisibility[index]}
              onChange={e => {
                toggleChartVisibility(index);
              }}
              style={{
                color
              }}
              color="default"
            />
          }
          label={label}
        />
      ))}
      {showRugsAndKdeOptions ? (
        <div style={{
          marginLeft: 'auto'
        }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={rugsVisibility}
                onChange={e => {
                  setRugsVisibility(!rugsVisibility)
                }}
                color="default"
              />
            }
            label="Rugs"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={kdePointsVisibility}
                onChange={e => {
                  setKdePointsVisibility(!kdePointsVisibility)
                }}
                color="default"
              />
            }
            label="KDE Points"
          />
        </div>
      ): null}
    </div>
  );
}

export default PredictorsBarColorIndicator;
