import React, { useState, SyntheticEvent, useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import pink from '@material-ui/core/colors/pink';

import { Loading } from 'components';
import { PredictorsBarChart } from 'containers';
import { Actions } from 'actions';
import { useCurrentLayer, IAppStore, CommunityData } from 'reducers';
import { useShallowSelector, usePrevious } from 'hooks';
import { GraphApi } from 'api';

import './PredictorsTable.css';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckIcon from '@material-ui/icons/Check';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoIcon from '@material-ui/icons/Info';
import { Menu, MenuItem, ListItemIcon, Typography, Divider, TableHead, TableRow, TableSortLabel, TableCell, Table, TableBody, Button } from '@material-ui/core';
import clsx from 'clsx';

interface PredictorsTableComponentProps {
}

interface Data {
  groupName: string | null;
  predictor: string;
  pvalue: string;
  dataType: string;
  definition: string;
  statistic: string | null;
  testName: string;
  pinned: boolean;
}

type SortableColumn = Exclude<keyof Data, 'pinned'>;

interface PredictorsTableHeadProps {
  activeColumns: SortableColumn[];
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: SortableColumn) => void;
  sort: TableSort;
  isFilterApplied: boolean;
  onFilterPredictorsByGroupClick: (e: React.MouseEvent<HTMLElement>) => void;
}

type SortItem = [SortableColumn, ('asc' | 'desc')];
type TableSort = SortItem | null;//no multi-sort

interface HeadCell {
  id: SortableColumn;
  label: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  optional: boolean;
}

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    overflow: 'auto',
    height: 'calc(100vh - 184px)'
  },
  headerPredictorsActions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  headerLabel: {
    marginRight: 'auto'
  },
  predictorRow: {
    cursor: 'pointer'
  },
  predictorCell: {
    whiteSpace: 'nowrap'
  },
  selectedPredictorCell: {
    fontWeight: 'bold'
  },
  selectedPredictorRowLight: {
    background: '#e8eaf6',
  },
  selectedPredictorRowDark: {
    background: '#1e88e5',
  },
  predictorsActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  predictorsActionsLabel: {
    marginRight: 'auto',
    fontWeight: 'bold',
    paddingLeft: 16
  },
  checkboxMenuItemIcon: {
    minWidth: 32
  },
}));

const headCells: HeadCell[] = [
  { id: 'groupName', label: 'Group', align: 'left', optional: false },
  { id: 'predictor', label: 'Predictor', align: 'left', optional: false },
  { id: 'pvalue', label: 'PValue', align: 'left', optional: false },
  { id: 'dataType', label: 'Type', align: 'left', optional: true },
  { id: 'statistic', label: 'Statistic', align: 'left', optional: true },
  { id: 'testName', label: 'Test', align: 'left', optional: true },
];

const optionalColumns = headCells.filter(headCell => headCell.optional).map(headCell => headCell.id);

const getSortDirection = (currentSort: TableSort, fieldName: string) => {
  if (currentSort) {
    const [id, direction] = currentSort;
    if (id === fieldName) {
      return direction;
    }
  }
  return undefined;
};

const isSortActive = (currentSort: TableSort, fieldName: string) => {
  if (currentSort) {
    const [id] = currentSort;
    return id === fieldName;
  }
  return false;
};

const infinityAwareStringToNumberConversion = (value: string) => {
  if (value === 'inf') {
    return Infinity;
  } else if (value === '-inf') {
    return -Infinity;
  }
  return Number(value);
}

function PredictorsTableHead(props: PredictorsTableHeadProps) {
  const { activeColumns, classes, sort = null, isFilterApplied, onRequestSort, onFilterPredictorsByGroupClick } = props;
  const createSortHandler = (property: SortableColumn) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const removePaddingTopBottom = activeColumns.includes('groupName');

  return (
    <TableHead >
      <TableRow>
        {headCells.map((headCell) => {
          if (!activeColumns.includes(headCell.id)) {
            return null;
          }
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              style={{
                //paddingLeft: headCell.id === 'predictor' ? 0 : '',
                paddingTop: removePaddingTopBottom ? 0 : '',
                paddingBottom: removePaddingTopBottom ? 0 : ''
              }}
            >
              <div style={{display: 'flex'}}>
                <TableSortLabel
                  active={isSortActive(sort, headCell.id)}
                  direction={getSortDirection(sort, headCell.id)}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
                { headCell.id === 'groupName' ? (
                  <Tooltip title="Filter predictors by group" placement="top">
                    <div>
                      <IconButton onClick={onFilterPredictorsByGroupClick} style={{
                          color: isFilterApplied ? pink[500] : ''
                      }}>
                        <FilterListIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                ) : null}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

function PredictorsTable(props: PredictorsTableComponentProps) {
  const { portfolioId, studyId, fileId, datasetId, modelId, graphId } = useParams();
  const allIds = { portfolioId, studyId, fileId, datasetId, modelId, graphId };
  const graphType: any = useShallowSelector(s => s.graph.graphType);

  const statisticTable: Data[] = useSelector(
    s => (s as any).predictors.statisticTable,
  );
  const predictorGroupsInfo: any = useSelector(
    s => (s as any).predictors.predictorGroupsInfo,
  );
  const lastSelectedNodes: any = useSelector(
    s => (s as any).predictors.lastSelectedNodes,
  );

  const loading = useShallowSelector(s => s.predictors.loading);
  const themeMode = useShallowSelector(s => s.common.themeMode);

  const [predictorsTableSorted, setPredictorsTableSorted] = useState<Data[]>([]);
  const [tableSort, setTableSort] = React.useState<TableSort>(null);

  const { groups, selectedGroups, totalPredictorsNumber } = predictorGroupsInfo;
  const previousSelectedGroups = usePrevious(selectedGroups);
  const [selectedGroupsMenuState, setSelectedGroupsMenuState] = useState<{
    menuAnchorEl: null | HTMLElement,
    selectedGroups: string[]
  }>({
    menuAnchorEl: null,
    selectedGroups: []
  });
  const isFilterApplied = selectedGroups.length !== groups.length;
  const filterPredictorsGroupsMenuOpen = Boolean(selectedGroupsMenuState.menuAnchorEl);

  const [selectedOptionalColumnsMenuState, setSelectedOptionalColumnsMenuState] = useState<{
    menuAnchorEl: null | HTMLElement,
    selectedColumns: SortableColumn[]
  }>({
    menuAnchorEl: null,
    selectedColumns: []
  });
  const selectedColumnsMenuOpen = Boolean(selectedOptionalColumnsMenuState.menuAnchorEl);
  const activeColumns: SortableColumn[] = ['groupName', 'predictor', 'pvalue', ...selectedOptionalColumnsMenuState.selectedColumns];

  if (groups.length === 0) {
    activeColumns.shift();
  }
  const [selectionOptionsMenuAnchorEl, setSelectionOptionsMenuAnchorEl] = useState<null | HTMLElement>(null);
  const selectionOptionsMenuOpen = Boolean(selectionOptionsMenuAnchorEl);
  const classes = useStyles();

  useEffect(() => {
    if (tableSort) {
      const [id, direction] = tableSort;
      const sortFunction = (a: Data, b: Data) => {
        switch (id) {
          case 'groupName': {
            const group1 = a.groupName ?? '';
            const group2 = b.groupName ?? '';
            if (direction === 'asc') {
              return group1.localeCompare(group2);
            }
            return group2.localeCompare(group1);
          }
          case 'predictor': {
            if (direction === 'asc') {
              return a.predictor.localeCompare(b.predictor);
            }
            return b.predictor.localeCompare(a.predictor);
          }
          case 'pvalue': {
            const value1 = infinityAwareStringToNumberConversion(a.pvalue);
            const value2 = infinityAwareStringToNumberConversion(b.pvalue);
            if (direction === 'asc') {
              return value1 - value2;
            }
            return value2 - value1;
          }
          case 'dataType': {
            if (direction === 'asc') {
              return a.dataType.localeCompare(b.dataType);
            }
            return b.dataType.localeCompare(a.dataType);
          }
          case 'statistic': {
            const value1 = a.statistic ? infinityAwareStringToNumberConversion(a.statistic) : NaN;
            const value2 = b.statistic ? infinityAwareStringToNumberConversion(b.statistic) : NaN;
            if (direction === 'asc') {
              return value1 - value2;
            }
            return value2 - value1;
          }
          case 'testName': {
            if (direction === 'asc') {
              return a.testName.localeCompare(b.testName);
            }
            return b.testName.localeCompare(a.testName);
          }
        }
        return 0;
      };

      switch (id) {
        case 'statistic':
        case 'pvalue': {
          const { numbersChunk, nanChunk } = statisticTable.reduce<{numbersChunk: Data[], nanChunk: Data[]}>((chunks, data) => {
            const originalValue = data[id];
            const coercedValue = Number(originalValue);

            if (originalValue !== 'inf' && originalValue !== '-inf' && Number.isNaN(coercedValue)) {
              chunks.nanChunk.push(data);
            } else {
              chunks.numbersChunk.push(data);
            }
            return chunks;
          }, {
            numbersChunk: [],
            nanChunk: []
          });
          numbersChunk.sort(sortFunction);
          setPredictorsTableSorted([...numbersChunk, ...nanChunk]);
          break;
        }
        default: {
          const statisticTableSorted = [...statisticTable].sort(sortFunction);
          setPredictorsTableSorted(statisticTableSorted);
        }
      }
    } else {
      setPredictorsTableSorted([...statisticTable]);
    }
  }, [statisticTable, tableSort]);

  useEffect(() => {
    if (previousSelectedGroups && previousSelectedGroups.length > 0) {
      //refresh predictors table
      GraphApi.getPredictors({
        ...allIds,
        graphType,
        selections: lastSelectedNodes,
        groupNames: selectedGroups
      });
    }
  }, [selectedGroups.join('-')]);

  function handlePredictorClick(e: SyntheticEvent, index: number) {
    const { predictor: targetPredictor } = predictorsTableSorted[index];

    e.stopPropagation();
    Actions.predictors.dispatch(({ statisticTable }) => {
      const newStatTable = statisticTable.map((row: any) => ({
        ...row,
        pinned: row.predictor === targetPredictor ? !row.pinned : row.pinned
      }));
      return { statisticTable: newStatTable };
    });
  }

  function onOpenFilterPredictorsMenuClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setSelectedGroupsMenuState({
      selectedGroups: selectedGroups.slice(),
      menuAnchorEl: e.currentTarget
    });
  };

  function onOpenOptionalColumnsMenuClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setSelectedOptionalColumnsMenuState({
      ...selectedOptionalColumnsMenuState,
      menuAnchorEl: e.currentTarget
    });
  };

  function onOpenSelectionOptionsMenuClick(e: React.MouseEvent<HTMLElement>) {
    setSelectionOptionsMenuAnchorEl(e.currentTarget);
  }

  function handlePredictorsGroupsMenuClose(applyChanges: boolean) {
    if (applyChanges) {
      const newSelectedGroups = selectedGroupsMenuState.selectedGroups.slice();
      Actions.predictors.dispatch({
        predictorGroupsInfo: {
          ...predictorGroupsInfo,
          selectedGroups: newSelectedGroups
        }
      });
    }
    setSelectedGroupsMenuState({
      ...selectedGroupsMenuState,
      menuAnchorEl: null
    });
  };

  function handleSelectedColumnsMenuClose() {
    setSelectedOptionalColumnsMenuState({
      ...selectedOptionalColumnsMenuState,
      menuAnchorEl: null
    });
  };

  function handleSelectionOptionsMenuClose() {
    setSelectionOptionsMenuAnchorEl(null);
  }

  function selectAllPredictorsGroups() {
    setSelectedGroupsMenuState({
      ...selectedGroupsMenuState,
      selectedGroups: groups.slice()
    });
  }

  function toggleAllOptionalTableColumns() {
    if (selectedOptionalColumnsMenuState.selectedColumns.length !== optionalColumns.length) {
      setSelectedOptionalColumnsMenuState({
        ...selectedOptionalColumnsMenuState,
        selectedColumns: optionalColumns.slice()
      });
    } else {
      setSelectedOptionalColumnsMenuState({
        ...selectedOptionalColumnsMenuState,
        selectedColumns: []
      });
    }
  }

  function togglePredictorsGroup(groupName: string) {
    let newSelectedGroups = selectedGroupsMenuState.selectedGroups.slice();
    if (newSelectedGroups.includes(groupName)) {
      newSelectedGroups = newSelectedGroups.filter((selectedGroup: string) => selectedGroup !== groupName);
    } else {
      newSelectedGroups.push(groupName);
    }
    newSelectedGroups.sort();
    if (newSelectedGroups.length > 0) {
      setSelectedGroupsMenuState({
        ...selectedGroupsMenuState,
        selectedGroups: newSelectedGroups
      });
    }
  }

  function toggleOptionalColumn(columnName: SortableColumn) {
    let newSelectedOptionalColumns = selectedOptionalColumnsMenuState.selectedColumns.slice();
    if (newSelectedOptionalColumns.includes(columnName)) {
      newSelectedOptionalColumns = newSelectedOptionalColumns.filter((selectedGroup: string) => selectedGroup !== columnName);
    } else {
      newSelectedOptionalColumns.push(columnName);
    }
    setSelectedOptionalColumnsMenuState({
      ...selectedOptionalColumnsMenuState,
      selectedColumns: newSelectedOptionalColumns
    });
  }

  function selectAllPredictors() {
    Actions.predictors.dispatch(({ statisticTable }) => {
      const newStatTable = statisticTable.map((row: any) => ({
        ...row,
        pinned: true
      }));
      return { statisticTable: newStatTable };
    });
  }

  function unselectAllPredictors() {
    Actions.predictors.dispatch(({ statisticTable }) => {
      const newStatTable = statisticTable.map((row: any) => ({
        ...row,
        pinned: false
      }));
      return { statisticTable: newStatTable };
    });
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, fieldName: SortableColumn) => {
    const direction = getSortDirection(tableSort, fieldName);
    const oppositeDirection = direction === 'asc'? 'desc' : 'asc';
    setTableSort([fieldName, oppositeDirection]);
  };

  return (
    <div style={{ margin: '8px', width: 'calc(100% - 16px)' }}>
      <div className={classes.predictorsActions}>
        <span className={classes.predictorsActionsLabel}>
          Calculated predictors
          { loading ? null : (
            ` (${predictorsTableSorted.length} of ${totalPredictorsNumber})`
          )}
        </span>
        <span>
          <Tooltip title="Select optional table columns" placement="top">
            <div>
              <IconButton
                onClick={onOpenOptionalColumnsMenuClick}
                disabled={loading}
              >
                <ViewColumnIcon />
              </IconButton>
            </div>
          </Tooltip>
        </span>
        <span>
          <IconButton onClick={onOpenSelectionOptionsMenuClick} disabled={loading}>
            <MoreVertIcon />
          </IconButton>
        </span>
      </div>
      <Divider />
      <div
        className={classes.tableWrapper}
      >
        {loading ? (
          <Loading />
        ): (
          <Table stickyHeader>
            <PredictorsTableHead
              activeColumns={activeColumns}
              classes={classes}
              sort={tableSort}
              onRequestSort={handleRequestSort}
              isFilterApplied={isFilterApplied}
              onFilterPredictorsByGroupClick={onOpenFilterPredictorsMenuClick}
            />
            <TableBody>
              { predictorsTableSorted.map((row, index) => {
                const {
                  groupName,
                  predictor,
                  pvalue,
                  dataType,
                  statistic,
                  pinned,
                  testName,
                  definition
                } = row;
                const rowClassName = clsx(classes.predictorRow, pinned ? (
                  themeMode === 'light' ? classes.selectedPredictorRowLight : classes.selectedPredictorRowDark
                ) : '');

                return (
                  <TableRow
                    hover
                    className={rowClassName}
                    key={predictor}
                    onClick={(e: any) => handlePredictorClick(e, index)}
                  >
                    { activeColumns.map(column => {
                        switch (column) {
                          case 'groupName': {
                            return (
                              <TableCell key={column}>
                                {groupName}
                              </TableCell>
                            );
                          }
                          case 'predictor': {
                            return (
                              <TableCell
                                className={clsx(classes.predictorCell, pinned ? classes.selectedPredictorCell : '')}
                                //padding="none"
                                key={column}
                              >
                                <Tooltip title={definition} placement="top">
                                  <span>{predictor}</span>
                                </Tooltip>
                              </TableCell>
                            );
                          }
                          case 'pvalue': {
                            return (
                              <TableCell key={column}>
                                {pvalue}
                              </TableCell>
                            );
                          }
                          case 'dataType': {
                            return (
                              <TableCell key={column}>
                                {dataType}
                              </TableCell>
                            );
                          }
                          case 'statistic': {
                            return (
                              <TableCell key={column}>
                                {statistic}
                              </TableCell>
                            );
                          }
                          case 'testName': {
                            return (
                              <TableCell key={column}>
                                {testName}
                              </TableCell>
                            );
                          }
                        }
                      })
                    }
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>

      <Menu
        id="filter-by-group-menu"
        getContentAnchorEl={null}
        anchorEl={selectedGroupsMenuState.menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={filterPredictorsGroupsMenuOpen}
        onClose={() => {
          handlePredictorsGroupsMenuClose(false)
        }}
      >
        <MenuItem onClick={() => {
          selectAllPredictorsGroups();
        }}>
          <ListItemIcon className={classes.checkboxMenuItemIcon}>
            <CheckIcon fontSize="small" style={{
              visibility: selectedGroupsMenuState.selectedGroups.length === groups.length ? 'visible' : 'hidden'
            }} />
          </ListItemIcon>
          <Typography variant="inherit">All groups</Typography>
        </MenuItem>
        <Divider />
        {groups.map((groupName: string) => (
          <MenuItem
            onClick={() => {
              togglePredictorsGroup(groupName);
            }}
            key={groupName}
          >
            <ListItemIcon className={classes.checkboxMenuItemIcon}>
              <CheckIcon fontSize="small" style={{
                visibility: selectedGroupsMenuState.selectedGroups.includes(groupName) ? 'visible' : 'hidden'
              }} />
            </ListItemIcon>
            <Typography variant="inherit">{groupName}</Typography>
          </MenuItem>
        ))}
        <Divider />
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            margin: '16px 48px 16px',
          }}
        >
          <Button
            variant="contained"
            style={{ marginRight: '32px' }}
            onClick={() => handlePredictorsGroupsMenuClose(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePredictorsGroupsMenuClose(true)}
          >
            Apply
          </Button>
        </div>
      </Menu>
      <Menu
        id="columns-menu"
        getContentAnchorEl={null}
        anchorEl={selectedOptionalColumnsMenuState.menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={selectedColumnsMenuOpen}
        onClose={handleSelectedColumnsMenuClose}
      >
        <MenuItem onClick={() => {
          toggleAllOptionalTableColumns();
        }}>
          <ListItemIcon className={classes.checkboxMenuItemIcon}>
            <CheckIcon fontSize="small" style={{
              visibility: selectedOptionalColumnsMenuState.selectedColumns.length === optionalColumns.length ? 'visible' : 'hidden'
            }} />
          </ListItemIcon>
          <Typography variant="inherit">All columns</Typography>
        </MenuItem>
        <Divider />
        {optionalColumns.map((columnName: SortableColumn) => {
          const [ headCell ] = headCells.filter(headCell => headCell.id === columnName);
          const columnLabel = headCell ? headCell.label : '';
          return (
            <MenuItem
              onClick={() => {
                toggleOptionalColumn(columnName);
              }}
              key={columnName}
            >
              <ListItemIcon className={classes.checkboxMenuItemIcon}>
                <CheckIcon fontSize="small" style={{
                  visibility: selectedOptionalColumnsMenuState.selectedColumns.includes(columnName) ? 'visible' : 'hidden'
                }} />
              </ListItemIcon>
              <Typography variant="inherit">{columnLabel}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
      <Menu
        id="selection-menu"
        getContentAnchorEl={null}
        anchorEl={selectionOptionsMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={selectionOptionsMenuOpen}
        onClose={handleSelectionOptionsMenuClose}
      >
        <MenuItem onClick={() => {
          selectAllPredictors();
          handleSelectionOptionsMenuClose();
        }}>
          <Typography variant="inherit">Select All</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          unselectAllPredictors();
          handleSelectionOptionsMenuClose();
        }}>
          <Typography variant="inherit">Unselect All</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default PredictorsTable;
