import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import omit from 'lodash/omit';
import pink from '@material-ui/core/colors/pink';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckIcon from '@material-ui/icons/Check';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import {
  ModalAddLayer,
  ModalDeleteLayer,
  ModalCopyLayer,
} from './components';
import { /*Drawer, */InfiniteScroll, Modal, LoadingCircle } from 'components';
import Drawer from '@material-ui/core/Drawer';
import { Actions } from 'actions';
import { GraphApi } from 'api';
import {
  useCurrentLayer,
  LayerData,
  LayersList,
  GraphState,
  CommunityData,
} from 'reducers';
import { useShallowSelector } from 'hooks';
import {
  DEFAULT_NODE_COLOR,
  paintGraphByLayerColors,
} from 'utils';

import './LayersDrawer.css';
import SharedIcon from 'components/CustomIcons/SharedIcon';
import { BottomNavigation, BottomNavigationAction, Menu, MenuItem, Typography, ListItemIcon, Checkbox, Divider } from '@material-ui/core';
import LayerItem from './components/LayerItem/LayerItem';
import { useSelector } from 'react-redux';

const DRAWER_WIDTH_EXPANDED = 350;

const useStyles = makeStyles(theme => ({
  layersList: {
    //width: '100%',
    width: DRAWER_WIDTH_EXPANDED,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 128px)',
  },
  layerNameWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  layerNameIcon: {
    fontSize: 18,
    marginRight: 5
  },
  globalLayerActions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  globalLayerActionsLabel: {
    marginRight: 'auto'
  },
  filterLayersMenuItemIcon: {
    minWidth: 32
  },
  noLeftPadding: {
    paddingLeft: 0,
  },

  drawer: {
    width: DRAWER_WIDTH_EXPANDED,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    position: 'relative',
    width: DRAWER_WIDTH_EXPANDED,
  },

  drawerOpen: {
    width: DRAWER_WIDTH_EXPANDED,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerCollapsed: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6),
  },
  drawerClosed: {
    width: 'auto',
  },
}));

interface FilterLayerSettings {
  showPublic: boolean;
  showPrivate: boolean;
  showShared: boolean;
}

const StyledCircle = () => {
  return (
    <CircularProgress
      style={{ width: '25px', height: '25px', margin: '5px', color: pink[500] }}
    />
  );
};

const LayersDrawer = () => {
  const {
    portfolioId,
    studyId,
    fileId,
    datasetId,
    modelId,
    graphId,
  } = useParams();
  const location = useLocation();
  const allIds = { portfolioId, studyId, fileId, datasetId, modelId, graphId };
  const isDrawerRightOpen = useShallowSelector(s => s.common.isDrawerRightOpen);
  const isDrawerRightVisible = useShallowSelector(
    s => s.common.isDrawerRightVisible,
  );
  const firstLoadGraphPainted = useRef(false);
  const graphType = useShallowSelector(s => s.graph.graphType);
  const graphData = useShallowSelector(s => s.graph.graphData);
  const layers = useShallowSelector(s => s.graph.layers);
  const { currentLayer } = useCurrentLayer();
  const loading = useShallowSelector(s => s.graphs.loading);
  const isModalAddLayerOpen = useShallowSelector(
    s => s.graph.isModalAddLayerOpen,
  );
  const selectedLayerCommunityIds = useShallowSelector(s => s.graph.selectedLayerCommunityIds);
  const classes = useStyles();

  const [isDrawerCollapsed, setIsDrawerCollapsed] = useState(false);

  const statisticTable = useShallowSelector(s => s.predictors.statisticTable);
  const predictorsLoading = useShallowSelector(s => s.predictors.loading);
  const predictorsOpen = (statisticTable.length > 0 || predictorsLoading);

  const [deleteLayerId, setDeleteLayerId] = useState<string>('');
  const [copyLayerId, setCopyLayerId] = useState<string>('');
  const [innerLoading, setInnerLoading] = useState(false);
  const modalDeleteLayer = useRef<{ show: () => void }>(null);
  const modalCopyLayer = useRef<{ show: () => void }>(null);

  const [filterLayersMenuAnchorEl, setFilterLayersMenuAnchorEl] = useState<null | HTMLElement>(null);
  const filterLayersMenuOpen = Boolean(filterLayersMenuAnchorEl);

  const [filterLayersSettings, setFilterLayersSettings] = useState<FilterLayerSettings>({
    showPublic: true,
    showPrivate: true,
    showShared: true
  });

  const downloadLayerCommunitiesRef = useRef<HTMLAnchorElement>(null);

  const onOpenFilterLayersMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setFilterLayersMenuAnchorEl(e.currentTarget);
  };

  const handleFilterLayersMenuClose = () => {
    setFilterLayersMenuAnchorEl(null);
  };

  useEffect(() => {
    if (predictorsOpen) {
      setIsDrawerCollapsed(true);
    }
  }, [predictorsOpen]);

  useEffect(() => {
    if (!isDrawerRightVisible) {
      Actions.graph.dispatch({
        eyeDropToolActive: false
      });
    }
  }, [isDrawerRightVisible]);
  useEffect(() => {
    reInitializeLayers();
  }, [graphType, location.pathname, isDrawerRightVisible]);

  useEffect(() => {
    if (currentLayer) {
      //paintGraphByLayerColors(currentLayer, graphData, graphType);
    }
  }, [currentLayer]);

  // useful when layers come faster then graph - paint graph first time on reload
  useEffect(() => {
    if (
      graphData[graphType] &&
      currentLayer &&
      !firstLoadGraphPainted.current
    ) {
      //paintGraphByLayerColors(currentLayer, graphData, graphType);
      firstLoadGraphPainted.current = true;
    }
  }, [graphData]);

  useEffect(() => {
    const [layerBarChart] = currentLayer?.charts ?? [];

    if (layerBarChart) {
      Actions.graph.dispatch({
        chartData: layerBarChart,
      });
    }
  }, [currentLayer?.charts]);

  function removeSelectionColor() {
    Actions.graph.dispatch({
      selectionColor: '',
    });
  }

  async function reInitializeLayers(resetCurrentLayerId = true) {
    removeSelectionColor();
    if (isDrawerRightVisible) {
      setLayers([]);
      const loadedLayers = await listLayers();

      if (loadedLayers?.length && resetCurrentLayerId) {
        setCurrentLayerId(loadedLayers[0]?.id);
      }
    }
  }

  function setLayers(layers: LayerData[]) {
    if (typeof layers === 'function') {
      return Actions.graph.dispatch(layers);
    }
    Actions.graph.dispatch({ layers });
  }

  function setCurrentLayerId(currentLayerId?: string) {
    if (currentLayerId) {
      removeSelectionColor();
      Actions.graph.dispatch({ currentLayerId });
    }
  }

  function handleDrawerRightClose() {
    Actions.common.dispatch({ isDrawerRightOpen: false });
  }

  function addLayer(title: string, additionalInfo: string) {
    const data = {
      ...allIds,
      title,
      additionalInfo,
      graphType,
      isDefault: false,
      communities: [],
    };
    return GraphApi.addLayer(data).then(() => {
      setLayers([]);
      listLayers();
    });
  }

  async function listLayers() {
    const { data }: any = await GraphApi.listLayers(
      { ...allIds, filtering: { graphType } }
    );
    const { items = [] } = data;
    setLayers(items);
    return items;
  }

  function toggleModalAddLayer() {
    Actions.graph.dispatch({ isModalAddLayerOpen: !isModalAddLayerOpen });
  }

  function rerenderLayers() {
    setLayers(layers.map(layerData => ({
      ...layerData
    })));
  }

  function setDrawerOpt(payload: any) {
    Actions.common.dispatch(payload);
  }

  async function updateLayer(layer: LayerData) {
    setInnerLoading(true);
    const communities = layer.communities
      .filter(c => !c.removed)
      .map(c => omit(c, ['hasChanges', 'removed']));
    await GraphApi.updateCommunity({
      communities,
      ...allIds,
      graphType,
      communityLayerId: layer.id,
    });
    setInnerLoading(false);
    reInitializeLayers(false);
  }

  async function changePublicLayerAccess(layer: LayerData, isPublic: boolean) {
    setInnerLoading(true);
    await GraphApi.updateLayer({
      ...allIds,
      communityLayerId: layer.id,
      isPublic,
    });
    setInnerLoading(false);
    reInitializeLayers(false);
  }

  async function mergeCommunities(layer: LayerData) {
    setInnerLoading(true);
    const data = {
      ...allIds,
      communityLayerId: layer.id,
      communitiesIds: selectedLayerCommunityIds,
    };
    await GraphApi.mergeCommunities(data);
    setInnerLoading(false);
    reInitializeLayers(false);
  }

  async function downloadLayerCommunities(layer: LayerData) {
    setInnerLoading(true);
    const data = {
      ...allIds,
      communityLayerId: layer.id,
    };
    const response = await GraphApi.downloadLayerCommunities(data);
    const csvFileContents = response.data;

    setInnerLoading(false);

    if (downloadLayerCommunitiesRef.current) {
      const url = window.URL.createObjectURL(new Blob([csvFileContents], {type : 'text/csv'}));
      downloadLayerCommunitiesRef.current.href = url;
      downloadLayerCommunitiesRef.current.click();
    }
  }

  const groupedLayers = (() => {
    //group layers by public access
    const defaultLayers = layers.filter(layer => layer.isDefault);
    const layersExceptDefault = layers.filter(layer => !layer.isDefault);
    const publicLayers = layersExceptDefault.filter(layer => layer.isPublic && !layer.isShared);
    const sharedLayers = layersExceptDefault.filter(layer => layer.isShared);
    const privateLayers = layersExceptDefault.filter(layer => !layer.isPublic && !layer.isShared)

    return [
      ...defaultLayers,
      ...(filterLayersSettings.showPrivate ? privateLayers : []),
      ...(filterLayersSettings.showShared ? sharedLayers : []),
      ...(filterLayersSettings.showPublic ? publicLayers : []),
    ];
  })();

  const filterResultLabel = groupedLayers.length < layers.length ? `(${groupedLayers.length} of ${layers.length})` : '';
  const globalLayerActionsLabel = `Communities layers ${filterResultLabel}`;
  const isFilterApplied = !filterLayersSettings.showPrivate || !filterLayersSettings.showShared || !filterLayersSettings.showPublic;

  //todo: canvas is not resized after drawer expand/collapse
  return (
    <Drawer
      open={isDrawerRightOpen}
      anchor="right"
      variant="persistent"
      className={
        clsx(classes.drawer, {
          [classes.drawerOpen]: isDrawerRightVisible && !isDrawerCollapsed,
          [classes.drawerCollapsed]: isDrawerRightVisible &&isDrawerCollapsed,
          [classes.drawerClosed]: !isDrawerRightVisible,
        })
      }
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: isDrawerRightVisible && !isDrawerCollapsed,
          [classes.drawerCollapsed]: isDrawerRightVisible && isDrawerCollapsed,
          [classes.drawerClosed]: !isDrawerRightVisible,
        }),
      }}
      SlideProps={{
        unmountOnExit: true,
        onEnter: () => setDrawerOpt({ isDrawerRightVisible: true }),
        onExited: () => setDrawerOpt({ isDrawerRightVisible: false }),
      }}
    >
      <div className="nav-bar-wrapper">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className={classes.noLeftPadding}
            >
              <div className={classes.globalLayerActions}>
                <span>
                  <Tooltip title={isDrawerCollapsed ? 'Expand communities drawer' : 'Collapse communities drawer'} placement="top">
                    <IconButton
                      color="inherit"
                      aria-label="Collapse communities drawer"
                      onClick={() => {
                        setIsDrawerCollapsed(!isDrawerCollapsed);
                      }}
                    >
                      {isDrawerCollapsed ? (
                        <ChevronLeftIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}

                    </IconButton>
                  </Tooltip>
                </span>
                <span className={classes.globalLayerActionsLabel}>{globalLayerActionsLabel}</span>
                <span>
                  <Tooltip title="Filter layer list" placement="top">
                    <IconButton disabled={loading} onClick={onOpenFilterLayersMenuClick} style={{
                        color: isFilterApplied ? pink[500] : ''
                    }}>
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>
                </span>
                <span>
                  <Tooltip title="Add new layer" placement="top">
                    <IconButton disabled={loading} onClick={toggleModalAddLayer}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              </div>
            </ListSubheader>
          }
          className={classes.layersList}
        >
          <div
            className="scroll-wrapper"
            style={{
              maxHeight: '100%'
            }}
          >
            {loading ? (
              <div className="loader-wrapper">
                <LoadingCircle />
              </div>
            ) : (
              groupedLayers.map(layer => {
                return (
                  <React.Fragment key={layer.id}>
                    <LayerItem
                      layer = {layer}
                      selected = {currentLayer?.id === layer.id}
                      innerLoading = {innerLoading}
                      onSelectLayer = {layer => setCurrentLayerId(layer.id)}
                      onSave = {layer => updateLayer(layer)}
                      onDeleteLayer = {layer => {
                        setDeleteLayerId(layer.id);
                        modalDeleteLayer?.current?.show();
                      }}
                      onCopyLayer = {layer => {
                        setCopyLayerId(layer.id);
                        modalCopyLayer?.current?.show();
                      }}
                      onChangePublicLayerAccess = {changePublicLayerAccess}
                      onMergeCommunities = {layer => mergeCommunities(layer)}
                      onDownloadLayerCommunities = {downloadLayerCommunities}
                      rerenderLayers = {rerenderLayers}
                    />
                    <Divider light />
                  </React.Fragment>
                );
              }
            ))}
          </div>
        </List>
        <a href="#" download="layer-communities.csv" hidden ref={downloadLayerCommunitiesRef}></a>
      </div>
      <Menu
        id="filter-menu"
        getContentAnchorEl={null}
        anchorEl={filterLayersMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={filterLayersMenuOpen}
        onClose={handleFilterLayersMenuClose}
      >
        <MenuItem onClick={() => {
          setFilterLayersSettings({
            ...filterLayersSettings,
            showPublic: !filterLayersSettings.showPublic
          });
          handleFilterLayersMenuClose();
        }}>
          <ListItemIcon className={classes.filterLayersMenuItemIcon}>
            <CheckIcon fontSize="small" style={{
              visibility: filterLayersSettings.showPublic ? 'visible' : 'hidden'
            }} />
          </ListItemIcon>
          <Typography variant="inherit">Common</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          setFilterLayersSettings({
            ...filterLayersSettings,
            showPrivate: !filterLayersSettings.showPrivate
          });
          handleFilterLayersMenuClose();
        }}>
          <ListItemIcon className={classes.filterLayersMenuItemIcon}>
            <CheckIcon fontSize="small" style={{
              visibility: filterLayersSettings.showPrivate ? 'visible' : 'hidden'
            }} />
          </ListItemIcon>
          <Typography variant="inherit">Private</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          setFilterLayersSettings({
            ...filterLayersSettings,
            showShared: !filterLayersSettings.showShared
          });
          handleFilterLayersMenuClose();
        }}>
          <ListItemIcon className={classes.filterLayersMenuItemIcon}>
            <CheckIcon fontSize="small" style={{visibility: filterLayersSettings.showShared ? 'visible' : 'hidden'}} />
          </ListItemIcon>
          <Typography variant="inherit">Shared</Typography>
        </MenuItem>
      </Menu>
      <ModalAddLayer
        addLayer={addLayer}
        showModal={isModalAddLayerOpen}
        handleClose={toggleModalAddLayer}
      />
      <Modal ref={modalDeleteLayer} title="Delete Layer">
        {({ close }) => (
          <ModalDeleteLayer
            communityLayerId={deleteLayerId}
            handleClose={close}
            handleDeleteCallback={() => reInitializeLayers()}
          />
        )}
      </Modal>
      <Modal ref={modalCopyLayer} title="Copy Layer">
        {({ close }) => (
          <ModalCopyLayer
            communityLayerId={copyLayerId}
            handleClose={close}
            handleCopyCallback={() => reInitializeLayers()}
          />
        )}
      </Modal>
    </Drawer>
  );
};

export default LayersDrawer;
