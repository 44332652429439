import React, { useState, SyntheticEvent, useEffect, useRef, createRef, useCallback } from 'react';
import { makeStyles, Theme, Slide } from '@material-ui/core';
import clsx from 'clsx';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { ReactNode } from 'react';

type ResizableSplitSectionParentProps = {
  first: ReactNode,
  second: ReactNode,
  desiredFirstSize?: number,
  desiredSecondSize?: number,
  direction: 'horizontal' | 'vertical',
  minSize?: number,
  onSizeChange: (newSize: [number, number], newSizePx: [number, number] ) => void
}


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  horizontal: {
    flexDirection: 'row'
  },
  vertical: {
    flexDirection: 'column'
  },
  dragHandler: {
    position: 'absolute',
    background: 'transparent',
    touchAction: 'none',
    transition: 'background 0.2s, width 0.2s, height 0.2s, margin 0.2s',
    zIndex: 1
  },
  dragHandlerHorizontal: {
    left: 0,
    right: 0,
    height: 4,
    marginTop: -2,
    cursor: 'ns-resize',
    borderTop: 'solid 1px #eee',
    borderBottom: 'solid 1px #eee',
    '&:hover': {
      height: 16,
      marginTop: -8,
      background: '#fafafa',
    },
  },
  dragHandlerVertical: {
    top: 0,
    bottom: 0,
    width: 4,
    marginLeft: -2,
    cursor: 'ew-resize',
    borderRight: 'solid 1px #eee',
    borderLeft: 'solid 1px #eee',
    '&:hover': {
      width: 16,
      marginLeft: -8,
      background: '#fafafa',
    },
  },
  dragIndicatorIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: '#777',
  },
  dragIndicatorIconHorizontal: {
    marginLeft: -11
  },
  dragIndicatorIconVertical: {
    marginTop: -11,
    transform: 'rotate(90deg)'
  },
  childWrapper: {
    width: '100%',
    height: '100%',
  }
}));

function formatSize(size: number) {
  return (Math.ceil(size * 10000) / 100) + '%';
}

const ResizableSplitSectionParent = (props: ResizableSplitSectionParentProps) => {
  const {
    first,
    second,
    desiredFirstSize,
    desiredSecondSize,
    direction,
    minSize = 0.1,
    onSizeChange
  } = props;
  const children = [first, second];
  const visibleChildren = children.filter(child => !!child);
  const maxSize = 1 - minSize;
  const classes = useStyles();
  const defaultSize = 1 / visibleChildren.length;
  const cssSizeKey = direction === 'horizontal' ? 'width' : 'height';

  const [size, _setSize] = useState<[number, number]>([0, 0]);
  const sizeRef = React.useRef<[number, number]>(size);
  const setSize = (newSize: [number, number]) => {
    sizeRef.current = newSize;
    _setSize(newSize)
  };
  const isFirstRun = useRef(true);
  const rootRef = useRef<HTMLDivElement>(null);
  const firstChildRef = useRef<HTMLDivElement>(null);
  const secondChildRef = useRef<HTMLDivElement>(null);
  const dragStartedRef = useRef(false);

  useEffect(() => {
    let newSize: [number, number];
    if (first && second) {
      if (desiredFirstSize) {
        newSize = [
          desiredFirstSize,
          1 - desiredFirstSize
        ];
      } else if (desiredSecondSize) {
        newSize = [
          1 - desiredSecondSize,
          desiredSecondSize
        ];
      } else {
        newSize = [first ? defaultSize : 0, second ? defaultSize : 0];
      }
    } else {
      newSize = [
        first ? 1 : 0,
        second ? 1 : 0
      ];
    }
    setSize(newSize);
    if (onSizeChange) {
      onSizeChange(newSize, getSizePx());
    }
  }, [visibleChildren.length]);

  function handleStartDrag(e: React.PointerEvent<HTMLDivElement>) {
    e.preventDefault();
    dragStartedRef.current = true;
  }

  function getSizePx(): [number, number] {
    const [firstChildSize, secondChildSize] = sizeRef.current;
    let firstChildSizePx = 0;
    let secondChildSizePx = 0;
    const rect = rootRef.current?.getBoundingClientRect();
    if (rect) {
      firstChildSizePx = direction === 'horizontal' ? rect.width * firstChildSize : rect.height * firstChildSize;
      secondChildSizePx = direction === 'horizontal' ? rect.width * secondChildSize : rect.height * secondChildSize;

    }
    return [firstChildSizePx, secondChildSizePx];
  }

  const handlePointerMove = useCallback((e: PointerEvent) => {
    if (dragStartedRef.current) {
      const rect = rootRef.current?.getBoundingClientRect();
      if (rect) {
        switch (direction) {
          case 'horizontal': {
            const newSize = Math.max(minSize, Math.min(maxSize, (e.clientX - rect.x) / rect.width));
            setSize([newSize, 1 - newSize]);
            break;
          }
          case 'vertical': {
            const newSize = Math.max(minSize, Math.min(maxSize, (e.clientY - rect.y) / (rect.height)));
            setSize([newSize, 1 - newSize]);
            break;
          }
        }
      }
      e.preventDefault();
    }
  }, []);

  const handlePointerUp = useCallback((e: PointerEvent) => {
    if (dragStartedRef.current) {
      dragStartedRef.current = false;
      e.preventDefault();
      e.stopPropagation();
      if (onSizeChange) {
        onSizeChange(sizeRef.current, getSizePx());
      }
    }
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }
    document.addEventListener('pointermove', handlePointerMove, true);
    document.addEventListener('pointerup', handlePointerUp, true);
    window.addEventListener('resize', handleWindowResize);

    return () => {
      document.removeEventListener('pointermove', handlePointerMove, true);
      document.removeEventListener('pointerup', handlePointerUp, true);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function handleWindowResize() {
    if (onSizeChange) {
      onSizeChange(sizeRef.current, getSizePx());
    }
  }

  function getResizeHandler() {
    if (first && second) {
      return (direction === 'horizontal') ? (
        <div
          className={clsx(classes.dragHandler, classes.dragHandlerVertical)}
          style={{
            left: formatSize(size[0])
          }}
          onPointerDown={handleStartDrag}
        >
          <DragIndicatorIcon className={clsx(classes.dragIndicatorIcon, classes.dragIndicatorIconHorizontal)}/>
        </div>
      ) : (
        <div
          className={clsx(classes.dragHandler, classes.dragHandlerHorizontal)}
          style={{
            top: formatSize(size[0])
          }}
          onPointerDown={handleStartDrag}
        >
          <DragIndicatorIcon className={clsx(classes.dragIndicatorIcon, classes.dragIndicatorIconVertical)} />
        </div>
      );
    }
  }

  return (
    <div className={clsx(classes.root, classes[direction])} ref={rootRef}>
      <div
        className={classes.childWrapper}
        style={{
          [cssSizeKey]: formatSize(size[0])
        }}
        ref={firstChildRef}
      >
        {first}
      </div>
      { getResizeHandler() }
      <div
        className={classes.childWrapper}
        style={{
          [cssSizeKey]: formatSize(size[1])
        }}
        ref={secondChildRef}
      >
        {second}
      </div>
    </div>
  );
};

export default ResizableSplitSectionParent;