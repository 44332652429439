import { d3 } from 'utils';
import React, { useRef } from "react";
import { useEffect } from "react";
import { Selection } from 'd3';

type D3Point = [number, number];
type NodeData = {
  x: number,
  y: number,
  nodeIndex: number
}

type LassoNodesSelectorProps = {
  width: number,
  height: number,
  selectionColor: string,
  nodes: NodeData[],
  transform: {
    x: number,
    y: number,
    k: number
  }
  onSelectionEnd: (nodes: NodeData[], shiftKey: boolean) => void
};

function getLasso(selectionColor: string) {
//function getLasso(C, draw) {
  const dispatch = d3.dispatch('start', 'end');

  // distance last point has to be to first point before it
  // auto closes when mouse is released.
  // some strange number for closing path
  let closeDistance = 75;

  // has inner lasso - maybe for encapsulation
  // root - some root HTML element - should be svg i guess
  function lasso(root: d3.Selection<SVGSVGElement, any, any, any>) {
    // append a <g> with a rect
    const g = root.append('g').attr('class', 'lasso-group');
    // gets dimensions of root container
    const bbox = root.node()?.getBoundingClientRect();
    // count area of selecting path - but lasso has no rect selector?
    // ah i see - we add to be full area of canvas?
    const area = g
      .append('rect')
      .attr('width', bbox?.width ?? 0)
      .attr('height', bbox?.height ?? 0)
      .attr('fill', selectionColor)
      .attr('opacity', 0);

    // guess to draw selecting polygon
    const drag = d3
      .drag<SVGRectElement, any>()
      .on('start', handleDragStart)
      .on('drag', handleDrag)
      .on('end', handleDragEnd);

    area.call(drag);

    let lassoPolygon: D3Point[] | null;
    let lassoPath: d3.Selection<SVGPathElement, any, any, any> | null;
    let closePath: d3.Selection<SVGLineElement, any, any, any> | null;

    // on start selecting
    function handleDragStart(this: any) {
      // get mouse position
      lassoPolygon = [d3.mouse(this)];
      // remove prev lassos
      if (lassoPath) {
        lassoPath.remove();
      }

      lassoPath = g
        .append('path')
        .attr('fill', selectionColor)
        .attr('fill-opacity', 0.1)
        .attr('stroke', selectionColor)
        .attr('stroke-dasharray', '3, 3');

      // what exactly to close
      closePath = g
        .append('line')
        .attr('x2', lassoPolygon[0][0])
        .attr('y2', lassoPolygon[0][1])
        .attr('stroke', selectionColor)
        .attr('stroke-dasharray', '3, 3')
        .attr('opacity', 0);

      // starting
      dispatch.call('start', lasso, lassoPolygon);
    }

    function handleDrag(this: any) {
      if (lassoPolygon) {
        const point = d3.mouse(this);
        lassoPolygon.push(point);
        lassoPath && lassoPath.attr('d', polygonToPath(lassoPolygon));

        const closingDistance = distance(lassoPolygon[0], lassoPolygon[lassoPolygon.length - 1]);

        // indicate if we are within closing distance
        if (closingDistance < closeDistance) {
          closePath && closePath
            .attr('x1', point[0])
            .attr('y1', point[1])
            .attr('opacity', 1);
        } else {
          closePath && closePath.attr('opacity', 0);
        }
      }
    }

    function handleDragEnd() {
      // remove the close path
      closePath && closePath.remove();
      closePath = null;

      // successful closed
      if (
        lassoPolygon &&
        distance(lassoPolygon[0], lassoPolygon[lassoPolygon.length - 1]) <
          closeDistance
      ) {
        lassoPath && lassoPath.attr('d', polygonToPath(lassoPolygon) + 'Z');
        dispatch.call('end', lasso, lassoPolygon, d3.event.sourceEvent);

        // otherwise cancel
      } else {
        lassoPath && lassoPath.remove();
        lassoPath = null;
        lassoPolygon = null;
      }
      lassoPath && lassoPath.remove();
    }

    /*lasso.reset = () => {
      if (lassoPath) {
        lassoPath.remove();
        lassoPath = null;
      }

      lassoPolygon = null;
      if (closePath) {
        closePath.remove();
        closePath = null;
      }
    };*/
  }

  lasso.on = (type: string, callback: any) => {
    dispatch.on(type, callback);
    return lasso;
  };

  return lasso;
}

// draw polygon SVG line
function polygonToPath(polygon: D3Point[]) {
  return `M${polygon.map(d => d.join(',')).join('L')}`;
}

// calculate distance point 1 and point 2
function distance(pt1: D3Point, pt2: D3Point) {
  return Math.sqrt((pt2[0] - pt1[0]) ** 2 + (pt2[1] - pt1[1]) ** 2);
}

const LassoNodesSelector = (props: LassoNodesSelectorProps) => {
  const { width, height, selectionColor, nodes, transform, onSelectionEnd = () => {} } = props;
  const propsRef = useRef({
    nodes, transform, onSelectionEnd
  });
  propsRef.current = {
    nodes, transform, onSelectionEnd
  };
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    d3.select(containerRef.current).selectAll('*').remove();

    //const boundingBox = containerRef.current?.getBoundingClientRect();
    const svgRoot = d3
      .select(containerRef.current)
      .append('svg')
      //.attr('class', 'selection-polygon')
      .attr('width', width)
      .attr('height', height)
      .style('position', 'absolute')
      .style('top', 0)
      .style('left', 0);

      // when a lasso is completed, filter to the points within the lasso polygon
    function handleLassoEnd(lassoPolygon: D3Point[], event: MouseEvent) {
      const { x, y, k } = propsRef.current.transform;
      const finalPolygon: D3Point[] = lassoPolygon.map(([px, py]) => [px - x, py - y]);

      const selectedNodes = propsRef.current.nodes.filter(d =>
        d3.polygonContains(finalPolygon, [d.x * k, d.y * k]),
      );
      propsRef.current.onSelectionEnd(selectedNodes, event.shiftKey);
    }

    // attach lasso to interaction SVG
    const lassoInstance = getLasso(selectionColor)
      .on('end', handleLassoEnd);
      //.on('start', handleLassoStart);

    if (selectionColor) {
      svgRoot.call(lassoInstance);
    }
  }, [selectionColor]);

  useEffect(() => {
    d3
      .select(containerRef.current)
      .select('svg')
      .attr('width', width)
      .attr('height', height)
      .select('rect')
      .attr('width', width)
      .attr('height', height)
  }, [width, height]);

  return (
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }} ref={containerRef} />
  );

};
export default LassoNodesSelector;