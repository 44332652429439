import React from 'react';
import PropTypes from 'prop-types';
import Cancel from '@material-ui/icons/Cancel';

import './CancelButton.css';

function CancelButton(props) {
  return (
    <div className="cancel-button-container">
      <Cancel
        style={{ color: '#212121', cursor: 'pointer', fontSize: '20px' }}
        onClick={props.handleCancelClick}
      />
    </div>
  );
}

CancelButton.propTypes = {
  handleCancelClick: PropTypes.func,
};

export default CancelButton;
