import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import setWith from 'lodash/setWith';

const setStateByRichPath = (state, action) => {
  const stateCopied = { ...state };

  each(action.payload, (value, path) =>
    setWith(stateCopied, path, value, cloneDeep),
  );
  return stateCopied;
};

const commonInnerReducers = (type, initialState) => {
  const initialStateCloned = cloneDeep(initialState);
  return {
    [type]: (state, action) => {
      const newState = setStateByRichPath(state, action);
      return newState;
    },
    [`${type}_CLEAR`]: () => initialStateCloned,
    [`${type}_PENDING`]: state => {
      return { ...state, loading: true };
    },
    [`${type}_FAIL`]: (state, action) => {
      const newState = setStateByRichPath(state, action);
      return { ...newState, loading: false };
    },
    [`${type}_SUCCESS`]: (state, action) => {
      const newState = setStateByRichPath(state, action);
      return { ...newState, loading: false };
    },
  };
};

export const createReducer = (
  initialState = {},
  type,
  customInnerReducers = {},
) => (state = initialState, action) => {
  const innerReducers = {
    ...commonInnerReducers(type, initialState),
    ...customInnerReducers,
  };

  const innerReducer = innerReducers[action.type];

  if (!innerReducer) {
    return state;
  }
  state = innerReducer(state, action);

  return state;
};
