import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '16px',
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
}));

const AutocompleteMulti = ({
  items = [],
  onChange,
  multi = true,
  label = '',
  placeholder = '',
  closeOnSelect = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple={multi}
        id="tags-standard"
        onChange={(e, v) => {
          onChange(v);
        }}
        options={items}
        disableCloseOnSelect={!closeOnSelect}
        getOptionLabel={option => option}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label={label}
            placeholder={placeholder}
            fullWidth
          />
        )}
      />
    </div>
  );
};

export default AutocompleteMulti;
