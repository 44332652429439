import React from 'react';

import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';

import { useShallowSelector } from 'hooks';

import { FlyBox, TextWrapper } from 'components';
import { IAppStore } from 'reducers';

type GraphInfoPlateProps = {
  visible: boolean
};

export const GraphInfoPlate = ({visible = true}: GraphInfoPlateProps) => {
  const graphAggregatedInfo = useShallowSelector(s => s.graph.graphAggregatedInfo);
  const coloring = useSelector((s: IAppStore) => s.graph.coloring);

  return (
    visible ? (
      <FlyBox
        noPaperPadding
        customStyles={{
          opacity: '0.8',
          position: 'absolute',
          right: '20px',
          bottom: '20px',
          width: '220px'
        }}
      >
        <div style={{ padding: '10px' }}>
          {!!coloring && (
            <>
              <TextWrapper variant="caption">NODE COLOR:</TextWrapper>
              <Tooltip title={coloring} placement="top">
                <TextWrapper
                  style={{
                    marginBottom: '5px',

                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {coloring}
                </TextWrapper>
              </Tooltip>
            </>
          )}
          <TextWrapper variant="caption">PORTFOLIO:</TextWrapper>
          <Tooltip
            title={graphAggregatedInfo.portfolio.title}
            placement="top"
          >
            <TextWrapper
              style={{
                marginBottom: '5px',

                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {graphAggregatedInfo.portfolio.title}
            </TextWrapper>
          </Tooltip>
          <TextWrapper variant="caption">STUDY:</TextWrapper>
          <Tooltip
            title={graphAggregatedInfo.study.title}
            placement="top"
          >
            <TextWrapper
              style={{
                marginBottom: '5px',

                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {graphAggregatedInfo.study.title}
            </TextWrapper>
          </Tooltip>
          <TextWrapper variant="caption">FILE:</TextWrapper>
          <Tooltip
            title={graphAggregatedInfo.file.title}
            placement="top"
          >
            <TextWrapper
              style={{
                marginBottom: '5px',

                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {graphAggregatedInfo.file.title}
            </TextWrapper>
          </Tooltip>
          <TextWrapper variant="caption">DATASET:</TextWrapper>
          <Tooltip
            title={graphAggregatedInfo.dataset.title}
            placement="top"
          >
            <TextWrapper
              style={{
                marginBottom: '5px',

                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {graphAggregatedInfo.dataset.title}
            </TextWrapper>
          </Tooltip>
          <TextWrapper variant="caption">MODEL:</TextWrapper>
          <Tooltip
            title={graphAggregatedInfo.model.title}
            placement="top"
          >
            <TextWrapper
              style={{
                marginBottom: '5px',

                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {graphAggregatedInfo.model.title}
            </TextWrapper>
          </Tooltip>
        </div>
      </FlyBox>
    ):(<></>)
  );
};

export default GraphInfoPlate;
