import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Print from '@material-ui/icons/Print';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Brightness4 from '@material-ui/icons/Brightness4';
import Settings from '@material-ui/icons/Settings';
import { Auth } from 'aws-amplify';

import coloringImg from '../coloring.png';
// import './HeaderMobileControls.css';

import { Actions } from 'actions';
import { notify } from 'utils';
import { apiUrl } from 'config';

const a4Dimensions = { height: 1133, width: 793 };

function HeaderMobileControls(props) {
  const [state, setState] = useState({
    coloringAnchorEl: null,
    mobileMoreAnchorEl: null,
    coloringAnchorEl: null,
    selectionAnchorEl: null,
    coloring: '',
    shouldPrint: false,
  });

  function handleChangeColoring(coloring) {
    setState(state => ({
      ...state,
      coloring,
      coloringAnchorEl: null,
      mobileMoreAnchorEl: null,
    }));
    getColoring(coloring);
  }

  function getColoring(coloring) {
    setState(state => ({
      ...state,
      coloringAnchorEl: null,
      mobileMoreAnchorEl: null,
    }));
    Actions.graph
      .POST({
        url: apiUrl.graph.getColoring(),
        data: { coloring },
      })
      .then(({ data }) => {
        setState(state => ({
          ...state,
          coloringAnchorEl: null,
          mobileMoreAnchorEl: null,
        }));
        Actions.graph.dispatch({
          'graphData.histogram': data.histogram,
          'graphData.clustered.nodes': props.graphData.clustered.nodes.map(
            (n, i) => ({
              ...n,
              ...data.clustered[i],
              fillColor: data.clustered[i].color,
            }),
          ),
          'graphData.metric.nodes': props.graphData.metric.nodes.map(
            (n, i) => ({
              ...n,
              ...data.metric[i],
              fillColor: data.metric[i].color,
            }),
          ),
        });
        Actions.flags.dispatch({
          forceUpdate: Symbol(),
          barUpdate: Symbol(),
        });
        Actions.flags.dispatch({ coloringUpdate: Symbol() });
      });
  }

  function handleMobileMenuClose() {
    setState(state => ({
      ...state,
      mobileMoreAnchorEl: null,
      coloringAnchorEl: null,
    }));
  }

  function handleToggleTheme() {
    Actions.common.dispatch({
      themeMode: props.themeMode === 'light' ? 'dark' : 'light',
    });
  }

  function openOptions() {
    Actions.graph.dispatch({ isOptionsOpen: true });
  }

  async function logout() {
    try {
      await Auth.signOut();
      Actions.auth.clear();
      Actions.graph.clear();
      Actions.flags.clear();
      Actions.tabs.clear();
    } catch (err) {
      console.log(err);
      notify({ title: 'Note!', message: err.message });
    }
  }

  async function printGraphs() {
    // localStorage.setItem('omitEventListener', true);
    Actions.common.dispatch({
      width: a4Dimensions.width,
    });
    await setTimeout(() => window.print(), 4);
    // localStorage.setItem('omitEventListener', false);
  }

  const { mobileMoreAnchorEl } = state;
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  return (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem onClick={toggleToolsMenu}>
          <IconButton color="inherit">{arrowUpDown}</IconButton>
          <p>Tools</p>
        </MenuItem> */}
      <MenuItem
        aria-owns={state.coloringAnchorEl ? 'coloring-menu-mobile' : undefined}
        aria-haspopup="true"
        onClick={e => {
          setState(state => ({ ...state, coloringAnchorEl: e.currentTarget }));
        }}
      >
        <IconButton color="inherit">
          <img style={{ width: '24px' }} src={coloringImg} alt="coloring" />
        </IconButton>
        <p>Coloring</p>
        <Menu
          id="coloring-menu-mobile"
          anchorEl={state.coloringAnchorEl}
          open={Boolean(state.coloringAnchorEl)}
          onClose={() =>
            setState(state => ({ ...state, coloringAnchorEl: null }))
          }
        >
          {props.listColorings.map((coloring, i) => {
            return (
              <MenuItem key={i} onClick={() => handleChangeColoring(coloring)}>
                {coloring}
              </MenuItem>
            );
          })}
        </Menu>
      </MenuItem>
      <MenuItem onClick={openOptions}>
        <IconButton color="inherit">
          <Settings />
        </IconButton>
        <p>Options</p>
      </MenuItem>
      <MenuItem onClick={handleToggleTheme}>
        <IconButton color="inherit">
          <Brightness4 />
        </IconButton>
        <p>Toggle theme</p>
      </MenuItem>
      {/* <MenuItem>
        <IconButton color="inherit">
          <CloudUpload />
        </IconButton>
        <p>Upload</p>
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          setState(state => ({
            ...state,
            mobileMoreAnchorEl: null,
          }));
          setTimeout(() => printGraphs(), 4);
        }}
      >
        <IconButton color="inherit">
          <Print />
        </IconButton>
        <p>Print</p>
      </MenuItem>
      {/* <MenuItem>
        <IconButton color="inherit">
          <GetApp />
        </IconButton>
        <p>Donwload</p>
      </MenuItem> */}
      {/* <MenuItem>
        <IconButton color="inherit">
          <Share />
        </IconButton>
        <p>Share</p>
      </MenuItem> */}
      <MenuItem onClick={logout}>
        <IconButton color="inherit">
          <ExitToApp />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
      {/* <div
        style={{
          padding: '30px 25px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <PresetSelector
          onClick={() => {
            Actions.common.dispatch({
              currentPreset: layoutConfig['preset1'],
            });
            updateAllContainers();
          }}
        />
        <PresetSelector
          onClick={() => {
            Actions.common.dispatch({
              currentPreset: layoutConfig['preset2'],
            });
            updateAllContainers();
          }}
          styleCustom={{ transform: 'rotate(90deg)' }}
        />
        <PresetSelector
          onClick={() => {
            Actions.common.dispatch({
              currentPreset: layoutConfig['preset3'],
            });
            updateAllContainers();
          }}
          styleCustom={{ transform: 'rotate(180deg)' }}
        />
      </div> */}
    </Menu>
  );
}

HeaderMobileControls.propTypes = {
  classes: PropTypes.object,
  handleLeftMenuClick: PropTypes.func,
  handleRightMenuClick: PropTypes.func,
  themeMode: PropTypes.string,
  showTools: PropTypes.bool,
};

const mapStateToProps = state => ({
  listColorings: state.graph.list_colorings,
  graphData: state.graph.graphData,
  graphType: state.graph.graphType,
  isDrawerLeftOpen: state.common.isDrawerLeftOpen,
  // graphData: { metric, clustered },
});

export default connect(mapStateToProps)(HeaderMobileControls);
