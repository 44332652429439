import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { TextWrapper } from 'components';

const ModalDeleteUser = ({ showModal, handleClose, handleDeleteUser }) => {
  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth="md"
        open={showModal}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Delete User</DialogTitle>
        <br />
        <DialogContent style={{ width: '550px' }}>
          <div style={{ marginBottom: '50px' }}>
            <TextWrapper>Are you sure you want to delete user?</TextWrapper>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 0 10px',
            }}
          >
            <Button
              variant="contained"
              style={{ marginRight: '25px' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDeleteUser}
            >
              Delete User
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalDeleteUser;
