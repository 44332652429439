import React, { useEffect, useState, useRef } from "react";
import { FormControl, InputLabel, Select, MenuItem, makeStyles, FormControlLabel, Switch, withStyles, Grid, Divider, Tooltip, Typography } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { Theme } from "@material-ui/core/styles";
import { GraphApi } from 'api';
import { Actions } from 'actions';
import { pageUrl, history } from 'config';

import StarIcon from '@material-ui/icons/Star';
import StarOutlinedIcon from '@material-ui/icons/StarBorder';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from "react-router-dom";
import { useShallowSelector } from 'hooks';
import { useSelector } from "react-redux";
import { GraphListItemData, IAppStore } from "reducers";

const useStyles = makeStyles((theme: Theme) => ({
  graphlistSectionWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  graphListSelectDivider: {
    marginTop: 8,
    marginBottom: 8
  },
  menuItemContentWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 8
  },
  menuItemGraphTitleWrapper: {
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: 8,
    paddingRight: 8
  },
  favoriteIconButtonWrapper: {
    width: 47
  },
  favoriteIconMenuItemWrapper: {
    width: 24,
  },
  favoriteIconButtonProgress: {
    position: 'absolute',
  },
  favoriteActive: {
    color: '#ffab00'
  },
  favoriteInactive: {
    color: '#bdbdbd'
  },
  detailedGraphSwitchContainer: {
    marginLeft: 10,
    marginRight: 10,
    whiteSpace: 'nowrap'
  }
}));

const SingleColorSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.primary.dark,
    '&$checked': {
      color: theme.palette.primary.dark,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  checked: {},
  track: {
    backgroundColor: theme.palette.primary.light,
  },
}))(Switch);


export default () => {
  const { portfolioId, studyId, fileId, datasetId, modelId, graphId } = useParams();
  const allIds = { portfolioId, studyId, fileId, datasetId, modelId, graphId };
  const classes = useStyles();
  const [graphs, setGraphs] = useState<GraphListItemData[]>([]);
  const favoriteGraphs = graphs.filter(({ isFavorite }) => isFavorite);
  const otherGraphs = graphs.filter(({ isFavorite }) => !isFavorite);

  const graphType = useShallowSelector(s => s.graph.graphType);
  const coloring = useSelector((s: IAppStore) => s.graph.coloring);
  const listColorings = useShallowSelector(s => s.graph.list_colorings);
  const isDetailedGraphType = graphType === 'metric';

  const currentGraphData = getSelectedGraph();

  const [graphListSelectUpdating, setGraphListSelectUpdating] = React.useState(false);
  const [graphListSelectOpen, setGraphListSelectOpen] = React.useState(false);

  const isMounted = useRef(false);

  const [currentColoringData] = listColorings.filter(({ title }: {title: string}) => coloring === title);
  const aggregationSwitcherDisabled = currentColoringData ? currentColoringData.isMetricOnly : false;


  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    listGraphs();
  }, []);

  async function listGraphs() {
    const {
      data: { items, pagination },
    } = await GraphApi.listGraphs(allIds);
    if (isMounted.current) {
      organizeAndSetGraphList(items);
    }
  }

  function organizeAndSetGraphList(graphs: GraphListItemData[]) {
    const favoriteGraphs: GraphListItemData[] = [];
    const otherGraphs: GraphListItemData[] = [];
    graphs.sort((a, b) => a.title.localeCompare(b.title, 'en', {numeric: true}));
    graphs.forEach(graph => {
      if (graph.isFavorite) {
        favoriteGraphs.push(graph);
      } else {
        otherGraphs.push(graph);
      }
    });
    setGraphs(favoriteGraphs.concat(otherGraphs));
  }

  function handleGraphListSelectClose() {
    setGraphListSelectOpen(false);
  };

  function handleGraphListSelectOpen() {
    setGraphListSelectOpen(true);
  };

  function getSelectedGraph() {
    const [result] = graphs.filter(graph => (
      pageUrl.graph(
        allIds.portfolioId,
        allIds.studyId,
        allIds.fileId,
        allIds.datasetId,
        allIds.modelId,
        graph.id,
      ) === window.location.pathname
    ));
    return result;
  }

  function onGraphSelected(graphId: string) {
    history.push(
      pageUrl.graph(
        allIds.portfolioId,
        allIds.studyId,
        allIds.fileId,
        allIds.datasetId,
        allIds.modelId,
        graphId,
      ),
    );
  }

  function changeGraphFavoriteStatus(graph: GraphListItemData, isFavorite: boolean) {
    const { resourceTitle, id } = graph;

    setGraphListSelectUpdating(true);
    GraphApi.changeGraphFavoriteStatus({
      resourceTitle, resourceId: id, isFavorite
    }).then(() => {
      const newGraphs = [...graphs].map(graph => ({
        ...graph,
        isFavorite: graph.id === id ? isFavorite : graph.isFavorite
      }));
      organizeAndSetGraphList(newGraphs);
    }).finally(() => {
      setGraphListSelectUpdating(false);
    });
  }

  function handleDetailedGraphStatusChange(event: React.ChangeEvent<HTMLInputElement>) {
    const checked = event.target.checked;

    Actions.graph.dispatch({
      graphType: checked ? 'clustered' : 'metric',
      coloringModeCommunities: [null, null]
    });
  }

  return (
    <>
      <div className={classes.graphlistSectionWrapper}>
        <div className={classes.favoriteIconButtonWrapper}>
          <Tooltip
            title="Toggle graph favorite status"
            placement="top"
          >
            <IconButton disabled={graphListSelectUpdating || graphs.length === 0} onClick={() => {
              changeGraphFavoriteStatus(currentGraphData, !currentGraphData.isFavorite)
            }}>
              <>
                { currentGraphData && currentGraphData.isFavorite ? (
                    <StarIcon className={classes.favoriteActive}/>
                  ) : (
                    <StarOutlinedIcon />
                  )
                }
                {graphListSelectUpdating ? (
                  <CircularProgress className={classes.favoriteIconButtonProgress}/>
                ) : null}
              </>
            </IconButton >
          </Tooltip>
        </div>
        <FormControl style={{width: '100%', marginRight: 16}} variant="outlined">
          <InputLabel id="graph-select-label">Graph</InputLabel>
          <Select
            labelId="graph-select-label"
            open={graphListSelectOpen}
            onOpen={handleGraphListSelectOpen}
            onClose={handleGraphListSelectClose}
            value={currentGraphData?.id ?? ''}
            onChange={(e) => {
              onGraphSelected(e.target.value as string);
            }}
            style={{width: 150}}
            renderValue={(value: any) => {
              return (<>{currentGraphData.title}</>);
            }}
            label="Graph"
            SelectDisplayProps={{
              //adjustments to make select the same height as coloring autocomplete
              style: {
                paddingTop: 9.5,
                paddingBottom: 9.5
              }
            }}
          >
            {favoriteGraphs.map(({ id, title}) => (
              <MenuItem
                key={id}
                value={id}
                selected={currentGraphData.id === id}
                disableGutters
              >
                <div className={classes.menuItemContentWrapper}>
                  <div className={classes.favoriteIconMenuItemWrapper}>
                    <StarIcon className={classes.favoriteActive} fontSize="small"/>
                  </div>
                  <Tooltip title={title} placement="right">
                    <div className={classes.menuItemGraphTitleWrapper}>
                      {title}
                    </div>
                  </Tooltip>
                </div>
              </MenuItem>
            ))}
            {favoriteGraphs.length > 0 ? (
              <Divider orientation="horizontal" className={classes.graphListSelectDivider}/>
            ) : null}
            {otherGraphs.map(({ id, title}) => (
              <MenuItem
                key={id}
                value={id}
                selected={currentGraphData.id === id}
                disableGutters
              >
                <div className={classes.menuItemContentWrapper}>
                  {favoriteGraphs.length > 0 ? (
                    <div className={classes.favoriteIconMenuItemWrapper}>
                    </div>
                  ) : null}
                  <Tooltip title={title} placement="right">
                    <div className={classes.menuItemGraphTitleWrapper}>
                      {title}
                    </div>
                  </Tooltip>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      </div>
      <Divider orientation="vertical" style={{height:'70%'}} />
      <div className={classes.detailedGraphSwitchContainer}>
        <Grid component="label" container alignItems="center" spacing={0} wrap="nowrap">
          <Grid item>Single View</Grid>
          <Grid item>
            <SingleColorSwitch
              color="primary"
              checked={!isDetailedGraphType}
              onChange={handleDetailedGraphStatusChange}
              disabled={aggregationSwitcherDisabled}
            />
          </Grid>
          <Grid item>Aggregated View</Grid>
        </Grid>
      </div>
    </>
  );
};