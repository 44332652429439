import React, { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Select from '@material-ui/core/Select';
import { MenuItem, Toolbar, InputLabel, FormControl, Snackbar, SnackbarContent } from '@material-ui/core';

import { Drawer, LoadingCircle, InfiniteScroll } from 'components';
import { GraphApi } from 'api';
import { Actions } from 'actions';
import { pageUrl, history } from 'config';
import { useShallowSelector } from 'hooks';
import { IProps } from './types';

import './styles.css';
import { PredictorsTable } from 'containers';
import { ModalAddLayer } from 'containers/LayersDrawer/components';
import { IAppStore, CommunityData, GraphListItemData } from 'reducers';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import StarOutlinedIcon from '@material-ui/icons/StarBorder';
import CircularProgress from '@material-ui/core/CircularProgress';

interface GraphData {
  id: string;
  resourceTitle: string;
  isFavorite: boolean;
  title: string;
  metricNodesCount: number;
  clusteredNodesCount: number;
}

const useSnackbarStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#4caf50',
    color: theme.palette.common.white,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  messageText: {
    marginLeft: '1rem'
  }
}));



const DRAWER_WIDTH = 350;
const TOP_MARGIN = 130;
const INNER_HEIGHT = window.innerHeight;
const LINE_HEIGH = 46;
const ADD_ROWS = 7;
const ROWS_COUNT = +((INNER_HEIGHT - TOP_MARGIN) / LINE_HEIGH) + ADD_ROWS;


//this component is deprecated
const ColoringModeLeftDrawer: FC<IProps> = () => {
  const { portfolioId, studyId, fileId, datasetId, modelId, graphId } = useParams();
  const allIds = { portfolioId, studyId, fileId, datasetId, modelId, graphId };
  const [graphs, setGraphs] = useState<GraphListItemData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [successfulLayerSaveSnackbarOpen, setSuccessfullLayerSaveSnackbarOpen] = React.useState(false);

  //const graphs: any = useShallowSelector(s => s.graphs.graphs);
  const loading = useShallowSelector(s => s.graphs.loading);
  const graphType = useShallowSelector(s => s.graph.graphType);
  const isModalAddLayerOpen = useShallowSelector(
    s => s.graph.isModalAddLayerOpen,
  );
  const coloringModeCommunities = useShallowSelector((s: IAppStore) => s.graph.coloringModeCommunities);
  const coloringUpdate = useShallowSelector(s => s.flags.coloringUpdate);
  const snackbarClasses = useSnackbarStyles();


  async function listGraphs(nextPage: number) {
    const {
      data: { items, pagination },
    } = await GraphApi.listGraphs(allIds);

    setGraphs(prevGraphs => [...prevGraphs, ...items] as any);
  }

  useEffect(() => {
    const getGraphs = async () => {
      listGraphs(currentPage);
    };
    function closeLayersDrawer() {
      Actions.common.dispatch({ isDrawerRightOpen: false });
    }
    closeLayersDrawer();
    getGraphs();
  }, []);

  const isDrawerRightOpen = true;
  const drawerRightVariant = 'persistent';

  function handleDrawerRightClose() {
    Actions.common.dispatch({ isDrawerRightOpen: false });
  }

  function checkIfSelected(itsURL: string) {
    return window.location.pathname === itsURL;
  }



  function onGraphClick(graph: any) {
    Actions.graph.dispatch({
      graphData: graph,
      list_colorings: graph?.coloring ?? [],
      allIds,
    });
    history.push(
      pageUrl.graph(
        allIds.portfolioId,
        allIds.studyId,
        allIds.fileId,
        allIds.datasetId,
        allIds.modelId,
        graph.id,
      ),
    );
  }

  function toggleModalAddLayer() {
    Actions.graph.dispatch({ isModalAddLayerOpen: !isModalAddLayerOpen });
  }

  function saveSelectionToLayer(title: string, additionalInfo: string) {
    const data = {
      ...allIds,
      title,
      additionalInfo,
      graphType,
      isDefault: false,
      communities: coloringModeCommunities.filter((community: CommunityData | null) => community !== null),
    };
    return GraphApi.addLayer(data).then(() => {
      setSuccessfullLayerSaveSnackbarOpen(true);
    });
  }

  function handleSuccessLayerSaveSnackbarClose(event?: React.SyntheticEvent, reason?: string) {
    setSuccessfullLayerSaveSnackbarOpen(false);
  }

  return (
    <Drawer
      drawerWidth={DRAWER_WIDTH}
      drawerVariant={drawerRightVariant}
      side="left"
      isOpen={false && isDrawerRightOpen}
      handleClose={handleDrawerRightClose}
      showToolbar={false}
    >
      <div className="graph-drawer-contents">
        <PredictorsTable
        />
      </div>
      <Snackbar
        open={successfulLayerSaveSnackbarOpen}
        onClose={handleSuccessLayerSaveSnackbarClose}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <SnackbarContent
          classes={snackbarClasses}
          message={(
            <>
              <CheckCircleIcon/>
              <div className={snackbarClasses.messageText}>
                Layer saved successfully
              </div>
            </>
          )}
        />
      </Snackbar>
      <ModalAddLayer
        addLayer={saveSelectionToLayer}
        showModal={isModalAddLayerOpen}
        handleClose={toggleModalAddLayer}
      />
    </Drawer>
  );
};

export default ColoringModeLeftDrawer;
