import { Actions } from '../../actions';
import { apiUrl } from '../../config';

export class StudyApi {
  static createStudy = (title, additionalInfo, portfolioId) => {
    return Actions.studies.POST({
      url: apiUrl.studies.createStudy(),
      data: { title, additionalInfo, portfolioId },
      mapData: response => {
        console.log(response);
        return {};
      },
    });
  };

  static listStudies = portfolioId => {
    return Actions.studies.POST({
      url: apiUrl.studies.listStudy(),
      data: {
        filtering: {
          isDeleted: false,
        },
        // "pagination": {
        //   "page": 0,
        //   "perPage": 0
        // },
        portfolioId,
        // "sorting": {
        //   "title": "string"
        // }
      },
      // mapData: response => {
      //   console.log(response);
      //   return {};
      // },
    });
  };

  static getStudyDetails = data => {
    return Actions.req.POST({
      url: apiUrl.studies.getStudyDetails(),
      data,
      // mapData: response => {
      //   console.log(response);
      //   return {};
      // },
    });
  };

  static deleteStudy = allIDs => {
    return Actions.req.DELETE({
      url: apiUrl.studies.deleteStudy(),
      data: { force: true, ...allIDs },
    });
  };
}
