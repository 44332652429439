import React from 'react';
import ReactDOM from 'react-dom';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { history, firebaseConfig } from './config';
import { configureStore } from './reducers';
import { Routes } from './containers';

import * as firebase from 'firebase/app';

import 'firebase/auth';

firebase.initializeApp(firebaseConfig() as any);

export const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Routes history={history} />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
