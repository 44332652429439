import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import StarOutlinedIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

interface Data {
  isFavorite: boolean;
  title: string;
  metricNodesCount: number;
  clusteredNodesCount: number;
  metricLinksCount: number;
  metricDefaultCommunitiesCount: number;
}

type SortItem = [keyof Data, ('asc' | 'desc')];
type TableSort = SortItem[];

interface GraphsTableProps {
  rows: Data[];
  totalRowsQuantity: number;
  rowsPerPage: number;
  page: number;
  defaultSort: TableSort | undefined;
  onChangePage(event: any, newPage: number): void,
  onChangeRowsPerPage(event: any): void;
  rowsPerPageOptions: number[];
  loading: boolean;
  LoadingComponent: React.ComponentType;
  onChangeFavoriteStatus(target: Data, isFavorite: boolean): void;
  onGraphClick(target: Data): void;
  onSortChange(newSort: TableSort): void
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  sort: TableSort;
  rowCount: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
}

const headCells: HeadCell[] = [
  { id: 'isFavorite', disablePadding: false, label: 'Favorite', align: 'left' },
  { id: 'title', disablePadding: true, label: 'Title', align: 'left' },
  { id: 'clusteredNodesCount', disablePadding: false, label: 'Aggregated Nodes', align: 'left' },
  { id: 'metricLinksCount', disablePadding: false, label: 'Edges', align: 'left' },
  { id: 'metricDefaultCommunitiesCount', disablePadding: false, label: 'Communities', align: 'left' },
];

const getSortDirection = (currentSort: TableSort, fieldName: string) => {
  const [currentFieldSort] = currentSort.filter(([id, direction]) => (id === fieldName));
  if (currentFieldSort) {
    const [, direction] = currentFieldSort;
    return direction;
  }
  return undefined;
};

const isSortActive = (currentSort: TableSort, fieldName: string) => {
  const filteredSort = currentSort.filter(([id, direction]) => (id === fieldName));
  return filteredSort.length > 0;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    graphTitle: {
      cursor: 'pointer',
      color: '#0a84ae'
    },
    favoriteActive: {
      color: '#ffab00'
    },
    favoriteInactive: {
      color: '#bdbdbd'
    },
    favoriteCell: {
      paddingTop: 4,
      paddingBottom: 4
    },
    noData: {
      fontSize: 30,
      color: '#80808080'
    }
  }),
);

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, sort = [], rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'default'}
            align={headCell.align}
          >
            <TableSortLabel
              active={isSortActive(sort, headCell.id)}
              direction={getSortDirection(sort, headCell.id)}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const GraphsTable = (props: GraphsTableProps) => {
  const {
    rows,
    totalRowsQuantity,
    rowsPerPage,
    page,
    rowsPerPageOptions,
    loading,
    LoadingComponent,
    defaultSort,
    onChangePage,
    onChangeRowsPerPage,
    onGraphClick,
    onChangeFavoriteStatus,
    onSortChange
  } = props;
  const [tableSort, setTableSort] = React.useState<TableSort>(defaultSort || []);

  const classes = useStyles();

  const handleRequestSort = (event: React.MouseEvent<unknown>, fieldName: keyof Data) => {
    const shiftKeyPressed = event.shiftKey;
    let newTableSort: TableSort;
    const defaultDirection = 'desc';

    if (isSortActive(tableSort, fieldName)) {
      const direction = getSortDirection(tableSort, fieldName);
      const oppositeDirection = direction === 'asc'? 'desc' : 'asc';
      const newDirection = shiftKeyPressed && tableSort.length > 1 ? (
        oppositeDirection === defaultDirection ? null : oppositeDirection
      ) : oppositeDirection;

      if (newDirection) {
        newTableSort = tableSort.map(([property, direction]) => {
          if (property === fieldName) {
            return [property, newDirection];
          }
          return [property, direction];
        });
      } else {
        //remove sort by this column
        newTableSort = tableSort.filter(([property]) => property !== fieldName);
      }
    } else {
      if (shiftKeyPressed) {
        newTableSort = [...tableSort, [fieldName, defaultDirection]];
      } else {
        newTableSort = [[fieldName, defaultDirection]];
      }
    }
    setTableSort(newTableSort);
    onSortChange(newTableSort);
  };

  return (
    <div>
      {loading && LoadingComponent ? (
          <LoadingComponent />
        ) : rows.length > 0 ? (
          <>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                sort={tableSort}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                { rows.map((row, index) => {
                    const {
                      isFavorite,
                      title,
                      metricNodesCount,
                      clusteredNodesCount,
                      metricLinksCount,
                      metricDefaultCommunitiesCount
                    } = row;
                    return (
                      <TableRow
                        hover
                        onClick={(event) => {}}
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        <TableCell align={headCells[0].align} className={classes.favoriteCell}>
                          <IconButton onClick={(e) => {
                            onChangeFavoriteStatus(row, !isFavorite);
                            e.stopPropagation();
                          }}>
                            { isFavorite ? (
                           <StarIcon className={classes.favoriteActive} />
                              ) : (
                                <StarOutlinedIcon className={classes.favoriteInactive} />
                              )
                            }
                          </IconButton>
                        </TableCell>
                        <TableCell
                          scope="row"
                          padding="none"
                          align={headCells[1].align}
                          className={classes.graphTitle}
                          onClick={() => onGraphClick(row)}
                        >
                          {title}
                        </TableCell>
                        <TableCell align={headCells[2].align}>{clusteredNodesCount}</TableCell>
                        <TableCell align={headCells[3].align}>{metricLinksCount}</TableCell>
                        <TableCell align={headCells[4].align}>{metricDefaultCommunitiesCount}</TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={totalRowsQuantity}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={(e, nextPage) => {
                onChangePage(e, nextPage);
              }}
              onChangeRowsPerPage={onChangeRowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          </>
        ) : (
          <Typography variant="h2" align="center" className={classes.noData}>No graphs found</Typography>
        )
      }
    </div>
  );
};
export default GraphsTable;